import React, { useState, Fragment, useEffect, useRef } from "react";
import "./SalesManagment.css";
import { months, formatNumber } from "../../helpers/helpers.js";
import SalesTable from "../../components/Tables/SalesTable/SalesTable";
import { useSelector, useDispatch } from "react-redux";
import MonthSelectSales from "../../components/Selectors/MonthSelectSales/MonthSelectSales";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLeft } from "@fortawesome/free-solid-svg-icons";
import { handleExportSales } from "../../Excel/salesExcel.js";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal.js";
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton.js";
import { getSalesData, updateSalesData, createSalesData } from "./SalesManagmentReq.js";

export default function SalesManagment() {
  const pdfRef = useRef();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("SalesManagment");
  const [index, setIndex] = useState(0);

  const currency = useSelector((state) => state.income.currency);
  const month = useSelector((state) => state.sales.month);
  const currentMonthData = useSelector((state) => state.sales[month]);
  const month_list = t("month_text").split(",");

  const [rows, setRows] = useState(currentMonthData?.rows || []);
  // const [combainTotalAmount, setCombainTotalAmount] = useState(0)
  const [headers, setHeaders] = useState(
    currentMonthData?.headers || [
      "date",
      "customerName",
      "contactName",
      "phoneNumber",
      "status1",
      "status2",
      "status3",
      "totalAmount",
      "preTaxAmount",
      "dealStatus",
    ]
  );
  const [selectedDealStatus, setSelectedDealStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salesData = await getSalesData(month);
        setRows(salesData.rows || []);
        setHeaders(salesData.headers || []);
      } catch (error) {
        console.error("Error fetching sales data:", error.message);
      }
    };
    fetchData();
  }, [month]);

  
  useEffect(() => {
    setRows(currentMonthData?.rows || [""]);
    setHeaders(
      currentMonthData?.headers || [
        "date",
        "customerName",
        "contactName",
        "phoneNumber",
        "status1",
        "status2",
        "status3",
        "totalAmount",
        "preTaxAmount",
        "dealStatus",
      ]
    );
  }, [currentMonthData]);

  const handleInputChange = async (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  
    try {
      const rowId = updatedRows[index].id; // Assuming each row has a unique 'id'
      await updateSalesData(rowId, updatedRows[index]);
      console.log("Row updated successfully.");
    } catch (error) {
      console.error("Error updating row:", error.message);
    }
  
    dispatch({
      type: `${month}_sales_data`,
      payload: { rows: updatedRows, headers },
    });
  };
  

  const addRow = async () => {
    const newRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
    
    setRows([...rows, newRow]);
  
    try {
      await createSalesData(newRow); // Call POST request to save new row
      console.log("New row added successfully.");
    } catch (error) {
      console.error("Error adding row:", error.message);
    }
  };
  
  const ExcelData = [
    headers.map((head)=> t(head)),
    ...rows.map(row => headers.map(header => row[header]))
  ]
  const addColumn = () => {
    const newHeader = prompt("Enter column name:");
    if (newHeader) {
      const updatedHeaders = [...headers, newHeader];
      setHeaders(updatedHeaders);

      setRows(rows.map((row) => ({ ...row, [newHeader]: "" })));

      dispatch({
        type: `${month}_sales_data`,
        payload: { rows, headers: updatedHeaders },
      });
    }
  };

  const saveData = async (e) => {
    e.preventDefault();
    
    try {
      for (let row of rows) {
        if (row.id) {
          // Update existing row
          await updateSalesData(row.id, row);
        } else {
          // Create new row
          await createSalesData(row);
        }
      }
      console.log("All data saved successfully.");
    } catch (error) {
      console.error("Error saving data:", error.message);
    }
  };
  

  const transferToNextMonth = () => {
    const currentMonthIndex = months.indexOf(month);
    if (currentMonthIndex === -1) {
      console.error("Invalid month");
      return;
    }

    const nextMonth = months[(currentMonthIndex + 1) % months.length];

    dispatch({
      type: `${nextMonth}_sales_data`,
      payload: { rows: [...rows], headers: [...headers] },
    });

    dispatch({
      type: `${month}_sales_data`,
      payload: { rows: [], headers },
    });

    setRows([]);
    document.getElementById("salesForm").reset();
  };

  const downloadPdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imageWidth, pdfHeight, imageHeight);
      const imgX = (pdfWidth - imageWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imageWidth * ratio,
        imageHeight * ratio
      );
      pdf.save("SalesManagment.pdf");
    });
  };

  const isRtl = i18n.language === "he";

  const filteredRows = selectedDealStatus
    ? rows.filter((row) => row.dealStatus === selectedDealStatus)
    : rows;
    // const combainTotalAmount = rows.reduce((acc , row)=> +acc + +row.totalAmount, 0)
    // const combainTotalAmount = filteredRows.reduce((acc , row)=> +acc + +row.totalAmount, 0)
    // const combainTotalPreTaxAmount = filteredRows.reduce((acc , row)=> +acc + +row.preTaxAmount, 0)
  return (
    <Fragment>
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>

      {/* <PdfButton onClick={downloadPdf} /> */}

      <div className="container ">
        <MonthSelectSales
          text={month_list}
          setIndex={setIndex}
          resetForm={"salesForm"}
          months={months}
        />
        <div className="filter-container">
          <label>{t("dealStatus")}</label>
          <select
            value={selectedDealStatus}
            onChange={(e) => setSelectedDealStatus(e.target.value)}
          >
            <option value="">{t("show_all")}</option>
            <option value="0%">0%</option>
            <option value="50%">50%</option>
            <option value="90%">90%</option>
            <option value="100%">100%</option>
          </select>
        </div>
      </div>

      <form id="salesForm" onSubmit={saveData} className="margin_top">
        <button
          type="button"
          className="button-transfer"
          onClick={transferToNextMonth}
        >
          <FontAwesomeIcon icon={faRightLeft} />
          {t("Transfer data to next month")}
        </button>
        <ExcelButton
                data={ExcelData}
                name={"Sales-mamgment"}
                excel={handleExportSales}
        />
        <div className="sales-management-container" ref={pdfRef}>
          <SalesTable
            rows={filteredRows}
            headers={headers}
            handleInputChange={handleInputChange}
            currency={currency}
            addColumn={addColumn}
            addRow={addRow}
          />
          <button
            type="button"
            className="button-add-column"
            style={{
              right: isRtl ? "auto" : "-50px",
              left: isRtl ? "-50px" : "auto",
            }}
            onClick={addColumn}
          >
            +
          </button>
          {/* <div className="total_section_sales">
            <div >{t("totalAmount")}:</div>
          <div
          dir="ltr"
          >{formatNumber(combainTotalAmount || 0)}</div>
          </div>
          <div className="total_section_sales">
            <div>{t("preTaxAmount")}:</div>
          <div
          dir="ltr"
          >{formatNumber(combainTotalPreTaxAmount || 0)}</div>
          </div> */}
          {/* <button type="button" className="button-sales" onClick={addRow}>
            +
          </button> */}

          <button type="submit" className="button-save-all text_center">
            {t("Save")}
          </button>
        </div>
      </form>
    </Fragment>
  );
}
