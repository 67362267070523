const initialState = {
  forms: [],
  currentForm: {
    id: null,
    nameOfProcedure: "",
    startDate: null,
    lastUpdate: null,
    status: "Pending",
    actions: [],
    connectors: [], // Add connectors to initial state for lines
  },
};


export const workflowReducer = (state = initialState, action) => {
  switch (action.type) {

    case "UPDATE_CURRENT_FORM":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          ...action.payload, // Merge changes into currentForm
        },
      };

    case "ADD_ACTION_TO_FORM":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          actions: [...state.currentForm.actions, action.payload], // Add a new action
        },
      };

    case "DELETE_ACTION_FROM_FORM":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          actions: state.currentForm.actions.filter(
            (_, index) => index !== action.payload
          ), // Remove an action by index
        },
      };

      case "SAVE_WORKFLOW":
        return {
          ...state,
          forms: state.forms.some((form) => form.id === action.payload.id)
            ? state.forms.map((form) =>
                form.id === action.payload.id ? action.payload : form
              )
            : [...state.forms, action.payload],
          currentForm: action.payload, // Save everything, including lines (connectors)
        };
  
      case "LOAD_WORKFLOW_FORM":
        const selectedForm = state.forms.find(
          (form) => form.id === action.payload
        );
        return {
          ...state,
          currentForm: selectedForm
            ? { ...selectedForm, connectors: selectedForm.connectors || [] } // Load connectors if available
            : { ...initialState.currentForm },
        };
  
      // Add a connector between actions (lines in the UI)
      case "ADD_CONNECTOR":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            connectors: [
              ...(state.currentForm.connectors || []),
              action.payload, // Add new connector (line)
            ],
          },
        };
  
      // Remove a connector between actions
      case "REMOVE_CONNECTOR":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            connectors: state.currentForm.connectors.filter(
              (connector) =>
                !(connector.from === action.payload.from &&
                  connector.to === action.payload.to)
            ), // Remove connector (line) based on from and to
          },
        };

    case "DUPLICATE_ACTION_BOX":
      const actionToDuplicate = state.currentForm.actions[action.payload];
      if (!actionToDuplicate) return state;

      const duplicatedAction = { ...actionToDuplicate };
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          actions: [...state.currentForm.actions, duplicatedAction], // Duplicate an action
        },
      };

    case "UPLOAD_PHOTO_TO_ACTION":
      const updatedActions = [...state.currentForm.actions];
      const file = action.payload.photo;

      if (file && updatedActions[action.payload.index]) {
        updatedActions[action.payload.index] = {
          ...updatedActions[action.payload.index],
          photoUrl: URL.createObjectURL(file),
          photoName: file.name,
        };
      }

      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          actions: updatedActions, // Update an action with a photo
        },
      };

    case "ADD_WORKFLOW_FORM":
      return {
        ...state,
        forms: [...state.forms, action.payload], // Add a new workflow form
      };

      case "REMOVE_WORKFLOW_FORM":
        return {
          ...state,
          forms: state.forms.filter((form) => form.id !== action.payload), // Remove form by id
        };      

  case "DUPLICATE_WORKFLOW_FORM":
  const formToDuplicate = state.forms.find((form) => form.id === action.payload.id);
  if (!formToDuplicate) return state;

  const duplicatedForm = {
    ...formToDuplicate,
    id: action.payload.newId, // Assign new unique ID
    nameOfProcedure: `${formToDuplicate.nameOfProcedure} - Copy`,
  };

  return {
    ...state,
    forms: [...state.forms, duplicatedForm], // Add the duplicated form
  };


    default:
      return state;
  }
};

// Action creators
export const saveWorkflow = (id, formData) => ({
  type: "SAVE_WORKFLOW",
  payload: { id, ...formData }, // Save workflow including actions and connectors
});

export const addActionToForm = (action) => ({
  type: "ADD_ACTION_TO_FORM",
  payload: action, // Add a new action box
});

export const duplicateActionBox = (index) => ({
  type: "DUPLICATE_ACTION_BOX",
  payload: index, // Duplicate an action box
});

export const uploadPhotoToAction = (index, photo) => ({
  type: "UPLOAD_PHOTO_TO_ACTION",
  payload: { index, photo }, // Add a photo to an action box
});

export const removeActionFromForm = (index) => ({
  type: "DELETE_ACTION_FROM_FORM",
  payload: index, // Remove an action box
});

// New action creators for connectors
export const addConnector = (from, to) => ({
  type: "ADD_CONNECTOR",
  payload: { from, to }, // Pass from and to to create a line
});

export const removeConnector = (from, to) => ({
  type: "REMOVE_CONNECTOR",
  payload: { from, to }, // Pass from and to to remove the line
});

