const defaultAppState = {
  Video_open: true,
  ai_responce: "Hello how are u nice to meet u",
  fullTextInput: "",
};

export const appReducer = (state = defaultAppState, action) => {
  switch (action.type) {
    case "Video_open":
      return { ...state, Video_open: action.payload };
    case "ai_responce":
      return { ...state, ai_responce: action.payload };

    case "UPDATE_FULL_TEXT":
      return {
        ...state,
        fullTextInput: action.payload,
      };
    default:
      return state;
  }
};
