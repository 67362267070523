import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt,faClone } from "@fortawesome/free-solid-svg-icons";
import "./LearningLessons.css";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";

function LearningLessons() {
  const { t } = useTranslation("LearningLessons");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.learningLessons.forms);

  return (
    <Fragment>
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>

      <table className="experience-table_learning">
        <thead>
          <tr>
            <th>{t("formNameColumn")}</th>
            <th>{t("creationDateColumn")}</th>
            <th>{t("status")}</th>
            <th>{t("last_update")}</th> {/* Last update column */}
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {forms.length > 0 ? (
            forms.map((form) => (
              <tr key={form.id}>
                <td
                  onClick={() => {
                    dispatch({ type: "LOAD_LESSONS_FORM", payload: form.id });
                    navigate(`/lessons-create/${form.id}`);
                  }}
                  className="clickable-cell"
                  style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                >
                  {form.formName || t("no_name")}
                </td>
                <td>{form.creationDate || t("no_date")}</td>
                <td>{form.status || t("Pending")}</td>
                <td>{form.lastUpdate ? new Date(form.lastUpdate).toLocaleString() : t("no_update")}</td> {/* Format last update */}
                <td>
                  <button className="action-btn" onClick={() => navigate(`/lessons-create/${form.id}`)}>
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    className="action-btn"
                    onClick={() => {
                      dispatch({ type: "REMOVE_LESSONS_FORM", payload: form.id });
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                  {/* New Duplicate button */}
  <button
    className="action-btn"
    onClick={() => {
      const newFormId = forms.length + 1; // Generate a new form ID
      dispatch({
        type: "DUPLICATE_LESSONS_FORM",
        payload: { id: form.id, newId: newFormId },
      });
    }}
  >
    <FontAwesomeIcon icon={faClone} /> {/* Replace with a duplicate icon if necessary */}
  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">{t("no_found")}</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="button-container1">
        <button
          className="top_button_create"
          onClick={() => {
            const newFormId = forms.length + 1;
            dispatch({ type: "CREATE_LESSONS_NEW_FORM", payload: newFormId });
            navigate(`/lessons-create/${newFormId}`);
          }}
        >
          {t("createButton")}
        </button>
      </div>
    </Fragment>
  );
}

export default LearningLessons;
