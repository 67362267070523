import React, { useState } from "react";
import "./ManagingScenarios.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import Warning from "../../components/Warning/Warning";

function ManagingScenarios() {
  const { t } = useTranslation("Scenarios");
  const dispatch = useDispatch();
  const scenariosFromStore = useSelector((state) => state.scenarios.scenarios);

  // Local state to handle unsaved changes
  const [localScenarios, setLocalScenarios] = useState([...scenariosFromStore]);

  // Add new scenario row
  const addScenario = () => {
    setLocalScenarios([
      ...localScenarios,
      { case: "", severity: "", probability: "", risk: 0, options: [{ name: "" }] },
    ]);
  };

  // Handle input changes and update local state
  const handleInputChange = (e, index, field) => {
    let { value } = e.target;

    // Ensure the value is between 0 and 100
    if (field === "severity" || field === "probability") {
      value = Math.max(0, Math.min(100, Number(value)));
    }

    const updatedScenarios = localScenarios.map((scenario, i) =>
      i === index ? { ...scenario, [field]: value } : scenario
    );
    setLocalScenarios(updatedScenarios);
  };

  // Handle textarea input for options
  const handleTextareaChange = (e, index) => {
    const { value } = e.target;
    const updatedOptions = value.split("\n").map(optionText => ({ name: optionText }));

    const updatedScenarios = localScenarios.map((scenario, i) =>
      i === index ? { ...scenario, options: updatedOptions } : scenario
    );
    setLocalScenarios(updatedScenarios);
  };

  // Save changes to the store
  const saveChanges = () => {
    dispatch({ type: "save_scenarios", payload: localScenarios });
  };

  // Reset to initial state from the store
  const resetChanges = () => {
    setLocalScenarios([...scenariosFromStore]);
  };

  // Download PDF function
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");

    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("scenarios.pdf");
    });
  };

  return (
    <div>
      {/* Added Scenario & Risk Management text here */}

      {/* Video Section */}
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>

      <div className="scenarios-layout">
        {/* PDF Download Section Outside the Rectangle */}
        <div id="pdf-content">
          <div className="pdf-button-container">
            <button onClick={downloadPDF} className="pdf-button">
              <img src={`${process.env.PUBLIC_URL}/Pictures/pdf_icon.png`} alt="PDF Icon" className="pdf-icon" />
            </button>
          </div>
        </div>

        {/* Capture content below this div */}
        <div id="pdf-content">
          {/* Table for scenarios */}
          <div className="table-container">
            <table className="scenarios_table">
              <thead>
                <tr>
                  <th>{t("scenario")}</th>
                  <th>{t("severity")}</th>
                  <th>{t("probability")}</th>
                  <th>{t("risk_assessment")}</th>
                  <th>{t("monitoring_measures")}</th>
                </tr>
              </thead>
              <tbody>
                {localScenarios.map((scenario, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        type="text"
                        value={scenario.case}
                        placeholder={t("Enter scenario")}
                        onChange={(e) => handleInputChange(e, i, "case")}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={scenario.severity || ""}
                        placeholder={t("(0-100)")}
                        min="0"
                        max="100"
                        onChange={(e) => handleInputChange(e, i, "severity")}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={scenario.probability || ""}
                        placeholder={t("(0-100)")}
                        min="0"
                        max="100"
                        onChange={(e) => handleInputChange(e, i, "probability")}
                      />
                    </td>
                    <td>
                      <div className="sanerio_input" readOnly>
                        {Math.round(scenario.severity * scenario.probability / 100)}%
                      </div>
                    </td>
                    <td>
                      <textarea className="no-border"
                        value={scenario.options.map(option => option.name).join("\n")}
                        placeholder={t("Enter option")}
                        onChange={(e) => handleTextareaChange(e, i)}
                        rows={scenario.options.length + 1} // Adjust height based on the number of options
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={addScenario} className="simple-button">+</button>
          </div>
        </div>
      </div>

      {/* Warning */}
      <Warning text={t("warning")} />
      <Warning text={t("warning2")} />

      {/* Save and Reset buttons at the bottom */}
      <div className="button-container">
        <button onClick={resetChanges} className="reset">{t("reset")}</button>
        <button onClick={saveChanges} className="save_button_f">{t("save")}</button>
      </div>
    </div>
  );
}

export default ManagingScenarios;
