import React, { useState, Fragment, useRef } from 'react';
import { useTranslation } from "react-i18next";
import "./WorkPlan.css";
import GoalsSelect from '../../components/Selectors/GoalsSelect/GoalsSelect';
import ObjectivSelect from '../../components/Selectors/TargetSelect/ObjectivSelect';
import ActionSelect from '../../components/Selectors/ActionSelect/ActionSelect';
import Pointer from '../../components/Pointer/Pointer';
import { useDispatch, useSelector } from "react-redux";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton";

function WorkPlan() {
    const { t } = useTranslation("WorkPlan");
    const dispatch = useDispatch();
    const pdfRef = useRef();

    const t1 = useSelector((state) => state.workplan.goals || []);
    const t2 = useSelector((state) => state.workplan.objectives || []);
    const t3 = useSelector((state) => state.workplan.indicators || []);
    const t4 = useSelector((state) => state.workplan.actions || []);
    const month_list = t("month_text") ? t("month_text").split(",") : [];

    const [goals, setGoals] = useState(t1);
    const [indicators, setIndicators] = useState(t3);
    const [objectives, setObjectives] = useState(t2);
    const [actions, setActions] = useState(t4);

    const resetChanges = () => {
        setGoals(t1);
        setIndicators(t3);
        setObjectives(t2);
        setActions(t4);
        dispatch({ type: "RESET_WORKPLAN" });
    };

    const objectivesUpdate = () => {
        const newObjective = { name: "", value: "", connection: false, actions: [] };
        
        setObjectives(prevObjectives => [...prevObjectives, newObjective]);
        
        setGoals(prevGoals => prevGoals.map(goal => ({
            ...goal,
            objectives: [...(goal.objectives || []), { ...newObjective }]
        })));
        
        setIndicators(prevIndicators => prevIndicators.map(indicator => ({
            ...indicator,
            objectives: [...(indicator.objectives || []), { ...newObjective }]
        })));
        
        dispatch({ type: "objectives", payload: [...objectives, newObjective] });
    };

    const actionsUpdate = () => {
        const newAction = { name: "", connection: false };
        
        setActions(prevActions => [...prevActions, newAction]);
        
        setGoals(prevGoals => prevGoals.map(goal => ({
            ...goal,
            actions: [...(goal.actions || []), { ...newAction }]
        })));
        
        setIndicators(prevIndicators => prevIndicators.map(indicator => ({
            ...indicator,
            actions: [...(indicator.actions || []), { ...newAction }]
        })));
        
        setObjectives(prevObjectives => prevObjectives.map(objective => ({
            ...objective,
            actions: [...(objective.actions || []), { ...newAction }]
        })));
        
        dispatch({ type: "actions", payload: [...actions, newAction] });
    };

    const saveTable = () => {
        dispatch({ type: "actions", payload: actions });
        dispatch({ type: "indicators", payload: indicators });
        dispatch({ type: "goals", payload: goals });
        dispatch({ type: "objectives", payload: objectives });
    };

    const downloadPDF = () => {
        const pdfContent = document.getElementById("pdf-content");
        const saveButton = document.getElementById("save-button");

        saveButton.style.display = "none";

        html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/png");
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save("Work-Plan.pdf");
        }).finally(() => {
            saveButton.style.display = "block";
        });
    };

    const handleMonthChange = (e, indicatorIndex, monthIndex) => {
        const { value } = e.target;
    
        setIndicators(prevIndicators => prevIndicators.map((indicator, i) => {
            if (i === indicatorIndex) {
                const updatedMonths = [...(indicator.months || [])];
                updatedMonths[monthIndex] = value;
                return { ...indicator, months: updatedMonths };
            }
            return indicator;
        }));
    };

    const adjustFontSize = (event, maxFontSize = 16, minFontSize = 1) => {
        const textarea = event.target;
        const containerWidth = textarea.offsetWidth;
        const text = textarea.value;
    
        const tempSpan = document.createElement("span");
        tempSpan.style.fontSize = `${maxFontSize}px`;
        tempSpan.style.visibility = "hidden"; 
        tempSpan.style.whiteSpace = "nowrap"; 
        tempSpan.innerText = text;
    
        document.body.appendChild(tempSpan); 
    
        let newFontSize = maxFontSize;
    
        // Continue reducing the font size until the text fits or reaches the minimum font size
        while (!(tempSpan.offsetWidth <= (containerWidth-5)) && newFontSize > minFontSize) {
            newFontSize -= 0.5;  // Reduce font size in smaller steps
            tempSpan.style.fontSize = `${newFontSize}px`;
        }
    
        // Apply the new font size to the textarea
        textarea.style.fontSize = `${newFontSize}px`;
    
        // Remove the temporary element
        document.body.removeChild(tempSpan);
    };
    
    return (
        <Fragment>
            <div className="video-container_p">
                <VideoNewModal videoUrl={t("video_url")} />
            </div>

            <PdfButton onClick={downloadPDF}/>

            <div className="work-plan-table-container" id="pdf-content">
                <div className='cash_flow_table' ref={pdfRef}>

                <div className='table_section2'>
    <div>
        {indicators.map((indicator, i) => (
            indicator.value && (
                <div key={i} className='small_target'>
                    {actions.map((action, j) => (
                        <Pointer
                            key={j}
                            info={action}
                            state={indicators}
                            list={"actions"}
                            point1={i}
                            point2={j}
                            updateState={setIndicators}
                        />
                    ))}
                    
                    <div className='mid_box_bottom_indicators'>
    <div className='indicators_info_name'><p>{indicator.name}</p></div>
    <div className='indicators_info_value'>
        <p>{t('indicator')}</p>
        <p>{indicator.value}</p>
    </div>

    <table className='month_table'>
        <thead>
            <tr>
                {month_list.map((month, m) => (
                    <th key={m} className="month_indicator">{month}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                {month_list.map((month, m) => (
                    <td key={m}>
                      <textarea
    value={(indicator.months && indicator.months[m]) || ""}
    className={`month_textarea ${indicator.months && (+indicator.months[m] >= +indicator.value) ? "green1" : "red1"}`}
    onChange={(e) => {
        handleMonthChange(e, i, m);
        adjustFontSize(e); // Appeler la fonction pour ajuster la taille de la police
    }}
/>


                    </td>
                ))}
            </tr>
        </tbody>
    </table>
</div>


                    {objectives.map((objective, j) => (
                        <Pointer
                            key={j}
                            info={objective}
                            state={indicators}
                            list={"objectives"}
                            point1={i}
                            point2={j}
                            updateState={setIndicators}
                        />
                    ))}
                </div>
            )
        ))}
    </div>
</div>


                    <div className='table_section'>
                        <div className='target_list'>
                            {actions.map((action, i) => (
                                <div className='first_box_mid' key={i}>
                                    <ActionSelect
                                        state={actions}
                                        setState={setActions}
                                        id={i}
                                        updateGoals={setGoals}
                                        currentGoals={goals}
                                        updateIndictors={setIndicators}
                                        currentIndictors={indicators}
                                        value={action.name}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='mid_box_mid'>
                            <div>
                                <p>{t("indicators")}</p>
                            </div>
                            <div className='text_mid_box'>
                                <button type="button" onClick={actionsUpdate}>+</button>
                                <p className='p_1'>{t("action")}</p>
                                <p className='p_1'>{t("objectives")}</p>
                                <button type="button" onClick={objectivesUpdate}>+</button>
                            </div>
                            <div>
                                <p>{t("goals")}</p>
                                <button type="button" onClick={() => setGoals(prevGoals => [...prevGoals, { name: "", objectives: objectives.map(obj => ({...obj})), actions: actions.map(act => ({...act})) }])}>+</button>
                            </div>
                        </div>
                        <div className='target_list'>
                            {objectives.map((objective, i) => (
                                <div className='first_box_mid' key={i}>
                                    <ObjectivSelect
                                        state={objectives}
                                        setState={setObjectives}
                                        id={i}
                                        updateGoals={setGoals}
                                        currentGoals={goals}
                                        updateIndictors={setIndicators}
                                        currentIndictors={indicators}
                                        value={objective}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='table_section1'>
                        <div>
                            {goals.map((goal, i) => (
                                <div key={i} className='small_target'>
                                    {actions.map((action, j) => (
                                        <Pointer 
                                            key={j} 
                                            info={action} 
                                            state={goals} 
                                            list={"actions"} 
                                            point1={i} 
                                            point2={j} 
                                            updateState={setGoals} 
                                        />
                                    ))}
                                    <div className='mid_box_bottom'>
                                        <GoalsSelect
                                            state={goals}
                                            setState={setGoals}
                                            value={goal.name}
                                            id={i}
                                        />
                                    </div>
                                    {objectives.map((objective, j) => (
                                        <Pointer 
                                            key={j} 
                                            info={objective} 
                                            state={goals} 
                                            list={"objectives"} 
                                            point1={i} 
                                            point2={j} 
                                            updateState={setGoals} 
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>

                <div className="legend-container">
                    <h4>{t("legend")}</h4>
                    <table className="legend-table">
                        <tbody>
                            <tr>
                                <td>{t("indirect_connection")}</td>
                                <td><input type="radio" checked readOnly /></td>
                            </tr>
                            <tr>
                                <td>{t("strong_connection")}</td>
                                <td><input type="radio" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="button-container">
                    <button onClick={resetChanges} className="reset">{t("reset")}</button>
                    <button onClick={saveTable} className="save_button_f" id="save-button">{t("save")}</button>
                </div>
            </div>
        </Fragment>
    );
}

export default WorkPlan;