import axios from 'axios';

// Base URL for the API
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:5000/api";

// POST request to create new marketing data
export const createMarketingData = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/marketing`, data);
    console.log("POST Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("POST Error:", error.message);
    throw error;
  }
};

// GET request to fetch marketing data
export const getMarketingData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/marketing`);
    console.log("GET Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Error:", error.message);
    throw error;
  }
};

// PUT request to update marketing data
export const updateMarketingData = async (id, data) => {
  try {
    const response = await axios.put(`${BASE_URL}/marketing/${id}`, data);
    console.log("PUT Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("PUT Error:", error.message);
    throw error;
  }
};

// DELETE request to delete marketing data
export const deleteMarketingData = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/marketing/${id}`);
    console.log("DELETE Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("DELETE Error:", error.message);
    throw error;
  }
};
