const initialState = {
  allTables: [[{ advantages: '', disadvantages: '' }]],
  topics: ['']
};

export const decisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NEW_TABLE':
      return {
        ...state,
        allTables: [...state.allTables, [{ advantages: '', disadvantages: '' }]],
        topics: [...state.topics, ''], // Adding a new topic placeholder
      };
    
    case 'ADD_ROW':
      return {
        ...state,
        allTables: state.allTables.map((table, index) =>
          index === action.payload.tableIndex
            ? [...table, { advantages: '', disadvantages: '' }]
            : table
        ),
      };

    case 'UPDATE_INPUT':
      return {
        ...state,
        allTables: state.allTables.map((table, tableIndex) =>
          tableIndex === action.payload.tableIndex
            ? table.map((row, rowIndex) =>
                rowIndex === action.payload.rowIndex
                  ? { ...row, [action.payload.field]: action.payload.value }
                  : row
              )
            : table
        ),
      };

    case 'UPDATE_TOPIC':
      return {
        ...state,
        topics: state.topics.map((topic, index) =>
          index === action.payload.index ? action.payload.value : topic
        ),
      };

    case 'RESET':
      return initialState;

    default:
      return state;
  }
};

  // Action types
  export const ADD_NEW_TABLE = 'ADD_NEW_TABLE';
  export const ADD_ROW = 'ADD_ROW';
  export const UPDATE_INPUT = 'UPDATE_INPUT';
  export const UPDATE_TOPIC = 'UPDATE_TOPIC';
  
  // Action creators
  export const addNewTable = () => ({
    type: ADD_NEW_TABLE,
  });
  
  export const addRow = (tableIndex) => ({
    type: ADD_ROW,
    payload: { tableIndex },
  });
  
  export const updateInput = (tableIndex, rowIndex, field, value) => ({
    type: UPDATE_INPUT,
    payload: { tableIndex, rowIndex, field, value },
  });
  
  export const updateTopic = (index, value) => ({
    type: UPDATE_TOPIC,
    payload: { index, value },
  });
  