import React, { Fragment } from 'react';
import "./SalesModal.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PdfButton from '../../Buttons/PdfButton/PdfButton';

function SalesModal({ icon, title, bodyText }) {
  const dispatch = useDispatch();

  function salesButtonFunc() {
    dispatch({ type: "checkbox1", payload: false });
    return;
  }

  // Function to download the modal content as PDF
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (!input) {
      console.error("Modal content not found");
      return;
    }

    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // A4 width in mm minus margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const marginRight = 15; // Right margin in mm
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", marginRight, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add new pages if content exceeds one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", marginRight, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${title}-details.pdf`);
    });
  };

  return (
    <Fragment>
      <div className="sales_modal">
        <div className="sales_modal_content">

          <div className='sales_popup' id="pdf-content">
            <div className='sales_button_div'>

              <button type='button' className='sales_button_x' onClick={() => salesButtonFunc()}>X</button>
             <div className='sales_button_pdf'>
             <PdfButton onClick={downloadPDF} />
             </div>

            </div>

            <div className='sales_div'>
              <div className='sales_icon'>
                <img src={icon} alt="salesModalImg" />
                <h4 className='sales_title'>{title}</h4>
              </div>
              <div className='sales_text_box'>
                <h4 className='sales_text_body body-text-ul'>{bodyText}</h4>
              </div>
            </div>

            {/* <div className='sales_button_div'>

            <button type='button' className='sales_button_x' onClick={() => salesButtonFunc()}>X</button>
        </div>
        <div className='sales_icon'>

            <img src={icon} />
            <h4 className='sales_title'>{title}</h4>
            <div className='sales_text_box'>
              <h4 className='sales_text_body body-text-ul'>{bodyText}</h4> */}
            {/* </div> */}
            {/* </div> */}
          </div>

          {/* PDF Download Button */}
          {/* <button onClick={downloadPDF} className="download-button">
        <FontAwesomeIcon icon={faFilePdf} />
      </button> */}
        </div>
      </div>
    </Fragment>
  );
}

export default SalesModal;
