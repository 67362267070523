import React from 'react';
import { useDispatch } from 'react-redux';
import { postData } from '../../../Pages/FirstPage/FirstPageRequst'; // Import the post function
import "./ResetButton.css";

function ResetButton(props) {
  const { text, month, value, setIndex, setAddInputs, setInventoryTotal, setSupplierTotal } = props;
  const dispatch = useDispatch();

  const save = () => {
    // Send data to backend (POST request)
    postData(value).then((data) => {
      console.log("Data saved successfully:", data);
      // Handle any UI updates based on backend response if necessary
    }).catch((error) => {
      console.error("Error saving data:", error);
    });

    // Redux dispatch logic (if still needed)
    dispatch({ type: month, payload: value });
    setAddInputs([]);
    setInventoryTotal(false);
    setSupplierTotal(false);
    setIndex(13);

    if (month !== "select month") {
      dispatch({ type: "Total" });
      dispatch({ type: "Average" });
    }

    return;
  };

  const resetButtonFunc = (e) => {
    e.preventDefault();
    save();
    
    const divs = document.querySelectorAll('.select_month');
    divs.forEach(div => {
      div.classList.remove('picked_f');
    });
    
    document.getElementById('financForm').reset();
    window.scrollTo(0, 400);
    return;
  };

  return (
    <div>
      <button 
        type='button' 
        className='save_button_first_page' 
        onClick={(e) => { e.preventDefault(); resetButtonFunc(e); }}
      >
        {text}
      </button>
    </div>
  );
}

export default ResetButton;
