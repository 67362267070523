// Createflow.js
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ActionBox from "../../components/ActionBox/ActionBox";
import {
  saveWorkflow,
  addActionToForm,
  duplicateActionBox,
  removeActionFromForm,
  addConnector,
  removeConnector,
} from "../../Reducers/workflowReducer";
import "./CreatePage.css";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Createflow() {
  const { t } = useTranslation("Workflow");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentForm } = useSelector((state) => state.workflow);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const [actions, setActions] = useState(
    currentForm.actions?.length
      ? currentForm.actions.map(action => ({ ...action, x: action.x || 0, y: action.y || 0 }))
      : [{ responsibility: "", action: "", time: "", procedure: "", notes: "", x: 0, y: 0 }]
  );
  const [tempName, setTempName] = useState(currentForm.nameOfProcedure);
  const [saveMessage, setSaveMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [lines, setLines] = useState([]);
  const [lineStart, setLineStart] = useState(null);
  const [hoveredLine, setHoveredLine] = useState(null);
  const [activeTrash, setActiveTrash] = useState(null);
  const [containerHeight, setContainerHeight] = useState(500);

  useEffect(() => {
    dispatch({ type: "LOAD_WORKFLOW_FORM", payload: parseInt(id) });
  }, [dispatch, id]);

  useEffect(() => {
    if (currentForm.actions && currentForm.actions.length > 0) {
      setActions(currentForm.actions.map(action => ({ ...action, x: action.x || 0, y: action.y || 0 })));
    } else {
      setActions([{
        responsibility: "",
        action: "",
        time: "",
        procedure: "",
        notes: "",
        x: 0,
        y: 0,
      }]);
    }
    setTempName(currentForm.nameOfProcedure);
  }, [currentForm.actions, currentForm.nameOfProcedure]);

  useEffect(() => {
    if (currentForm.connectors) {
      setLines(currentForm.connectors);
    }
  }, [currentForm.connectors]);

  useEffect(() => {
    const maxY = Math.max(...actions.map(action => action.y)) + 300;
    setContainerHeight(Math.max(500, maxY));
  }, [actions]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveWorkflow(currentForm.id, {
        ...currentForm,
        actions,
        nameOfProcedure: tempName,
        lastUpdate: new Date().toISOString().split("T")[0],
      })
    );
    setSaveMessage(t("saveSuccess"));
    setTimeout(() => setSaveMessage(""), 3000);
  };

  const handleRemoveActionBox = (index) => {
    dispatch(removeActionFromForm(index));
  };

  const handleDuplicateActionBox = (index) => {
    const lastAction = actions.length > 0 ? actions[actions.length - 1] : { x: 0, y: 0 };
    const actionToDuplicate = actions[index];
    const duplicatedAction = {
      ...actionToDuplicate,
      x: lastAction.x + 220,
      y: lastAction.y,
    };
    dispatch(addActionToForm(duplicatedAction));
  };

  const handleMouseDown = (e, index) => {
    const actionBoxRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - actionBoxRect.left;
    const offsetY = e.clientY - actionBoxRect.top;
  
    setDragOffset({ x: offsetX, y: offsetY });
    setDraggedItem(index);
    e.preventDefault();
  };
  
  const handleMouseMove = (e) => {
    if (draggedItem !== null) {
      const containerRect = e.currentTarget.getBoundingClientRect();
      const newX = e.clientX - containerRect.left - dragOffset.x;
      const newY = e.clientY - containerRect.top - dragOffset.y;
  
      const updatedActions = [...actions];
      updatedActions[draggedItem] = {
        ...updatedActions[draggedItem],
        x: newX,
        y: newY,
      };
      setActions(updatedActions);
    }
  };
  
  const handleMouseUp = () => {
    setDraggedItem(null);
  };

  const handleStartLine = (index) => {
    setLineStart(index);
  };

  const handleEndLine = (index) => {
    if (lineStart !== null && lineStart !== index) {
      const existingLine = lines.find(
        (line) => line.from === lineStart && line.to === index
      );
      if (existingLine) {
        dispatch(removeConnector(lineStart, index));
        setLines(lines.filter((line) => line !== existingLine));
      } else {
        const newLine = { from: lineStart, to: index };
        dispatch(addConnector(lineStart, index));
        setLines([...lines, newLine]);
      }
      setLineStart(null);
    }
  };

  const handleAddActionBox = () => {
    const lastAction = actions.length > 0 ? actions[actions.length - 1] : { x: 0, y: 0 };
    const newAction = {
      responsibility: "",
      action: "",
      time: "",
      procedure: "",
      notes: "",
      x: lastAction ? lastAction.x + 220 : 0,
      y: lastAction ? lastAction.y : 0,
    };
    dispatch(addActionToForm(newAction));
  };

  const handleLineHover = useCallback((index) => {
    setHoveredLine(index);
  }, []);

  const handleLineLeave = useCallback(() => {
    setHoveredLine(null);
  }, []);

  const handleDeleteLine = useCallback((index) => {
    const lineToDelete = lines[index];
    dispatch(removeConnector(lineToDelete.from, lineToDelete.to));
    setLines(lines.filter((_, i) => i !== index));
  }, [lines, dispatch]);

  useEffect(() => {
    dispatch({ type: "LOAD_WORKFLOW_FORM", payload: parseInt(id) });
  
    if (currentForm.id === null) {
      const newForm = {
        id: parseInt(id),
        nameOfProcedure: tempName || "New Workflow",
        actions: [
          { responsibility: "", action: "", time: "", procedure: "", notes: "", x: 0, y: 0 }
        ],
        startDate: new Date().toISOString().split("T")[0],
        lastUpdate: new Date().toISOString().split("T")[0],
        status: "Pending",
      };
      dispatch(saveWorkflow(newForm.id, newForm));
    }
  }, [dispatch, id, currentForm.id, tempName]);

  return (
    <div className="all55">
      <div className="page99">
        <div className="header99">
          <button className="back-button2" onClick={() => navigate("/creating-workflow")}>
            ← {t("Back_to")}
          </button>
        </div>
      </div>

      <div className="top-buttons">
        <button type="submit" className="save_button_f" onClick={handleSubmit}>
          {t("save")}
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="workflow-name-container">
          {isEditing ? (
            <input
              type="text"
              className="workflow-name-input"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              onBlur={() => setIsEditing(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") setIsEditing(false);
              }}
              autoFocus
            />
          ) : (
            <h2 className="workflow-name">
              {tempName || t("workflow_name")}
              <FontAwesomeIcon icon={faPen} className="edit-icon2" onClick={() => setIsEditing(true)} />
            </h2>
          )}
        </div>

        <p>{t("explination")}</p>

        <div
          className="cards-container"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{ 
            position: "relative", 
            minHeight: `${containerHeight}px`,
            height: `${containerHeight}px`
          }}
        >
          <div className="lines-background"></div>

          {actions.map((action, i) => (
            <div
              key={i}
              style={{
                position: "absolute",
                left: `${action.x}px`,
                top: `${action.y}px`,
                cursor: "move",
              }}
              onMouseDown={(e) => handleMouseDown(e, i)}
            >
              <ActionBox
                number={i + 1}
                actionData={action}
                onActionChange={(updatedAction) => {
                  const updatedActions = [...actions];
                  updatedActions[i] = updatedAction;
                  setActions(updatedActions);
                }}
                onStartLine={() => handleStartLine(i)}
                onEndLine={() => handleEndLine(i)}
                onDuplicate={() => handleDuplicateActionBox(i)}
                onRemove={() => handleRemoveActionBox(i)}
              />
            </div>
          ))}

          <div className="add-button-container">
            <button type="button" className="add_button_action" onClick={handleAddActionBox}>
              +
            </button>
          </div>

          {lines.map((line, index) => {
            const from = actions[line.from];
            const to = actions[line.to];
            if (!from || !to) return null;

            const fromRightX = from.x + 240;
            const fromCenterY = from.y + 50;
            const toLeftX = to.x;
            const toCenterY = to.y + 50;
            const midX = (fromRightX + toLeftX) / 2;
            const midY = (fromCenterY + toCenterY) / 2;
            const curveOffset = 100;

            return (
              <svg 
                key={index} 
                className="line-svg" 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none'
                }}
              >
                <defs>
                  <marker
                    id="arrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="5"
                    refY="3.5"
                    orient="auto"
                    fill="black"
                  >
                    <polygon points="0 0, 10 3.5, 0 7" />
                  </marker>
                </defs>

                <path
                  d={`M ${fromRightX},${fromCenterY} C ${midX - curveOffset},${fromCenterY} ${midX + curveOffset},${toCenterY} ${toLeftX},${toCenterY}`}
                  stroke="black"
                  strokeWidth="2"
                  fill="transparent"
                  markerEnd="url(#arrowhead)"
                  className="connector-line"
                  onMouseEnter={() => setActiveTrash(index)}
                />

                {activeTrash === index && (
                  <g
                    transform={`translate(${midX}, ${midY})`}
                    onMouseLeave={() => setActiveTrash(null)}
                  >
                    
                    <foreignObject x="-10" y="-10" width="20" height="20">
                      <div
                        xmlns="http://www.w3.org/1999/xhtml"
                        className="trash-icon-container"
                        onMouseEnter={() => setActiveTrash(index)}
                        onMouseLeave={() => setActiveTrash(null)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            handleDeleteLine(index);
                            setActiveTrash(null);
                          }}
                          className="trash-icon"
                        />
                      </div>
                    </foreignObject>
                  </g>
                )}
              </svg>
            );
          })}
        </div>

        {saveMessage && <p className="save-message">{saveMessage}</p>}
      </form>
    </div>
  );
}

export default Createflow;