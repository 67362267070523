import React, { Fragment, useState } from "react";
import "./SalesTraining.css";
import SalesCoaching from "../../components/SalesTrainingArticals/SalesCoaching.js";
import SalesObjections from "../../components/SalesTrainingArticals/SalesObjections.js";
// import ProcessesSales from "../../components/SalesTrainingArticals/ProcessesSales.js";
import { useTranslation } from "react-i18next";
// import TrainingTable from "../../components/SalesTrainingArticals/TrainingTable.js";
function SalesTraining() {
  const { t } = useTranslation("SalesTraining");
  // function pickUnpick(id) {
  //   document.getElementById(id).classList.toggle("picked");
  // }

  const [practice, setPractice] = useState("");
  const pickPracice = (e)=>{
    e.preventDefault()
    if(practice === e.target.id)setPractice(0);
    else{setPractice(e.target.id);}
  }

  return (
    <Fragment>
      <div className="page_control">

      {/* <h3>{t("title")}</h3> */}
      
      <p className="guide_sales_t">{t("guide")}</p>
      <div className="container_grid_seals text_center">
        <div
          id="Sales_Coaching"
          name={"hey"}
          className={`strategies ${practice === "Sales_Coaching"? "picked_sales":""}`}
          onClick={(e)=>pickPracice(e)}
          >
          {t("option_1")}
        </div>
        <div
          id="Sales_Objections"
          name={2}
          className={`strategies ${practice === "Sales_Objections"? "picked_sales":""}`}
          onClick={(e)=>pickPracice(e)}
          >
          {t("option_2")}
        </div>
        {/* <div
          id="Sales_Processes"
          className="strategies "
          onClick={(e) => {
            pickUnpick(e.target.id);
            setProcesses(!processes);
            }}
        >
          {t("option_3")}
          </div> */}
      </div>
      <div>

     {/* <div className="container_grid_seals"> */}
      {practice === "Sales_Coaching" ? <SalesCoaching /> : ""}
      {practice === "Sales_Objections" ? <SalesObjections /> : ""}
      {/* {processes ? <ProcessesSales /> : ""} */}
       {/* <TrainingTable />  */}
      {/* </div> */}
      </div>
          </div>
    </Fragment>
  );
}

export default SalesTraining;
