const defaultWorkplanState = {
  month: "select month",
  goals: [],
  actions: [],
  objectives: [],
  indicators: [],
};

export const workplanReducer = (state = defaultWorkplanState, action) => {
  switch (action.type) {
    case "goals":
      return { ...state, goals: action.payload || [] };  // Ensure it defaults to an empty array
    case "actions":
      return { ...state, actions: action.payload || [] };
    case "objectives":
      return { ...state, objectives: action.payload || [] };
    case "indicators":
      return { ...state, indicators: action.payload || [] };
    case "RESET_WORKPLAN":
      return { ...defaultWorkplanState }; // Reset state to default values
    default:
      return state;
  }
};
