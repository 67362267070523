import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ExamplePage.css"; // Ensure this file contains the button styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"; // Import the arrow icon

function ExamplePage() {
  const { t } = useTranslation("Experience");
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="page">
      {/* Header */}
      <div className="header">
        <button className="back-button2" onClick={() => navigate(-1)}>
          ← {t("Back_to")}
        </button>
      </div>


      {/* Image at the top */}
      {/* <div className="top-image">
        <img src={`${process.env.PUBLIC_URL}/Pictures/six_keys_logo.png`} alt="Top Image" className="top-image-file" />
      </div> */}


      {/* Form with fields */}
      <div className="form-section">
        <div className="input-row">
          <div className="input-column">
            <label>{t("procedure_name")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_procedure_name")} disabled />
          </div>
          <div className="input-column">
            <label>{t("document_number")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_document_number")} disabled />
          </div>
          <div className="input-column">
            <label>{t("page_number")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_page_number")} disabled />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <label>{t("date_created")}</label>
            <input type="text" className="input-field1" placeholder="DD/MM/YYYY" disabled />
          </div>
          <div className="input-column">
            <label>{t("last_update")}</label>
            <input type="text" className="input-field1" placeholder="DD/MM/YYYY" disabled />
          </div>
          <div className="input-column">
            <label>{t("update_number")}</label>
            <input type="text" className="input-field1" placeholder="1" disabled />
          </div>
        </div>
      </div>

      <div className="steps-section">
        <p>{t("Purpose: Ensure customer satisfaction with the service and product produced.")}</p>
        <p>{t("Procedure: Contact the customer within three days of service completion and complete a survey.")}</p>
        <p>{t("Responsible Person: Office and Administration Manager.")}</p>
      </div>

      <label>{t("qusetions")}</label>

      <div className="questions-section">
        <p>{t("How would you rate the service experience you received?")}</p>
        <p>{t("Did the consultant meet your expectations?")}</p>
        <p>{t("How would you rate the courtesy and attentiveness of the team?")}</p>
        <p>{t("What do you think we could do better to improve?")}</p>
      </div>



      <div className="footer-section">
        <div className="input-row">
          <div className="input-column">
            <label>{t("prepared_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_prepared_by")} disabled />
          </div>
          <div className="input-column">
            <label>{t("reviewed_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_reviewed_by")} disabled />
          </div>
          <div className="input-column">
            <label>{t("approved_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_approved_by")} disabled />
          </div>
        </div>
      </div>

 {/* Back to Top Button */}
 <button className="back-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faChevronUp} />
        <span>{t("Back to Top")}</span>
      </button>
    </div>
  );
}

export default ExamplePage;
