import React from "react";
import "./SalesCard.css";
import { useDispatch, useSelector } from "react-redux";

function SalesCard({ icon, text, onClick }) {
  const checkboxState = useSelector((state) => state.sales.checkbox1);
  const dispatch = useDispatch();

  function checkBoxFunc() {
    dispatch({ type: "checkbox1", payload: true });
    return;
  }

  return (
    <div className="sales_card container_column" onClick={onClick}>
      <input
        type="checkbox"
        className="checkbox-round"
        checked={checkboxState}
        onChange={() => checkBoxFunc()}
      />
      <img src={icon} className="icon_sales" alt="salesCardImg"/>
      <h3 className="sales_text"><b>{text}</b></h3>
      <button className="sales_button">View More</button>
    </div>
  );
}

export default SalesCard;
