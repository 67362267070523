import React, { Fragment } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNumber } from "../../helpers/helpers";
import './TotalDiv.css'
function TotalDiv(props) {
    const {totalAmountTaken, text ,currency} = props
  return (
    <Fragment>
        
        <div>
        <div className="total_container">
            <p>{text}:</p>
            <div className="total_container" dir='ltr'>

          <FontAwesomeIcon color='#8C8C8C' icon={currency} />
          <p
            type="text"
            className="the_info_b"
            >{formatNumber(totalAmountTaken)}</p>
            </div>
        </div>
        {/* <div className='debts_h_text'>{totalAmountTaken}</div> */}
        </div>
    </Fragment>
  )
}

export default TotalDiv