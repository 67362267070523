

import React, { Fragment } from "react";
import { useSelector, } from "react-redux";
import "./MonthTableRow.css";
import { months} from '../../../helpers/helpers';

import { formatNumber } from '../../../helpers/helpers';
function MonthTableBP({id, text}) {


    const thisMonth = useSelector(state => state.finance);
    const totalSpend = months.reduce((base,mon)=>{
        return base += +thisMonth[mon].salary_and_related_expenses + +thisMonth[mon].financial_expenses  + +thisMonth[mon].operational_and_managment
    },0);
    const totalExpensesY = months.reduce((base,mon)=>{
      return base += +thisMonth[mon].inventory_and_supplier_expenses
  },0);
    const totalMonthIncome =  months.reduce((base,mon)=>{
      return base += +thisMonth[mon].total_Income
    },0);
    return (
        <Fragment>
              <div className="input-table-c"
              readOnly
              ><p className="the_info">{text}</p></div>
             {months.map(( mon ,i ) => (
                <div
                key={i}
                className="input-table-f"
                dir='ltr'
                // {formatNumber(thisMonth[mon][id])}/
                // disabled
              > <p className="the_info">{formatNumber(thisMonth[mon][id])}</p></div>
              ))}

              <div className="input-table-f"

              disabled
              dir='ltr'
              >
            <p></p>
              </div>
              <div className="input-table-f"
              disabled
              dir='ltr'
              ><p className={`the_info`}>{(totalMonthIncome === 0)? 0: formatNumber((totalSpend/ ((1 - totalExpensesY / totalMonthIncome)))) }</p></div>
              </Fragment>
      );
    }



export default MonthTableBP;