import React, { Fragment, useState } from "react";
import "./MonthSelectVs.css"; // Create this CSS file for styling
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

function MonthSelectVsExecution({ text = [], setIndex, resetForm, months = [], page, theMonth }) {
  const thisState = useSelector((state) => state.vs);
  const dispatch = useDispatch();
  const { t } = useTranslation('Vs');
  const [selectedMonth, setSelectedMonth] = useState(null); // Track selected month

  if (!months || months.length === 0) {
    return <div>{t('no_months_available')}</div>;
  }

  return (
    <Fragment>
      <div className="month_label text_center">
        {t('chose_months')} {/* This will display the label "months" */}
      </div>
      <div className="container_grid_12_b text_center month_list">
        {months.map((mon, i) => (
          <Fragment key={i}>
            <div
              className={`select_month ${mon === selectedMonth ? 'picked_f' : ''}`} // Apply 'picked_f' only to the selected month
              id={mon}
              onClick={(e) => {
                const formElement = document.getElementById(resetForm);
                if (formElement) {
                  formElement.reset(); // Reset the form if it exists
                } else {
                  console.warn('Form not found:', resetForm); // Log if the form is not found
                }

                dispatch({ type: "month", payload: mon });
                dispatch({
                  type: mon + page,
                  payload: {
                    ...thisState[mon],
                  },
                });

                setSelectedMonth(mon); // Update the selected month
                setIndex(i);
              }}
            >
              {text[i] || mon}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default MonthSelectVsExecution;
