import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "../../../helpers/helpers";
import "./FourTableRow.css";
// import {
//   faDollarSign,
//   faEuroSign,
//   faShekelSign,
// } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FourTableRow({
  id,
  text,
  turnover,
  initialPercentage,
  isSummary,
  totalBudget,
  totalActual,
  totalDifference,
  page,
  month,
  currency,
}) {
  const current_month = useSelector((state) => state.budget[month]);
  // const current = useSelector((state) => state.budget);
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState(initialPercentage);
  const [baseValue, setBaseValue] = useState(0);
  const [actualExpense, setActualExpense] = useState(0);
  // const [budgetAmount, setBudgetAmount] = useState(baseValue * percentage);
  const  budgetAmount = baseValue * percentage;

  useEffect(() => {
    if (current_month) {
      setPercentage(current_month[id + "_initialPercentage"]);
      setBaseValue(current_month.transaction_turnover_before_vat);
      setActualExpense(current_month[id + "_actual_expense"]);
    }
    else{
      setPercentage(0);
      setBaseValue(0);
      setActualExpense(0);
    }
  }, [current_month, id,]);
  useEffect(() => {
    if (!isSummary) {
      dispatch({ type: `${id}_budget_amount`, payload: budgetAmount });
      dispatch({ type: `${id}_actual_expense`, payload: actualExpense });
      dispatch({
        type: `${id}_budget_status`,
        payload: budgetAmount - actualExpense,
      });
      dispatch({ type: `${id}_initialPercentage`, payload: percentage });
      dispatch({ type: "UPDATE_TOTAL_BUDGET" });
      dispatch({ type: "UPDATE_TOTAL_EXPENSE" });
      dispatch({ type: "UPDATE_TOTAL_STATUS" });
    }
  }, [budgetAmount, actualExpense, dispatch, isSummary, id, percentage, month]);

  // const getCurrencySymbol = (currency) => {
  //   if (currency === faDollarSign)
  //     return (
  //       <h5>
  //         <FontAwesomeIcon icon={faDollarSign} />
  //       </h5>
  //     );
  //   if (currency === faShekelSign)
  //     return (
  //       <h5>
  //         <FontAwesomeIcon icon={faShekelSign} />
  //       </h5>
  //     );
  //   if (currency === faEuroSign)
  //     return (
  //       <h5>
  //         <FontAwesomeIcon icon={faEuroSign} />
  //       </h5>
  //     );
  //   return "";
  // };

  // const currencySymbol = getCurrencySymbol(currency);

  // const handleActualExpenseChange = (e) => {
  //   const value = Number(e.target.value.replace(",", "")) || 0;
  //   setActualExpense(value);
  //   dispatch({ type: `${id}_actual_expense`, payload: parseFloat(value) });
  //   return;
  // };

  const handlePercentageChange = (e) => {
    const value = parseFloat(e.target.value) / 100 || 0;
    setPercentage(value);
    return;
  };

  if (isSummary) {
    const differenceClass = totalDifference < 0 ? "negative" : "positive";
    return (
      <div className="container_grid_four row_height">
        <div className="budget_col"><p className="the_info">{text}</p></div>
        <div></div>
        <div className="input-container" dir="ltr">
        <FontAwesomeIcon icon={currency} />
          <p
            className="the_info_b"
            // className="input-table text_center"
            // defaultValue={formatNumber(totalBudget)}
            type="text"
            dir="ltr"
            // disabled
          >{formatNumber(totalBudget)}</p>
        </div>
        <div className="input-container" dir="ltr">
          <FontAwesomeIcon icon={currency} />
          <p
            // defaultValue={formatNumber(totalActual)}
            type="text"
            className="the_info_b"
            // disabled
            // className="input-table text_center"
          >{formatNumber(totalActual)}</p>
        </div>
        <div className={`input-container ${differenceClass}`} dir="ltr">
          <FontAwesomeIcon icon={currency} />
          <p
            // defaultValue={formatNumber(totalActual)}
            type="text"
            className="the_info_b"
            // disabled
            // className="input-table text_center"
          >{formatNumber(totalDifference)}</p>
        </div>
        {/* <div className='input-container' dir="ltr">
          <FontAwesomeIcon icon={currency} />
          <p
            // defaultValue={formatNumber(totalDifference)}
            className="ithe_info_b"
            type="text"
            disabled
            // className={`input-table ${differenceClass} text_center`}
          >{formatNumber(totalDifference)}</p>
        </div> */}
        {/* <div className={differenceClass}>{formatNumber(totalDifference)}</div> */}
      </div>
    );
  }
  return (
    <div className="container_grid_four row_height">
      <div className="budget_col"><p className="the_info">{text}</p></div>
      <div className="input-container">
        <input
          className="input-table text_center"
          type="number"
          defaultValue={
            current_month
              ? current_month[id + "_initialPercentage"] * 100
              : initialPercentage * 100
          }
          onChange={handlePercentageChange}
          min="0"
          max="100"
          step="1"
          dir="rtl"
        />
      </div>
      <div className="input-container" dir="ltr">
        {/* <p className="currency-symbol">{currencySymbol}</p> */}
        <FontAwesomeIcon icon={currency} />
        <p
          className=" the_info_b"
          // type="number"
          dir="ltr"
        >{formatNumber(budgetAmount)}</p>
      </div>
      <div className="input-container" dir="ltr">
        {/* <span className="currency-symbol">{currencySymbol}</span> */}
        <FontAwesomeIcon icon={currency} />
        <p
          className="the_info_b"
          // type="text"
          // defaultValue={formatNumber(actualExpense)}
          // onChange={handleActualExpenseChange}
          dir="ltr"
          // disabled
        >{formatNumber(actualExpense)}</p>
      </div>
      <div className="input-container" dir="ltr">
        {/* <span className="currency-symbol ">{currencySymbol}</span> */}
        <FontAwesomeIcon icon={currency} />
        <p
          className="the_info_b"
          // className="input-table text_center"
          // type="text"
          // defaultValue={formatNumber(budgetAmount - actualExpense)}
          disabled
          dir="ltr"
        >{formatNumber(budgetAmount - actualExpense)}</p>
      </div>
    </div>
  );
}

export default FourTableRow;

