const defaultScenariosReducer = {
  scenarios: [
    {
      case: "",  // Empty string for scenario text
      risk: "",
      chance: "",
      options: [{ name: "" }],
      monitoring: "",  // Empty string for monitoring measure text
    },
  ],
};

export const scenariosReducer = (state = defaultScenariosReducer, action) => {
  switch (action.type) {
    case "scenarios":
      return { ...state, scenarios: action.payload };
    case "save_scenarios":
      // Save the new scenarios payload to the state
      return { ...state, scenarios: action.payload };
    case "reset_scenarios":
      // Reset all fields, including text fields, to default values
      return { ...defaultScenariosReducer };
    default:
      return state;
  }
};
