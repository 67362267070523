import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import emailjs from "emailjs-com";
import "./CreatePage2.css";

const SERVICE_ID = "service_5m5vvpm";
const TEMPLATE_ID = "template_nvd87st";
const USER_ID = "guGAcwcx5swVscWSP";

function CreatePage2() {
  const { t } = useTranslation("Experience");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [emailSentMessage, setEmailSentMessage] = useState("");

  // Récupérer les données actuelles du formulaire depuis Redux
  const { emailTitle, messageContent, googleFormLink, excelFile, preparedBy ,senderEmail} = useSelector((state) => state.customer.currentForm);

  const handleInputChange = (field, value) => {
    dispatch({ type: `SET_${field.toUpperCase()}`, payload: value });
  };

  const handleExcelUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const emails = jsonData.slice(1).map((row) => row[1]).filter(Boolean);
        dispatch({ type: "SET_EXCEL_FILE", payload: emails });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const sendEmails = () => {
    if (!excelFile || excelFile.length === 0) {
      console.error("No email addresses found to send emails.");
      setEmailSentMessage(t("No email addresses found"));
      return;
    }
  
    if (!emailTitle || !messageContent || !preparedBy || !googleFormLink) {
      setEmailSentMessage(t("Please fill in all required fields before sending the email"));
      return;
    }
  
    const finalSenderEmail = senderEmail || "default@domain.com";
  
    console.log("Sending emails with:", { finalSenderEmail, emailTitle, messageContent, googleFormLink });
  
    excelFile.forEach((email) => {
      const templateParams = {
        to_email: email,
        email_title: emailTitle,
        message: `${messageContent}\n\nLink: ${googleFormLink}`,
        from_email: finalSenderEmail,
        from_name: preparedBy || t("dr_elie_hani") 
      };
  
      emailjs
        .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
        .then((response) => {
          console.log("Email successfully sent to:", email, response.status, response.text);
          setEmailSentMessage(`${t("Email sent to")}: ${email}`);
        })
        .catch((err) => {
          console.error("Failed to send email to:", email, err);
        });
    });
  };
  

  const exportTemplate = () => {
    const templateData = [[t("First Name"), t("Email Address"), t("Phone")]];
    const worksheet = XLSX.utils.aoa_to_sheet(templateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    XLSX.writeFile(workbook, "email_template.xlsx");
  };

  const result = () => {
    navigate(`/create3/${id}`);
  };


  return (
    <div className="create-page">
      <div className="create-header">
        <button className="create-back-button" onClick={() => navigate("/customer-experience")}>
          ← {t("Back_to")}
        </button>
      </div>

        {/* Progress Bar */}
        <div className="create-progress-bar">
          <div className="create-step completed" onClick={() => navigate(`/create1/${id}`)}>1</div>
          <div className="create-step completed" onClick={() => navigate(`/create2/${id}`)}>2</div>
          <div className="create-step" onClick={() => navigate(`/create3/${id}`)}>3</div>
        </div>

      <div className="create-form-section">
        <div className="create-input-row">
          <label>{t("Email title")}</label>
          <textarea
            value={emailTitle}
            placeholder={t("Text")}
            onChange={(e) => dispatch({ type: "SET_EMAIL_TITLE", payload: e.target.value })}
            className="create-input-field"
          />
        </div>
        <div className="create-input-row">
          <label>{t("Message")}</label>
          <textarea
            value={messageContent}
            placeholder={t("hi")}
            onChange={(e) => dispatch({ type: "SET_MESSAGE_CONTENT", payload: e.target.value })}
            className="create-input-field"
          />
        </div>
        <div className="create-input-row">
          <label>{t("Google Form Link")}</label>
          <input
            type="text"
            value={googleFormLink}
            placeholder={t("Enter the link here")}
            onChange={(e) => dispatch({ type: "SET_GOOGLE_FORM_LINK", payload: e.target.value })}
            className="create-input-field"
          />
        </div>
         {/* <div className="create-input-row">
          <label>{t("Sender Email")}</label>
          <input
            type="email"
            value={senderEmail}
            placeholder={t("Enter sender email")}
            onChange={(e) => dispatch({ type: "SET_SENDER_EMAIL", payload: e.target.value })}
            className="create-input-field"
          />
        </div>  */}
      </div>

      <div className="create-form-section">
        <button className="top_button_exemple" onClick={exportTemplate}>
          {t("Export Template")}
        </button>
        <label className="top_button_exemple">
          {t("Import Data")}
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleExcelUpload}
            style={{ display: "none" }}
          />
        </label>
        <button className="top_button_create" onClick={sendEmails}>
          {t("Send")}
        </button>
      </div>

      {/* Affichage du message après l'envoi des emails */}
      {emailSentMessage && <div className="email-sent-message">{emailSentMessage}</div>}

      {/* Boutons "Back" et "Next" */}
      <button className="top_button_back" onClick={() => navigate(`/create1/${id}`)}>
        {t("Back")}
      </button>
      <button className="top_button_next" onClick={result}>
        {t("Next")}
      </button>
    </div>
  );
}

export default CreatePage2;
