import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { stages } from "../../../src/data/SalesCustomerStages";
import "./SalesCustomer.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton";

export default function SalesCustomer() {
  const [currentStage, setCurrentStage] = useState(1);
  const { t } = useTranslation("Sales");

  const handleStageChange = (stageId) => {
    setCurrentStage(stageId);
  };

  // Function to download the PDF for the current stage content only
  const downloadPDF = () => {
    const input = document.querySelector(".stage_content"); // Target the stage_content part
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // A4 width in mm minus margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("customer-journey-stage.pdf");
    });
  };

  return (
    <div>
      {/* Video Section */}
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url3")} />
      </div>

      <PdfButton onClick={downloadPDF} />
      <div className="sales_customer" id="pdf-content">
        {/* <div className="header-title">{t("Stage_2_Title")}</div> */}

        <div className="progress_container">
          {stages.map((stage, index) => (
            <div key={index} className="progress_step">
              <div
                className={`circle ${currentStage > stage.id ? "completed" : ""}
                ${currentStage === stage.id && "current"}`}
                onClick={() => handleStageChange(stage.id)}
              >
                {stage.id}
              </div>
              {index < stages.length - 1 && (
                <div className={`line ${currentStage > stage.id ? "filled" : ""}`}/>
              )}
            </div>
          ))}
        </div>
        <div className="stage_content">
          <h2 className="text_center">{t(stages[currentStage - 1]?.text)}</h2>
          <p className="text_center">{t(stages[currentStage - 1]?.description)}</p>
        </div>
      </div>


    </div>
  );
}
