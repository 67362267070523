const initialState = {
  forms: [],
  currentForm: {
    id: null,
    startDate: null,
    lastUpdate: null,
    questions: [
      { id: 1, key: "How would you rate the service experience you received?", text: "" },
      { id: 2, key: "Did the consultant meet your expectations?", text: "" },
      { id: 3, key: "How would you rate the courtesy and attentiveness of the team?", text: "" },
      { id: 4, key: "What do you think we could do better to improve?", text: "" }
    ],
    image: null,
    nameOfProcedure: "",
    numberOfProcedure: "",
    numberOfPages: "",
    preparedBy: "",
    authorizedToMakeChanges: "",
    approval: "",
    emailAddress: "",
    emailTitle: "",
    messageContent: "",
    excelFile: [],
    googleFormLink: "",
    senderEmail: "",
  }
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {

    // Setter for sender email
    case "SET_SENDER_EMAIL":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          senderEmail: action.payload
        }
      };

    // Create new form and reset current form
    case "CREATE_NEW_FORM":
      return {
        ...state,
        forms: [
          ...state.forms,
          { ...initialState.currentForm, id: action.payload } // Create a new form with the new ID
        ],
        currentForm: { ...initialState.currentForm, id: action.payload } // Reset the current form with the new ID
      };

    // Save the current form to the forms array by updating the existing one
    case "SAVE_CURRENT_FORM":
      return {
        ...state,
        forms: state.forms.map((form) =>
          form.id === state.currentForm.id ? state.currentForm : form
        )
      };

    // Load a form from the forms array based on its ID
    case "LOAD_FORM":
      const selectedForm = state.forms.find((form) => form.id === action.payload);
      return {
        ...state,
        currentForm: selectedForm
          ? { ...selectedForm }
          : { ...initialState.currentForm, id: action.payload }
      };

    // Generic setter for multiple fields in current form
    case "SET_GOOGLE_FORM_LINK":
    case "SET_START_DATE":
    case "SET_LAST_UPDATE":
    case "SET_NAME_OF_PROCEDURE":
    case "SET_NUMBER_OF_PROCEDURE":
    case "SET_NUMBER_OF_PAGES":
    case "SET_PREPARED_BY":
    case "SET_AUTHORIZED_TO_MAKE_CHANGES":
    case "SET_APPROVAL":
    case "SET_EMAIL_TITLE":
    case "SET_MESSAGE_CONTENT":
    case "SET_EXCEL_FILE":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          // Use dynamic property update
          [action.type.slice(4).toLowerCase().replace(/_([a-z])/g, (_, char) => char.toUpperCase())]: action.payload
        }
      };

    // Setter for updating question text
    case "SET_QUESTION_TEXT":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: state.currentForm.questions.map((question) =>
            question.id === action.payload.id
              ? { ...question, text: action.payload.text }
              : question
          )
        }
      };

    // Add a new question to the questions array
    case "ADD_QUESTION":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: [
            ...state.currentForm.questions,
            { id: state.currentForm.questions.length + 1, text: "" }
          ]
        }
      };

    // Remove a question from the questions array
    case "REMOVE_QUESTION":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: state.currentForm.questions.filter(
            (question) => question.id !== action.payload
          )
        }
      };

    // Set an image in the current form
    case "SET_IMAGE":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          image: action.payload
        }
      };

    // Remove a form by its ID
    case "REMOVE_FORM":
      return {
        ...state,
        forms: state.forms.filter((form) => form.id !== action.payload)
      };

    // Duplicate a form and assign a new ID to the duplicated form
    case "DUPLICATE_FORM":
      const formToDuplicate = state.forms.find((form) => form.id === action.payload.id);
      if (!formToDuplicate) return state;

      const duplicatedForm = {
        ...formToDuplicate,
        id: action.payload.newId, // Assign a new ID
        lastUpdate: new Date().toLocaleDateString() // Update the last update date
      };

      return {
        ...state,
        forms: [...state.forms, duplicatedForm] // Add the duplicated form to the list
      };

    // Default case for returning current state
    default:
      return state;
  }
};

export default customerReducer;
