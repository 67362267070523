import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import "./i18n";
import { store } from "./Reducers/index.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Login from "./Pages/Login/Login.js";
import Layout from "./layouts/Layout.js";
import Loader from "./components/Loader/Loader.js";

import FirstPage from "./Pages/FirstPage/FirstPage";
import Budget from "./Pages/Budget/Budget.js";
import Income from "./Pages/Income/Income.js";
import ConcentrationDebts from "./Pages/ConcentrationDebts/ConcentrationDebts.js";

import Sales from "./Pages/Sales/Sales.js";
import AdvertisingChannels from "./Pages/AdvertisingChannels/AdvertisingChannels.js";
import SalesCustomer from "./Pages/SalesCustomer/SalesCustomer.js";
import SalesTraining from "./Pages/SalesTraining/SalesTraining.js";
import SalesManagment from "./Pages/SalesManagment/SalesManagment.js";

import MarketingControl from "./Pages/MarketingControl/MarketingControl.js";
import WorkPlan from "./Pages/WorkPlan/WorkPlan.js";
import CustomerExperience from "./Pages/CustomerExperience/CustomerExperience.js";
import ExamplePage from "./Pages/CustomerExperience/ExamplePage";
import CreatePage1 from "./Pages/CustomerExperience/CreatePage1";
import CreatePage2 from "./Pages/CustomerExperience/CreatePage2";
import CreatePage3 from "./Pages/CustomerExperience/CreatePage3";
import CreatingWorkflow from "./Pages/CreatingWorkflow/CreatingWorkflow.js";
import DefineRules from "./Pages/DefineRules/DefineRules.js";
import ManagingScenarios from "./Pages/ManagingScenarios/ManagingScenarios.js";
import CompetitorAnalisys from "./Pages/CompetitorAnalisys/CompetitorAnalisys.js";
import PlaningVsExecution from "./Pages/PlaningVsExecution/PlaningVsExecution.js";
import LearningLessons from "./Pages/LearningLessons/LearningLessons.js";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import MakingDecisions from "./Pages/MakingDecisions/MakingDecisions.js";
import TermsAndConditions from "./Pages/Login/TermsAndConditions.js";
import ExamplePage2 from "./Pages/DefineRules/ExamplePage.js";
import CreateProcedure from "./Pages/DefineRules/CreateProcedure.js";
import ExamplePage3 from "./Pages/CreatingWorkflow/ExamplePage.js";
// import CreateWorkflow from "./Pages/CreatingWorkflow/CreatingWorkflow.js";
import Createflow from "./Pages/CreatingWorkflow/CreatePage.js";
import CreateFormLessons from "./Pages/LearningLessons/CreateFormLessons.js";
import Home from "./Pages/Home/Home.js"

function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/home" element={<Home/>} />
                <Route path="/dashboard" element={<Dashboard/>} />
                {/* finance */}
                <Route path="/first" element={<FirstPage />} />
                <Route path="/second" element={<Budget />} />
                <Route path="/third" element={<Income />} />
                <Route path="/concentration-debts" element={<ConcentrationDebts/>} />

                {/* sales */}
                <Route path="/sales" element={<Sales />} />
                <Route path="/sales-customer" element={<SalesCustomer />} />
                <Route path="/sales-training" element={<SalesTraining />} />
                <Route path="/advertising-channels"element={<AdvertisingChannels />}/>
                <Route path="/sales-managment" element={<SalesManagment />} />
                <Route path="/sales-effectiveness" element={<MarketingControl />}/>
                <Route path="/competitor-analisys-report" element={<CompetitorAnalisys/>}/>
                {/* managment & operations */}
                <Route path="/creating-workflow" element={<CreatingWorkflow />}/>
                <Route path="/example3" element={<ExamplePage3/>}/>
                <Route path="/create-workflow/:id" element={<Createflow />} />
                <Route path="/create-workflow" element={<Createflow />} />
                <Route path="/confined-work-plan" element={<WorkPlan />}/>
                <Route path="/customer-experience" element={<CustomerExperience />}/>
                <Route path="/create1/:id" element={<CreatePage1 />} />
                <Route path="/create2/:id" element={<CreatePage2 />}/>
                <Route path="/create3/:id" element={<CreatePage3 />}/>
                <Route path="/create3/:formId" element={<CreatePage3 />} />
                <Route path="/example" element={<ExamplePage />} />
                <Route path="/create1" element={<CreatePage1 />} />
                <Route path="/create2" element={<CreatePage2 />} />
                <Route path="/create3" element={<CreatePage3 />} />
                <Route path="/define-rules" element={<DefineRules/>}/>
                <Route path="/example2" element={<ExamplePage2/>}/>
                <Route path="/createProcedure/:id" element={<CreateProcedure />} />
                <Route path="/createProcedure" element={<CreateProcedure />} />
                <Route path="/scenario&risk-managment" element={<ManagingScenarios/>} />
                <Route path="/planing-vs-execution" element={<PlaningVsExecution/>} />
                <Route path="/learning-lessons" element={<LearningLessons/>}/>
                <Route path="/lessons-create/:id" element={<CreateFormLessons />} />
                <Route path="/lessons-create" element={<CreateFormLessons />} />
                <Route path="/making-choice" element={<MakingDecisions/>}/>
              </Routes>
            </Layout>
          </BrowserRouter>
        </Suspense>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
