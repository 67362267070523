import axios from "axios";

// POST request to save competitor analysis data
export const CompetitorPostReq = async (data) => {
  try {
    const response = await axios.post("http://localhost:5000/api/competitor", data);
    console.log("POST Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("POST Error:", error.message);
    // Handle error here, e.g., notify the user
  }
};

// GET request to retrieve saved competitor analysis data
export const CompetitorGetReq = async () => {
  try {
    const response = await axios.get("http://localhost:5000/api/competitor");
    console.log("GET Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Error:", error.message);
    // Handle error here
  }
};

// PUT request to update competitor analysis data
export const CompetitorPutReq = async (id, data) => {
  try {
    const response = await axios.put(`http://localhost:5000/api/competitor/${id}`, data);
    console.log("PUT Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("PUT Error:", error.message);
    // Handle error here
  }
};

// DELETE request to remove competitor analysis data
export const CompetitorDeleteReq = async (id) => {
  try {
    const response = await axios.delete(`http://localhost:5000/api/competitor/${id}`);
    console.log("DELETE Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("DELETE Error:", error.message);
    // Handle error here
  }
};
