import React from "react";
import "./HomeVideoModal.css";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

function HomeVideoModal({ videoUrl }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const Video_open = useSelector((state) => state.income.Video_open);


  return (
    <div className={`player-wrapper ${i18n.language === "he" ? "rtl" : "ltr"}`}>
      {Video_open && (
        <div>
          <div className="video-container1">
            <ReactPlayer
              className="react-player1"
              url={videoUrl}
              playing={true}
              controls={true}
              width="100%"
              height="97%"
            />
            <div className="reload-button-container">
              <div className="buttons-container">
                {/* <ReloadButton /> */}
                <button
                  className="exsit-home-button"
                  onClick={() => {
                    dispatch({ type: "Video_open", payload: false });
                  }}
                >
                  X
                  {/* <FontAwesomeIcon icon={faCircleXmark} /> */}
                </button>
              </div>
            </div>
          </div>
          {/* <VideoSizeButton size={size} setSize={setSize} /> */}
        </div>
      )}
    </div>
  );
}

export default HomeVideoModal;
