import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./ObjectivSelect.css";

function ObjectivSelect(props) {
    const { state, setState, id, updateGoals, currentGoals, value, currentIndictors, updateIndictors } = props;
    const { t } = useTranslation(["Targets"]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value.name || t('monthly_sales'));
    const [inputValue, setInputValue] = useState(value.name || t('monthly_sales'));
    const [isAdding, setIsAdding] = useState(false);
    const [valueInput, setValueInput] = useState(value.value);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const updateValue = (newName, newValue) => {
        setSelectedValue(newName);
        setInputValue(newName);
        setState([...state.slice(0, id), { ...state[id], name: newName, value: newValue }, ...state.slice(id + 1)]);
        updateIndictors([...currentIndictors.slice(0, id), { ...currentIndictors[id], name: newName, value: newValue }, ...currentIndictors.slice(id + 1)]);
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleValueInputChange = (e) => {
        setValueInput(e.target.value);
    };

    const handleInputBlur = () => {
        updateValue(inputValue, valueInput);
        setIsAdding(false);
    };

    const removeItem = () => {
        setState([...state.slice(0, id), ...state.slice(id + 1)]);
        const updatedGoals = currentGoals.map((goal) => {
            goal.objectives = [...goal.objectives.slice(0, id), ...goal.objectives.slice(id + 1)];
            return goal;
        });
        updateGoals(updatedGoals);
    };

    return (
        <Fragment>
            <div className='selector_container' ref={dropdownRef}>
                <div className='selector_box scrollable'>
                    <button className="select_button scrollable" onClick={() => setIsOpen(!isOpen)}>
                        {selectedValue}
                    </button>
                    {isOpen && (
                        <div className="options_window scrollable">
                            <ul>
                                <li onClick={() => setIsAdding(true)}>
                                    {t('add_text')}
                                </li>
                                {['monthly_sales', 'monthly_production', 'monthly_increase', 'happiness_index', 'minimum_profit', 
                            'purchase_property', 'improving_operating_profi', 'generating_leads', 'minimum_transactions', 
                            'unique_product', 'improving_profit_before_tax', 'weekly_control', 
                            'increasing_financial_repayments', 'new_regional_distribution', 'franchise_store', 
                            'minimum_customer_score', 'leaving_work', 'new_market', 'repetitive_jobs'].map((objective, index) => (
                                    <li key={index} onClick={() => updateValue(t(objective), valueInput)}>
                                        {t(objective)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {isAdding && (
                        <div className="input_window">
                            <input
                                type="text"
                                className="selector_input"
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                placeholder={t('custom_input')}
                            />
                        </div>
                    )}
                    <input
                        type="number"
                        className="selector_input"
                        value={valueInput}
                        onChange={handleValueInputChange}
                        onBlur={handleInputBlur}
                        placeholder={t('value')}
                    />
                </div>
                <button type='button' className="remove_button" onClick={removeItem}>X</button>
            </div>
        </Fragment>
    );
}

export default ObjectivSelect;
