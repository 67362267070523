import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux"; // Ensure both useDispatch and useSelector are imported
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faPaperPlane,faClone } from "@fortawesome/free-solid-svg-icons"; // Import "Send" icon
import "./DefineRules.css";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";

function DefineRules() {
  const { t } = useTranslation("DefineRules");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forms } = useSelector((state) => state.define); // Get dynamic forms from Redux

  // Function to download the PDF (existing functionality)
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // A4 width in mm minus margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const marginRight = 15; // Right margin in mm
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", marginRight, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", marginRight, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("Writing-Procedures.pdf");
    });
  };

  return (
    <Fragment>

      {/* Video Section */}
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>



      {/* Table Section */}
      <table className="experience-table_define">
        <thead>
          <tr>
            <th>{t("name_of_survey")}</th>
            <th>{t("date_created")}</th>
            <th>{t("status")}</th>
            <th>{t("last_update")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {/* Static example row */}
          <tr>
            <td
              onClick={() => navigate("/example2")}
              className="clickable-cell"
              style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
            >
              {t("Exemple")}
            </td>
            <td>01/01/2024</td>
            <td>{t("Exemple")}</td>
            <td>02/01/2024</td>
            <td>
              <button className="action-btn">
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button className="action-btn">
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </td>
          </tr>

          {/* Dynamic form rows */}
          {forms.map((form) => (
            <tr key={form.id}>
              <td
                onClick={() => {
                  // Charger les données du formulaire dans Redux avant de naviguer
                  dispatch({ type: "LOAD_DEFINE_FORM", payload: form.id });
                  navigate(`/createProcedure/${form.id}`);
                }}
                className="clickable-cell"
                style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
              >
                {form.nameOfProcedure}
              </td>
              <td>{form.startDate}</td>
              <td>{form.status || t("Pending")}</td>
              <td>{form.lastUpdate}</td>
              <td>
                <button className="action-btn" onClick={() => navigate(`/createProcedure/${form.id}`)}>
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <button
                  className="action-btn"
                  onClick={() => {
                    console.log("Form ID to remove:", form.id); // Vérification de l'ID
                    dispatch({ type: "REMOVE_DEFINE_FORM", payload: form.id });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
                {/* Duplicate button */}
  <button
    className="action-btn"
    onClick={() => {
      const newFormId = forms.length + 1; // Generate a new ID for the duplicated form
      dispatch({ type: "DUPLICATE_DEFINE_FORM", payload: { id: form.id, newId: newFormId } });
    }}
  >
    <FontAwesomeIcon icon={faClone} /> {/* Icon for duplication */}
  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Button Section */}
      <div className="button-container1">
        <button className="top_button_exemple" onClick={() => navigate("/example2")}>
          {t("See example")}
        </button>
        <button className="top_button_create" onClick={() => {
          dispatch({ type: "RESET_DEFINE-FORM" });
          navigate("/createProcedure");
        }}>
          {t("Create")}
        </button>
      </div>
    </Fragment>
  );
}

export default DefineRules;
