import React, { Fragment } from 'react'
import './Warning.css'
function Warning(props) {
    const {text} = props
  return (
    <Fragment>
                <div className='warning_line'>
        <div className='warning_icon'></div>
        <div  className="warning_text">
        {text}
        </div>
        </div>
    </Fragment>
  )
}

export default Warning