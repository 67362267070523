import React, {useEffect, useState } from "react";
import ReasonSelect from "../../Selectors/ReasonSelect/ReasonSelect";
import FivePositionSelect from "../../Selectors/FivePositionSelect/FivePositionSelect";
import { useDispatch, useSelector} from "react-redux";
import "./IncomeTableInput.css";
import { useTranslation } from "react-i18next";
import { formatNumber } from '../../../helpers/helpers';

function IncomeTableInput(props) {
    const {month, input_number, action} = props;
    const { t } = useTranslation("Incom");
    const [status , setStatus] = useState(0)
    // const [selectedOption, setSelectedOption] = useState(action.income_expence_select);
    const current_month = useSelector(state => state.income[month]);
    const current_account_status_base = useSelector(state => state.income.current_account_status_base);
    const Month_action = useSelector((state) => state.income.Month_action_monthly_flow);;
    // const account_frame = useSelector((state) => state.income.account_frame);
    const dispatch = useDispatch();
useEffect(()=>{
    if(Month_action.length > 0 && (Month_action[input_number].income_revenue > 0 ||Month_action[input_number].payments_expenses > 0 )){
        const current = Month_action.slice(0, 1 + input_number).reduce((acc, action)=>{
           return +acc + Number(action.income_revenue) - Number(action.payments_expenses)
        }, 0)
        if(current !== 0)setStatus( +current_account_status_base + current)
    else{
        setStatus(0)    }}
    else{
        setStatus(0)
    }
},[Month_action, current_account_status_base, input_number])
    const handleSelectChange = (e) => {
        const {name, value, id} = e.target
        // dispatch({ type: month + page, payload: {...current_month, [e.target.name]:e.target.value}});
        dispatch({ type: "Month_action_monthly_flow", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],[name]: value}, ...Month_action.slice([1+ +id])] });
        return
      };
      const addExpenseValue = (e) => {
          const { value, id} = e.target
          const totalI = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return acc + +action.income_revenue },0) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.income_revenue },0)
          const totalE = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },value) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },0)
          dispatch({ type: "total_income_monthly_flow", payload: totalI });
        dispatch({ type: "total_expense_monthly_flow", payload: totalE });
        // dispatch({ type: "payments_expenses", payload: totalE });
        // dispatch({ type: "current_account_status", payload: +current_status + -value });
        dispatch({ type: "Month_action_monthly_flow", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],payments_expenses: value,income_revenue: 0}, ...Month_action.slice([1+ +id])] });
        return;
      };
      const addIncomeValue = (e) => {
          const { value, id} = e.target
        const totalI = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return +acc + +action.income_revenue },value) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.income_revenue },0)
        const totalE = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return acc + +action.payments_expenses },0) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },0)
        dispatch({ type: "total_income_monthly_flow", payload: totalI });
        dispatch({ type: "total_expense_monthly_flow", payload: totalE });
        dispatch({ type: "Month_action_monthly_flow", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],payments_expenses: 0,income_revenue: value}, ...Month_action.slice([1+ +id])] });

        return
     };




      const addData = (e) => {
        const { value, name, id} = e.target
        dispatch({ type: "Month_action_monthly_flow", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],[name]: value}, ...Month_action.slice([1+ +id])] });
        return
      };

  return (
    <div id="status" className="container_grid_nine_income">
    <div className="input_div">
      <input
     className="date income_table_input"
     id={input_number}
     name="date"
     type="date"
     onChange={addData}
     value={action.date !== "0000-00-00"? action.date : ""}
       />
    </div>
    <div className="input_div">
      <select  id={input_number} value={action.income_expence_select} name={"income_expence_select"} className="income-expence-select income_table_input" onChange={(e)=>{
        handleSelectChange(e)}}>
        <option value="0">{t("Income")}</option>
        <option value="1">{t("Expence")}</option>
      </select>
    </div>
    <div className="input_div">
      <ReasonSelect month={current_month} id={input_number} aValue={action.reason_select} handleSelectChange={handleSelectChange}/>
    </div>
    <div className="input_div">
      <input className="detail_name income_table_input" type="text"  name={"detail_name"}defaultValue={action.detail_name} onChange={addData}/>
    </div>
    <div className="input_div">
      <FivePositionSelect month={current_month} fpValue={action.fivePositionSelect} id={input_number} handleSelectChange={handleSelectChange} />
    </div>
    <div  className={`${action.income_expence_select === "0" ?"expanse_div_unacticve" :  "expanse_div"}`}>
      <input
        name={'Expense'}
        defaultValue={action.payments_expenses > 0 ? action.payments_expenses : ""}
        className="income_table_input_number"
        placeholder="0"
        id={input_number}
        dir="ltr"
        onBlur={(e)=> addExpenseValue(e)}
        disabled={action.income_expence_select === "0"}
        type="number"
      />
    </div>
    <div className={`${action.income_expence_select === "1" ?"income_div_unacticve" :  "income_div"}`}>
      <input
        name={"Income"}
        className="income_table_input_number"
        placeholder="0"
        defaultValue={action.income_revenue > 0 ? action.income_revenue : ""}
        dir="ltr"
        onBlur={(e)=> addIncomeValue(e)}
        id={input_number}
        // onChange={(e)=>addIncomeValue(e)}
        disabled={action.income_expence_select === "1"}
        type="number"
      />{" "}
    </div>
    <div className="input_div">
      <input
      className=" income_table_input_number"
        name="current_account_status"
        placeholder="0"
        readOnly
        value={formatNumber(status)}
        dir="ltr"
        disabled
      />{" "}
    </div>
    <div className="input_div">
      <input className="income_table_input"   id={input_number} type="text" defaultValue={current_month? current_month["notes"+input_number] : ""} name={"notes" + input_number} onChange={addData}/>
    </div>
  </div>
  )
}

export default IncomeTableInput;