const initialState = {
    forms: [], // List of forms
    currentForm: {
      startDate: null,
      lastUpdate: null,
      questions: [{ id: 1, text: "" }], // Initialize with a blank question
      image: null,
      nameOfProcedure: "",
      numberOfProcedure: "",
      numberOfPages: "",
      preparedBy: "",
      authorizedToMakeChanges: "",
      approval: "",
      emailAddress: "",
      emailTitle: "",
      messageContent: "",
      excelFile: [],
      isSubmitted: false,
    },
  };
  
  // DefineReducer to handle the form actions
  const defineReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_DEFINE_START_DATE":
        return {
          ...state,
          currentForm: { ...state.currentForm, startDate: action.payload },
        };
  
      case "SET_DEFINE_LAST_UPDATE":
        return {
          ...state,
          currentForm: { ...state.currentForm, lastUpdate: action.payload },
        };
  
      case "SET_DEFINE_NAME_OF_PROCEDURE":
        return {
          ...state,
          currentForm: { ...state.currentForm, nameOfProcedure: action.payload },
        };
  
      case "SET_DEFINE_NUMBER_OF_PROCEDURE":
        return {
          ...state,
          currentForm: { ...state.currentForm, numberOfProcedure: action.payload },
        };
  
      case "SET_DEFINE_NUMBER_OF_PAGES":
        return {
          ...state,
          currentForm: { ...state.currentForm, numberOfPages: action.payload },
        };
  
      case "SET_DEFINE_PREPARED_BY":
        return {
          ...state,
          currentForm: { ...state.currentForm, preparedBy: action.payload },
        };
  
      case "SET_DEFINE_AUTHORIZED_TO_MAKE_CHANGES":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            authorizedToMakeChanges: action.payload,
          },
        };
  
      case "SET_DEFINE_APPROVAL":
        return {
          ...state,
          currentForm: { ...state.currentForm, approval: action.payload },
        };
  
      case "SET_DEFINE_QUESTION_TEXT":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            questions: state.currentForm.questions.map((question) =>
              question.id === action.payload.id
                ? { ...question, text: action.payload.text }
                : question
            ),
          },
        };
  
      case "ADD_DEFINE_QUESTION":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            questions: [
              ...state.currentForm.questions,
              { id: state.currentForm.questions.length + 1, text: "" }, // Add new question with unique ID
            ],
          },
        };
  
      case "REMOVE_DEFINE_QUESTION":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            questions: state.currentForm.questions.filter(
              (question) => question.id !== action.payload
            ),
          },
        };
  
      case "SET_DEFINE_IMAGE":
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            image: action.payload, // Store base64 image
          },
        };
  
      case "LOAD_DEFINE_FORM":
        const selectedForm = state.forms.find(
          (form) => form.id === action.payload
        );
        return {
          ...state,
          currentForm: selectedForm ? { ...selectedForm } : { ...state.currentForm },
        };
  
      case "ADD_DEFINE_FORM":
        return {
          ...state,
          forms: [...state.forms, { ...state.currentForm, id: state.forms.length + 1 }],
          currentForm: { // Reset form after adding
            startDate: null,
            lastUpdate: null,
            questions: [{ id: 1, text: "" }],
            image: null,
            nameOfProcedure: "",
            numberOfProcedure: "",
            numberOfPages: "",
            preparedBy: "",
            authorizedToMakeChanges: "",
            approval: "",
            emailAddress: "",
            emailTitle: "",
            messageContent: "",
            excelFile: [],
            isSubmitted: true,
          },
        };
  
      case "RESET_DEFINE_FORM":
        return {
          ...state,
          currentForm: {
            startDate: null,
            lastUpdate: null,
            questions: [{ id: 1, text: "" }],
            image: null,
            nameOfProcedure: "",
            numberOfProcedure: "",
            numberOfPages: "",
            preparedBy: "",
            authorizedToMakeChanges: "",
            approval: "",
            emailAddress: "",
            emailTitle: "",
            messageContent: "",
            excelFile: [],
            isSubmitted: false,
          },
        };
  
      case "REMOVE_DEFINE_FORM":
        return {
          ...state,
          forms: state.forms.filter((form) => form.id !== action.payload),
        };

        case "DUPLICATE_DEFINE_FORM":
      const formToDuplicate = state.forms.find((form) => form.id === action.payload.id);
      if (!formToDuplicate) return state;

      const duplicatedForm = {
        ...formToDuplicate,
        id: action.payload.newId, // Assign a new ID
        lastUpdate: new Date().toLocaleDateString() // Update the last modified date
      };

      return {
        ...state,
        forms: [...state.forms, duplicatedForm] // Add the duplicated form to the list
      };
  
      default:
        return state;
    }
  };
  
  export default defineReducer;
  