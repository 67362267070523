import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";

function ActionBox({ 
  number, 
  actionData, 
  onActionChange, 
  onDuplicate, 
  onRemove,
  onStartLine,
  onEndLine,
  onUploadPhoto = () => {}
}) {
  const { t, i18n } = useTranslation("Workflow");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const photoUrl = URL.createObjectURL(file);
      onActionChange({
        ...actionData, 
        photoUrl: photoUrl,
        photoName: file.name,
      });
      onUploadPhoto(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onActionChange({ ...actionData, [name]: value });
  };

  useEffect(() => {
    return () => {
      if (actionData.photoUrl) {
        URL.revokeObjectURL(actionData.photoUrl);
      }
    };
  }, [actionData.photoUrl]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const isRTL = i18n.dir() === 'rtl';

  return (
    <div className="box_action">
      <div className="step">{t('step')} {number}</div>

      <div className="action-box-header">
        <button className="icon-button" onClick={toggleDropdown}>⋮</button>
        {dropdownVisible && (
          <div className={`dropdown-menu show ${isRTL ? "dropdown-left" : "dropdown-right"}`} ref={dropdownRef}>
            <div className="dropdown-menu-item" onClick={onDuplicate}>
              <FontAwesomeIcon icon={faCopy} /> {t('duplicate')}
            </div>
            <div className="dropdown-menu-item">
              <label>
                <FontAwesomeIcon icon={faUpload} /> {t('uploadPhoto')}
                <input type="file" onChange={handlePhotoUpload} style={{ display: "none" }} />
              </label>
            </div>
            <div className="dropdown-menu-item" onClick={onRemove}>
              <FontAwesomeIcon icon={faTrash} /> {t('remove')}
            </div>
          </div>
        )}
      </div>

      {actionData.photoUrl && (
        <div className="uploaded-photo">
          <img src={actionData.photoUrl} alt={actionData.photoName} />
        </div>
      )}

      <div className="card-title">
        <label htmlFor="cardTitle">{t('cardTitle')}:</label>
        <input
          type="text"
          name="cardTitle"
          value={actionData.cardTitle}
          onChange={handleInputChange}
          className="small-input"
        />
      </div>

      <div className="details">
        <div>
          <label htmlFor="responsibility">{t('responsibility')}:</label>
          <input
            type="text"
            name="responsibility"
            value={actionData.responsibility}
            onChange={handleInputChange}
            className="small-input"
          />
        </div>
        <div>
          <label htmlFor="time">{t('time')}:</label>
          <input
            name="time"
            value={actionData.time}
            onChange={handleInputChange}
            className="small-input"
          />
        </div>
        <div>
          <label htmlFor="procedure">{t('procedure')}:</label>
          <input
            name="procedure"
            value={actionData.procedure}
            onChange={handleInputChange}
            className="small-input"
          />
        </div>
        <div>
          <label htmlFor="notes">{t('notes')}:</label>
          <input
            name="notes"
            value={actionData.notes}
            onChange={handleInputChange}
            className="small-input"
          />
        </div>
      </div>

      <div className="connection-buttons">
        <button className="connect-button" onClick={onStartLine}>
          {t("startConnection")}
        </button>
        <button className="connect-button" onClick={onEndLine}>
          {t("endConnection")}
        </button>
      </div>
    </div>
  );
}

export default ActionBox;