import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './PlaningVsExecution.css';
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { months } from '../../helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MonthSelectVsExecution from "../../components/Selectors/MonthSelectVs/MonthSelectVs"; // Adjust the path if necessary


function PlaningVsExecution() {
    const { t } = useTranslation('Vs');
    const dispatch = useDispatch();
    const selectedMonth = useSelector(state => state.vs.selectedMonth);
    const projects = useSelector((state) => state.vs.projects[selectedMonth]);
    const [formId] = useState('planingVsExecutionForm');
    const [localProjects, setLocalProjects] = useState(projects || []);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [daysInMonth, setDaysInMonth] = useState(31);
    const [startDay, setStartDay] = useState(0); // Sunday = 0, Monday = 1, etc.
    const daysOfWeek = [
        t('sunday'),
        t('monday'),
        t('tuesday'),
        t('wednesday'),
        t('thursday'),
        t('friday'),
        t('saturday')
    ];
    
    useEffect(() => {
        // Calculate the number of days in the selected month and year
        const getDaysInMonth = (year, monthIndex) => {
            return new Date(year, monthIndex + 1, 0).getDate();
        };

        const getStartDayOfMonth = (year, monthIndex) => {
            return new Date(year, monthIndex, 1).getDay(); // Returns the day index (0 = Sunday, 1 = Monday, etc.)
        };

        setDaysInMonth(getDaysInMonth(selectedYear, selectedMonthIndex));
        setStartDay(getStartDayOfMonth(selectedYear, selectedMonthIndex));
    }, [selectedMonthIndex, selectedYear]);

    const handleMonthChange = (monthIndex) => {
        setSelectedMonthIndex(monthIndex);
        setSelectedIndex(monthIndex); // Sync with the visual month selector
    };

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const handleSave = () => {
        dispatch({ type: 'SAVE_PROJECTS', payload: localProjects });
    };

    const addProjectRow = (event) => {
        event.preventDefault();
        setLocalProjects([
            ...localProjects,
            {
                name: '',
                resources: [''],
                cost: '',
                tasks: [{ text: '' }],
                days: [Array(daysInMonth).fill(0)],
                columnNames: localProjects[0]?.columnNames || [],
                additionalColumnValues: (localProjects[0]?.columnNames || []).map(() => ['']),
            },
        ]);
    };

    const addTaskLine = (event, index) => {
        event.preventDefault();
        const updatedProjects = localProjects.map((project, projectIndex) =>
            projectIndex === index
                ? {
                    ...project,
                    tasks: [...project.tasks, { text: '' }],
                    days: [...project.days, Array(daysInMonth).fill(0)],
                }
                : project
        );
        setLocalProjects(updatedProjects);
        dispatch({ type: 'UPDATE_PROJECTS', payload: updatedProjects });
    };

    const addResourceLine = (event, index) => {
        event.preventDefault();
        const updatedProjects = localProjects.map((project, projectIndex) =>
            projectIndex === index
                ? {
                    ...project,
                    resources: [...project.resources, ''],
                }
                : project
        );
        setLocalProjects(updatedProjects);
        dispatch({ type: 'UPDATE_PROJECTS', payload: updatedProjects });
    };

    const addNewColumn = () => {
        const updatedProjects = localProjects.map((project) => ({
            ...project,
            columnNames: [...project.columnNames, ''],
            additionalColumnValues: [...project.additionalColumnValues, ['']],
        }));
        setLocalProjects(updatedProjects);
        dispatch({ type: 'UPDATE_PROJECTS', payload: updatedProjects });
    };

    const handleColumnNameChange = (projectIndex, colIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedColumnNames = [...project.columnNames];
                updatedColumnNames[colIndex] = value;
                return { ...project, columnNames: updatedColumnNames };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const handleColumnValueChange = (projectIndex, colIndex, rowIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedColumnValues = project.additionalColumnValues.map(
                    (columnValues, colIdx) =>
                        colIdx === colIndex
                            ? columnValues.map((val, rowIdx) => (rowIdx === rowIndex ? value : val))
                            : columnValues
                );
                return { ...project, additionalColumnValues: updatedColumnValues };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const toggleTaskDay = (projectIndex, taskIndex, dayIndex) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedDays = project.days.map((taskDays, tIndex) =>
                    tIndex === taskIndex
                        ? taskDays.map((dayValue, dIndex) =>
                            dIndex === dayIndex ? (dayValue + 1) % 4 : dayValue
                        )
                        : taskDays
                );
                return { ...project, days: updatedDays };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const handleProjectNameChange = (projectIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                return { ...project, name: value };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const handleProjectCostChange = (projectIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                return { ...project, cost: value };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const handleResourceChange = (projectIndex, resourceIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedResources = project.resources.map((resource, resIdx) =>
                    resIdx === resourceIndex ? value : resource
                );
                return { ...project, resources: updatedResources };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const handleTaskTextChange = (projectIndex, taskIndex, value) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedTasks = project.tasks.map((task, tIndex) =>
                    tIndex === taskIndex ? { ...task, text: value } : task
                );
                return { ...project, tasks: updatedTasks };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
    };

    const downloadPDF = () => {
        const input = document.getElementById('pdf-content');
        const originalWidth = input.style.width;
        const originalHeight = input.style.height;
        const originalOverflow = input.style.overflow;

        // Temporarily change styles to ensure everything is visible
        input.style.width = 'auto';
        input.style.height = 'auto';
        input.style.overflow = 'visible';

        setTimeout(() => {
            html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const pageHeight = pdf.internal.pageSize.height;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                // Add new pages if necessary
                while (heightLeft > 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save('planing_vs_execution.pdf');

                // Restore original styles after PDF is generated
                input.style.width = originalWidth;
                input.style.height = originalHeight;
                input.style.overflow = originalOverflow;
            }).catch((error) => {
                console.error('Error generating PDF: ', error);
            });
        }, 500);
    };

    const handleFormReset = () => {
        const form = document.getElementById(formId);
        if (form) {
            form.reset();
        }
    };

    const addRowToColumn = (projectIndex, colIndex) => {
        const updatedProjects = localProjects.map((project, idx) => {
            if (idx === projectIndex) {
                const updatedColumnValues = project.additionalColumnValues.map(
                    (columnValues, colIdx) =>
                        colIdx === colIndex ? [...columnValues, ''] : columnValues
                );
                return { ...project, additionalColumnValues: updatedColumnValues };
            }
            return project;
        });
        setLocalProjects(updatedProjects);
        dispatch({ type: 'UPDATE_PROJECTS', payload: updatedProjects });
    };

    if (!localProjects || localProjects.length === 0) {
        return <div>{t('no_projects_available')}</div>;
    }

    return (
        <Fragment>
            <div className="video-container_p">
                <VideoNewModal videoUrl={t("video_url")} />
            </div>
            <div id="pdf-content">
                <div className="pdf-button-container21">
                    <button onClick={downloadPDF} className="pdf-button">
                        <img src={`${process.env.PUBLIC_URL}/Pictures/pdf_icon.png`} alt="PDF Icon" className="pdf-icon" />
                    </button>
                </div>

                {/* Month and Year Selectors */}
                <div className="month-year-selectors">
                    <MonthSelectVsExecution
                        text={t("months").split(",")}
                        resetForm={handleFormReset}
                        months={months}
                        setIndex={handleMonthChange} // Handle month change here
                        page="planingVsExecution"
                    />
                    <select onChange={handleYearChange} value={selectedYear}>
                        {Array.from({ length: 10 }, (_, i) => {
                            const year = new Date().getFullYear() - 5 + i;
                            return (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className="add-column-container">
                    <button onClick={addNewColumn} className="add-column-button">
                        {t('add_column')}
                    </button>
                </div>
                <div className="planing-vs-table-container" id="pdf-content">
                    <form id={formId}>
                        <table className="planing-vs-table">
                            <thead>
                                <tr>
                                    <th rowSpan="2">{t('project_name')}</th>
                                    {localProjects[0].columnNames && localProjects[0].columnNames.map((colName, colIndex) => (
                                        <th key={colIndex} rowSpan="2">
                                            <input
                                                type="text"
                                                placeholder={t('new_column')}
                                                value={colName}
                                                onChange={(e) => handleColumnNameChange(0, colIndex, e.target.value)}
                                                className="cell-input"
                                            />
                                        </th>
                                    ))}
                                    <th rowSpan="2">{t('needed_resources')}</th>
                                    <th rowSpan="2">{t('cost_of_project')}</th>
                                    <th rowSpan="2">{t('task')}</th>
                                    {Array.from({ length: daysInMonth }, (_, i) => (
                                        <th key={i}>{t(daysOfWeek[(i + startDay) % 7])}</th>
                                    ))}
                                </tr>
                                <tr>
                                    {Array.from({ length: daysInMonth }, (_, i) => (
                                        <th key={i}>{i + 1}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {localProjects.map((project, projectIndex) => (
                                    <Fragment key={projectIndex}>
                                        {project.tasks.map((task, taskIndex) => (
                                            <tr key={taskIndex}>
                                                {taskIndex === 0 ? (
                                                    <>
                                                        <td rowSpan={project.tasks.length + 1}>
                                                            <input
                                                                type="text"
                                                                placeholder={t('project_name')}
                                                                value={project.name}
                                                                onChange={(e) => handleProjectNameChange(projectIndex, e.target.value)}
                                                                className="cell-input"
                                                            />
                                                        </td>
                                                        {project.columnNames.map((colName, colIndex) => (
                                                            <td key={colIndex} rowSpan={project.tasks.length + 1}>
                                                                {project.additionalColumnValues[colIndex].map((value, rowIndex) => (
                                                                    <input
                                                                        key={rowIndex}
                                                                        type="text"
                                                                        placeholder={t('column_value')}
                                                                        value={value}
                                                                        onChange={(e) =>
                                                                            handleColumnValueChange(projectIndex, colIndex, rowIndex, e.target.value)
                                                                        }
                                                                        className="cell-input"
                                                                    />
                                                                ))}
                                                                <button
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        addRowToColumn(projectIndex, colIndex);
                                                                    }}
                                                                    className="add-line-button"
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> {t('add')}
                                                                </button>
                                                            </td>
                                                        ))}
                                                        <td rowSpan={project.tasks.length + 1}>
                                                            {project.resources.map((resource, resourceIndex) => (
                                                                <div key={resourceIndex}>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={t('needed_resources')}
                                                                        value={resource}
                                                                        onChange={(e) => handleResourceChange(projectIndex, resourceIndex, e.target.value)}
                                                                        className="cell-input"
                                                                    />
                                                                </div>
                                                            ))}
                                                            <div>
                                                                <button
                                                                    onClick={(e) => addResourceLine(e, projectIndex)}
                                                                    className="add-line-button"
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> {t('add')}
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td rowSpan={project.tasks.length + 1}>
                                                            <input
                                                                type="text"
                                                                placeholder={t('cost_of_project')}
                                                                value={project.cost}
                                                                onChange={(e) => handleProjectCostChange(projectIndex, e.target.value)}
                                                                className="cell-input"
                                                            />
                                                        </td>
                                                    </>
                                                ) : null}
                                                <td>
                                                    <textarea
                                                        rows="1"
                                                        placeholder={t('task')}
                                                        value={task.text}
                                                        onChange={(e) => handleTaskTextChange(projectIndex, taskIndex, e.target.value)}
                                                        className="cell-input"
                                                    />
                                                </td>
                                                {Array.from({ length: daysInMonth }).map((_, dayIndex) => (
                                                    <td key={dayIndex}>
                                                        <div
                                                            className={`clickable-box ${
                                                                project.days[taskIndex][dayIndex] === 1
                                                                    ? 'blue-background'
                                                                    : project.days[taskIndex][dayIndex] === 2
                                                                    ? 'green-background'
                                                                    : project.days[taskIndex][dayIndex] === 3
                                                                    ? 'red-background'
                                                                    : 'default-background'
                                                            }`}
                                                            onClick={() => toggleTaskDay(projectIndex, taskIndex, dayIndex)}
                                                        ></div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                        <tr>
                                            <td>
                                                <button onClick={(e) => addTaskLine(e, projectIndex)} className="add-line-button">
                                                    <FontAwesomeIcon icon={faPlus} /> {t('add')}
                                                </button>
                                            </td>
                                            {Array.from({ length: daysInMonth }).map((_, dayIndex) => (
                                                <td key={dayIndex}></td>
                                            ))}
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                            <tr>
                                <td colSpan={localProjects[0].columnNames.length}>
                                    <button
                                        onClick={addProjectRow}
                                        className="add-project-button"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} /> {t('add_project')}
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
            <div className="bottom-section">
                <div className="color-legend">
                    <h4>{t('Color Legend')}</h4>
                    <div className="legend-item">
                        <div className="color-box blue-box"></div>
                        <span>{t('Blue - Planned')}</span>
                    </div>
                    <div className="legend-item">
                        <div className="color-box green-box"></div>
                        <span>{t('Green - Completed')}</span>
                    </div>
                    <div className="legend-item">
                        <div className="color-box red-box"></div>
                        <span>{t('Red - Not Completed')}</span>
                    </div>
                </div>
                <div>
                    <button className="save-buttony" onClick={handleSave} style={{ textAlign: 'right' }}>
                        {t('Save')}
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

export default PlaningVsExecution;
