import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  loadLessonsForm,
  updateLessonsForm,
  setLessonsFormField,
} from "../../Reducers/learningLessonsReducer";
import "./CreateFormLessons.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Warning from "../../components/Warning/Warning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon component
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons"; // Import specific icons

export default function CreateFormLessons() {
  const { t } = useTranslation("LearningLessons");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();

  const currentForm = useSelector((state) => state.learningLessons.currentForm);

  useEffect(() => {
    if (formId) {
      dispatch(loadLessonsForm(formId));
    }
  }, [dispatch, formId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLessonsForm());
    navigate(`/learning-lessons`);
  };

  const downloadPDF = () => {
    const formContent = document.getElementById("form-content");
    const saveButton = document.getElementById("save-button");

    if (formContent) {
      if (saveButton) {
        saveButton.style.display = "none";
      }

      html2canvas(formContent, { scale: 2 })
        .then((canvas) => {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          pdf.save("Form-Lessons.pdf");
        })
        .finally(() => {
          if (saveButton) {
            saveButton.style.display = "block";
          }
        });
    } else {
      console.error("Form content not found");
    }
  };

  const resetChanges = () => {
    dispatch(loadLessonsForm(formId));
  };

  return (
    <div className="all_page">
      <div className="header15">
        <button className="back-circle" onClick={() => navigate("/learning-lessons")}>
          ←
        </button>
      </div>

      <div className="pdf-button-container">
        <button onClick={downloadPDF} className="pdf-button">
          <img src={`${process.env.PUBLIC_URL}/Pictures/pdf_icon.png`} alt="PDF Icon" className="pdf-icon" />
        </button>
      </div>

      <div className="create-form-left-aligned" id="form-content">
        <div className="form-title">
          <h2>{t("Learning lessons & corrective action form")}</h2>
        </div>

        <div className="form-group-inline">
          <label htmlFor="formName">{t("formNameColumn")}</label>
          <div className="form-group-inline-case">
          <input
            type="text"
            id="formName"
            value={currentForm.formName || ""}
            onChange={(e) => dispatch(setLessonsFormField("formName", e.target.value))}
            required
          />
<div className="empty"></div>
          <label htmlFor="creationDate">{t("creationDate")}</label>
          <input
            type="date"
            id="creationDate"
            value={currentForm.creationDate || ""}
            onChange={(e) => dispatch(setLessonsFormField("creationDate", e.target.value))}
            required
          />
        </div>
</div>
        <div className="form-group2">
          <label htmlFor="caseName">{t("caseName")}</label>
          <div className="form-group2-case">
          <input
            type="text"
            id="caseName"
            value={currentForm.caseName}
            onChange={(e) => dispatch(setLessonsFormField("caseName", e.target.value))}
            required
          />
          </div>
        </div> 

        <div className="form-group1">
          <label htmlFor="caseDescription">{t("caseDescription")}</label>
          <textarea
            id="caseDescription"
            value={currentForm.caseDescription}
            onChange={(e) => dispatch(setLessonsFormField("caseDescription", e.target.value))}
            required
          />
        </div>

        <div className="form-group3">
          <label>{t("questions")}</label>
          <div className="column_group3">
          {currentForm.questions.map((question, index) => (
            <div key={index} className="corrective-action">
              <input
                type="text"
                value={question.text}
                onChange={(e) => dispatch({ type: "SET_QUESTION_TEXT", payload: { index, text: e.target.value } })}
                required
              />
              <button onClick={() => dispatch({ type: "REMOVE_QUESTION", payload: index })} className="icon-button" type="button">
                <FontAwesomeIcon icon={faTrash} /> {/* Trash icon */}
              </button>
            </div>
          ))}
                        <button type="button" className="icon-button" onClick={() => dispatch({ type: "ADD_QUESTION" })}>
                <FontAwesomeIcon icon={faPlus} /> {/* Plus icon */}
              </button>
        </div>
        </div>

        <div className="form-group4">
  <label>{t("correctiveActions")}</label>
  <div className="column_group4">
  {currentForm.correctiveActions.map((action, index) => (
    <div key={index} className="corrective-action">
      <input
        type="text"
        value={action}
        onChange={(e) => dispatch({ type: "SET_CORRECTIVE_ACTION", payload: { index, text: e.target.value } })}
        required
      />
      <button onClick={() => dispatch({ type: "REMOVE_CORRECTIVE_ACTION", payload: index })} className="icon-button" type="button">
        <FontAwesomeIcon icon={faTrash} /> {/* Trash icon */}
      </button>
    </div>
  ))}
  <button type="button" className="icon-button" onClick={() => dispatch({ type: "ADD_CORRECTIVE_ACTION" })}>
    <FontAwesomeIcon icon={faPlus} /> {/* Plus icon */}
  </button>
</div>
</div>

      </div>

      <Warning text={t("warning")} />

      <div className="button-container">
        <button onClick={resetChanges} className="reset" type="button">
          {t("reset")}
        </button>
        <button onClick={handleSubmit} className="save_button_f" type="button">
          {t("saveButton")}
        </button>
      </div>
    </div>
  );
}
