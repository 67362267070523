import * as XLSX from 'xlsx'
import i18next from "i18next";

// import { months } from '../helpers/helpers';


// function for changeing the excel file col
// function transposeObjectData(data) {
//   const keys = Object.keys(data[0]);
//   const transposedData = keys.map(key => data.map(obj => obj[key]));
//   return transposedData;
// }
// export const generateBudgetData = (rows, turnover, actualExpenses, t) => {
//   return rows.map(({ id, text, initialPercentage }) => {
//     const budgetAmount = turnover * initialPercentage;
//     const actualExpense = actualExpenses[id];
//     return {
//       [t("issue_of_expenses_costs")]: text,
//       [t("budget_amount")]: budgetAmount,
//       [t("actual_expense")]: actualExpense,
//       [t("budget_status")]:
//         actualExpense > budgetAmount ? t("Over Budget") : t("Under Budget"),
//     };
//   });
// };


export function handleExport(data,name, dir){
    const currentLanguageDirection = i18next.dir();
    // console.log(currentLanguageDirection === "rtl")
    const workbook = XLSX.utils.book_new(); 
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet['!cols'] =[{ width: 30},{ width: 20},{ width: 20},{ width: 20}];
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    workbook.Workbook = {  Views: [
        {RTL: currentLanguageDirection === "rtl"}
      ]}
    // יצירת הקובץ
    XLSX.writeFile(workbook, `${name}.xlsx`);

}

// export function handleExportTotal(data,name){
//     const newData = data.map((obj)=>{
//       const {month,total_Income,inventory_and_supplier_expenses,salary_and_related_expenses,operational_and_managment,financial_expenses, total} = obj
//       return {month,total_Income,inventory_and_supplier_expenses,salary_and_related_expenses,operational_and_managment,financial_expenses, total}
//     })
//     const transposedData = transposeObjectData(newData)
//     const workbook = XLSX.utils.book_new(); 
//     const worksheet = XLSX.utils.json_to_sheet(newData);
//     worksheet['!cols'] = Array(50).fill({ width: 30});
//     XLSX.utils.book_append_sheet(workbook, worksheet, name);
//     // יצירת הקובץ
//     XLSX.writeFile(workbook, `${name}.xlsx`);

// }