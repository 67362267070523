import React, { Fragment } from 'react'
import './AddRow.css'
function AddRow(props) {
    const {text, addRow} = props
  return (
    <Fragment>
        <div className='add_line' onClick={(e)=> addRow(e)}>
            <div className='add_icon'></div>
            <div className='add_text'>{text}</div>
        </div>
    </Fragment>
  )
}

export default AddRow