// import axios from "axios";

//  export const BudgetPostReq = async (data) => {
//   try {
//     console.log(data)
//     const response = await axios({
//       method: "post",
//       url: "http://localhost:5000/api/budget",
//       data:data
//     });
//     console.log(response)
//   } catch (error) {
//     console.log(error)
//   }
// };





import axios from "axios";

// POST request for submitting budget data
export const BudgetPostReq = async (data) => {
  try {
    const response = await axios.post("http://localhost:5000/api/budget", data);
    console.log(response);
  } catch (error) {
    console.error("POST Error:", error);
  }
};


// GET request to retrieve budget data
export const BudgetGetReq = async () => {
  try {
    const response = await axios({
      method: "get",
      url: "http://localhost:5000/api/budget"
    });
    console.log("GET Response:", response.data);
    return response.data;
  } catch (error) {
    console.log("GET Error:", error);
  }
};

// PUT request to update budget data
export const BudgetPutReq = async (id, data) => {
  try {
    const response = await axios({
      method: "put",
      url: `http://localhost:5000/api/budget/${id}`,
      data: data
    });
    console.log("PUT Response:", response);
  } catch (error) {
    console.log("PUT Error:", error);
  }
};

// DELETE request to remove budget data
export const BudgetDeleteReq = async (id) => {
  try {
    const response = await axios({
      method: "delete",
      url: `http://localhost:5000/api/budget/${id}`
    });
    console.log("DELETE Response:", response);
  } catch (error) {
    console.log("DELETE Error:", error);
  }
};

