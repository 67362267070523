import axios from 'axios';

// GET Request - fetch data from the server
export const firstGetRequest = async () => {
  try {
    const response = await axios.get('http://localhost:5000/api/finance');
    console.log(response.data);
    return response.data; // Return the data to use elsewhere
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// POST Request - send data to the server
export const postData = async (newData) => {
  try {
    const response = await axios.post('http://localhost:5000/api/finance', newData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Data posted:', response.data);
    return response.data; // Return the data to use elsewhere
  } catch (error) {
    console.error('Error posting data:', error);
  }
};

// PUT Request - update data on the server
export const updateData = async (id, updatedData) => {
  try {
    const response = await axios.put(`http://localhost:5000/api/finance/${id}`, updatedData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Data updated:', response.data);
    return response.data; // Return the data to use elsewhere
  } catch (error) {
    console.error('Error updating data:', error);
  }
};

// DELETE Request - delete data from the server
export const deleteData = async (id) => {
  try {
    const response = await axios.delete(`http://localhost:5000/api/finance/${id}`);
    console.log('Data deleted:', response.data);
    return response.data; // Return the data to use elsewhere
  } catch (error) {
    console.error('Error deleting data:', error);
  }
};

// POST Request for Resetting Data
export const resetData = async (month) => {
  try {
    const response = await axios.post('http://localhost:5000/api/finance', { month }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Data reset:', response.data);
    return response.data; // Return the data to use elsewhere
  } catch (error) {
    console.error('Error resetting data:', error);
    throw error;
  }
};
