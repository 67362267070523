import "./ExcellButton.css"
// import {handleExport, handleExportFistPage} from "../../../Excel/generateBudgetData"
import ExcelDesgin from "./ExcelDesgin";
function ExcelButton(props) {
  const {name , data, excel ,dir} =props;
  const excelfunc=(e)=>{
    e.preventDefault();
    excel(data, name, dir);
  }
  return (
    <div  className="xl_div">
      <button type='button' className="xl-button_1" onClick={(e)=> {excelfunc(e)}}/>
        <div className="fillup"></div>
     </div>
  )
}

export default ExcelButton
