import React from 'react'
import "./Artical.css"
import { useTranslation } from 'react-i18next';
// import ReactPlayer from "react-player";
import VideoNewModal from '../Modals/VideoNewModal/VideoNewModal';
// import { text } from '@fortawesome/fontawesome-svg-core';
function SalesCoaching() {
  const { t } = useTranslation('SalesTraining');
  return (
    <div className='paragraph'>
      <VideoNewModal videoUrl={t("video_url1")}/>
      <div className='textArea'>
        <div className='keys_container'>
          <h3 className='modal_h_sales'>{t('first_h')}</h3>
          {/* {t('text_1')} */}
          {/* <bold>{t('first_modal_h')}</bold> */}
             <p className='text_salse'>{t('text_1')}</p>

          <bold className='modal_h_sales' >{t('first_modal_h')}</bold>
             <p className='text_salse'>{t('first_modal_text_1')}</p>
             <p className='text_salse'>{t('first_modal_text_2')}</p>
             <p className='text_salse'>{t('first_modal_text_3')}</p>
          
          <bold  className='modal_h_sales'>{t('second_modal_h')}</bold>
             <p className='text_salse'>{t('second_modal_text_1')}</p>
             <p className='text_salse'>{t('second_modal_text_2')}</p>
             <p className='text_salse'>{t('second_modal_text_3')}</p>
             <p className='text_salse'>{t('second_modal_text_4')}</p>

          <bold  className='modal_h_sales'>{t('third_modal_h')}</bold>
             <p className='text_salse'>{t('third_modal_text_1')}</p>
             <p className='text_salse'>{t('third_modal_text_2')}</p>
             <p className='text_salse'>{t('third_modal_text_3')}</p>
             <p className='text_salse'>{t('third_modal_text_4')}</p>
             <p className='text_salse'>{t('third_modal_text_5')}</p>

          <bold  className='modal_h_sales'>{t('fourth_modal_h')}</bold>
             <p className='text_salse'>{t('fourth_modal_text_1')}</p>
             <p className='text_salse'>{t('fourth_modal_text_2')}</p>
             <p className='text_salse'>{t('fourth_modal_text_3')}</p>
             <p className='text_salse'>{t('fourth_modal_text_4')}</p>
             <p className='text_salse'>{t('fourth_modal_text_5')}</p>
        </div>
      </div>
</div>
  )
}

export default SalesCoaching;