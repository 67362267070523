import axios from "axios";

// POST request to submit debts data
export const DebtsPostReq = async (data) => {
  try {
    const response = await axios.post("http://localhost:5000/api/debts", data);
    console.log("POST Response:", response);
  } catch (error) {
    console.error("POST Error:", error);
  }
};

// GET request to retrieve debts data
export const DebtsGetReq = async () => {
  try {
    const response = await axios.get("http://localhost:5000/api/debts");
    console.log("GET Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Error:", error);
  }
};

// PUT request to update debts data
export const DebtsPutReq = async (id, data) => {
  try {
    const response = await axios.put(`http://localhost:5000/api/debts/${id}`, data);
    console.log("PUT Response:", response);
  } catch (error) {
    console.error("PUT Error:", error);
  }
};

// DELETE request to remove debts data
export const DebtsDeleteReq = async (id) => {
  try {
    const response = await axios.delete(`http://localhost:5000/api/debts/${id}`);
    console.log("DELETE Response:", response);
  } catch (error) {
    console.error("DELETE Error:", error);
  }
};
