import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import "./CreatePage1.css";

function CreatePage1() {
  const { t } = useTranslation("Experience");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    startDate,
    lastUpdate,
    questions,
    googleFormLink,
    image,
    nameOfProcedure,
    numberOfProcedure,
    numberOfPages,
    preparedBy,
    authorizedToMakeChanges,
    approval,
    forms,
  } = useSelector((state) => state.customer.currentForm);

  const addQuestion = () => {
    dispatch({ type: "ADD_QUESTION" });
  };

  const removeQuestion = (id) => {
    dispatch({ type: "REMOVE_QUESTION", payload: id });
  };

  const handleQuestionChange = (id, newText) => {
    dispatch({
      type: "SET_QUESTION_TEXT",
      payload: { id, text: newText },
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch({ type: "SET_IMAGE", payload: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    dispatch({ type: "SAVE_CURRENT_FORM" });
  };

  const next = () => {
    navigate(`/create2/${id}`);
  };

  const createGoogleForm = async (questions) => {
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbyQePrIEgsj2jLbQHdW8lrG7ME8eZlFMcOCvvNplLhh1dClZBvXItEHXcJOsoieSzx-xQ/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questions: questions
        }),
      });

      const result = await response.json();

      if (result.formLink) {
        console.log('Google Form Link:', result.formLink);
        alert('Google Form Created: ' + result.formLink);
      } else {
        console.error('Error creating form:', result.error);
      }
    } catch (error) {
      console.error('Error during form creation:', error);
    }
  };


  return (
    <div className="all15">
      <div className="page111">
        <div className="header15">
          <button className="back-button2" onClick={() => navigate("/customer-experience")}>
            ← {t("Back_to")}
          </button>
        </div>

        {/* Progress Bar */}
        <div className="progress-bar">
          <div className="create-step completed" onClick={() => navigate(`/create1/${id}`)}>1</div>
          <div className="create-step" onClick={() => navigate(`/create2/${id}`)}>2</div>
          <div className="create-step" onClick={() => navigate(`/create3/${id}`)}>3</div>
        </div>
      </div>

      {/*add space because in css it dosent work */}
      <br></br>

      <div className="form-container">
        {/* <div className="upload-section">
          <div
            className="logo-placeholder"
            onClick={() => document.getElementById("file-upload").click()}
          >
            {!image && <span>{t("Upload Photo")}</span>}
            <FontAwesomeIcon icon={faPen} className="edit-icon" />
            <input
              id="file-upload"
              type="file"
              onChange={handleImageUpload}
              className="file-input"
              accept="image/*"
            />
            {image && <img src={image} alt="Uploaded" className="uploaded-image" />}
          </div>
        </div> */}

        <div className="form-section">
          <div className="input-row">
            <div className="input-column">
              <label>{t("Name of Procedure")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={nameOfProcedure}
                onChange={(e) =>
                  dispatch({ type: "SET_NAME_OF_PROCEDURE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Number of Procedure")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={numberOfProcedure}
                onChange={(e) =>
                  dispatch({ type: "SET_NUMBER_OF_PROCEDURE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Number of pages")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={numberOfPages}
                onChange={(e) =>
                  dispatch({ type: "SET_NUMBER_OF_PAGES", payload: e.target.value })
                }
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-column">
              <label>{t("Date created")}</label>
              <input
                type="date"
                className="input-field"
                value={startDate}
                onChange={(e) =>
                  dispatch({ type: "SET_START_DATE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Last update")}</label>
              <input
                type="date"
                className="input-field"
                value={lastUpdate}
                onChange={(e) =>
                  dispatch({ type: "SET_LAST_UPDATE", payload: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="form-section">
      {questions.map((question) => (
        <div key={question.id} className="question-row">
          <input
            type="text"
            value={question.text || t(question.key)} // Utilise la traduction si le texte est vide
            onChange={(e) => handleQuestionChange(question.id, e.target.value)}
            className="input-field"
            placeholder={t(question.key)} // Utilise la clé pour le placeholder
          />
          <button
            onClick={() => removeQuestion(question.id)}
            className="icon-button"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}
      <button onClick={addQuestion} className="add-question-button">
        <FontAwesomeIcon icon={faPlus} /> {t("Add Question")}
      </button>

            {/* Button to create Google Form */}
      {/* <button onClick={createGoogleForm} className="add-question-button">
        {t("Create Google Form")}
      </button> */}

            {/* Link to create Google Form */}
            <button onClick={() => window.open('https://docs.google.com/forms/u/0/', '_blank')} className="add-question-button">
        {t("Create Google Form")}
      </button>

      {/* Display the Google Form link once created */}
      {googleFormLink && (
        <div className="google-form-link">
          <a href={googleFormLink} target="_blank" rel="noopener noreferrer">
            {t("View Google Form")}
          </a>
        </div>
      )}
    </div>

        <div className="form-section">
          <div className="input-row">
            <div className="input-column">
              <label>{t("Prepared by")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={preparedBy}
                onChange={(e) =>
                  dispatch({ type: "SET_PREPARED_BY", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Authorized to make changes")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={authorizedToMakeChanges}
                onChange={(e) =>
                  dispatch({
                    type: "SET_AUTHORIZED_TO_MAKE_CHANGES",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Approval")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={approval}
                onChange={(e) =>
                  dispatch({ type: "SET_APPROVAL", payload: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <button className="top_button_create" onClick={handleSave}>{t("Save")}</button>

      <div className="page">
        <button className="top_button_next" onClick={next}>
          {t("Next")}
        </button>
      </div>
    </div>
  );
}

export default CreatePage1;