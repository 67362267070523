import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import "./MonthTableRow.css";
import { months } from "../../../helpers/helpers";
import { formatNumber } from "../../../helpers/helpers";
function MonthTableTotal({ id, text }) {
  const thisMonth = useSelector((state) => state.finance);
  const totalfMonth = months.reduce((base, mon) => {
    return (base += +thisMonth[mon][id]);
  }, 0);
  const activeMonths = months.reduce((base, mon) => {
    if(thisMonth[mon].active === true){
         return base + 1;}
    else{
        return base
    }
  }, 0);
//   console.log(activeMonth)
  return (
    <Fragment>
      <div className="input-table-c " >

     <p className="the_info">{text}</p>
      </div>
      {months.map((mon, i) => (
        <div
          key={i}
          className="input-table-f"
          dir='ltr'
          // {formatNumber(thisMonth[mon][id])}/
          // disabled
        > <p className={`the_info ${thisMonth[mon][id] > 0? "green": ""} ${thisMonth[mon][id] < 0? "red": ""}`}>{formatNumber(thisMonth[mon][id])}</p></div>
      ))}

      <div
        className="input-table-f"
        // defaultValue=
        dir='ltr'
        disabled
      ><p className={`the_info ${totalfMonth / 12 > 0? "green": ""} ${totalfMonth / 12 < 0? "red": ""}`}>
        {activeMonths > 0? formatNumber(totalfMonth / activeMonths) : 0}
        </p></div>
      <div
        className="input-table-f "
        // defaultValue={formatNumber(totalfMonth)}
        dir='ltr'
        disabled
      ><p className={`the_info ${totalfMonth > 0? "green": ""} ${totalfMonth < 0? "red": ""}`}>{formatNumber(totalfMonth)}</p></div>
    </Fragment>
  );
}

export default MonthTableTotal;
