import React, { Fragment } from "react";
import "./MonthSelectIncome.css";
// import { months } from '../../helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
function MonthSelectIncome(props) {
  const { text, setIndex, resetForm, months } = props;
  const dispatch = useDispatch();
  const divs = document.querySelectorAll(".select_month");
  const theMonth = useSelector((state) => state.income);
  // const month_number = ["01","02","03","04","05","06","07","08","09","10","11","12"]

  return (
    <Fragment>
      <div className="container_grid_13_f text_center ">
        {months.map((mon, i) => (
          <Fragment key={i}>
            <div
              className="select_month"
              id={mon}
              onClick={(e) => {
                if (!(e.target.id === "Total" || e.target.id === "Average")) {
                  // document.getElementById("incomeForm2").reset();
                  document.getElementById(resetForm).reset();
                  divs.forEach((div) => {
                    div.classList.remove("picked_f");
                  });
                  dispatch({ type: "month", payload: mon });
                  setIndex(i);
                  dispatch({ type: "total_income", payload: theMonth[mon].total_income });
                  dispatch({ type: "total_expense", payload: theMonth[mon].total_expense });
                  dispatch({ type: "current_account_status_base", payload: theMonth[mon].current_account_status_base });
                  dispatch({ type: "account_frame", payload: theMonth[mon].account_frame });
                  // if(theMonth[mon].active === false){
                  //   dispatch({
                  //     type: "month_action",
                  //     payload: [...theMonth.Monthly_flow.Month_action],
                  //   });
                  // }
                  // else{
                    dispatch({
                      type: "month_action",
                      payload: [...theMonth[mon].Month_action],
                    });
                  // }

                  e.currentTarget.classList.add("picked_f");
                }
              }}
            >
              {text[i]}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default MonthSelectIncome;
