import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ExamplePage.css"; // Ensure this file contains the button styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"; // Import the arrow icon

function ExamplePage2() {
  const { t } = useTranslation("DefineRules");
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="page">
      {/* Header */}
      <div className="header12">
        <button className="back-button2" onClick={() => navigate(-1)}>
          ← {t("Back_to")}
        </button>
      </div>

      {/* Image at the top */}
      <div className="top-image">
        <img src={`${process.env.PUBLIC_URL}/Pictures/six_keys_logo.png`} alt="Top Image" className="top-image-file" />
      </div>

      {/* Form with fields */}
      <div className="form-section">
        <div className="input-row">
          <div className="input-column">
            <label>{t("procedure_name")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_procedure_name")} disabled />
          </div>
          <div className="input-column">
            <label>{t("document_number")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_document_number")} disabled />
          </div>
          <div className="input-column">
            <label>{t("page_number")}</label>
            <input type="text" className="input-field1" placeholder={t("enter_page_number")} disabled />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <label>{t("date_created")}</label>
            <input type="text" className="input-field1" placeholder="09/11/2004" disabled />
          </div>
          <div className="input-column">
            <label>{t("last_update")}</label>
            <input type="text" className="input-field1" placeholder="20/09/2024" disabled />
          </div>
          <div className="input-column">
            <label>{t("update_number")}</label>
            <input type="text" className="input-field1" placeholder="1" disabled />
          </div>
        </div>
      </div>

      {/* Procedure text from the image, each in a bordered box */}
      <div className="steps-section">
        <div className="text-field">
          <p>{t("Purpose: Aligning all employees and managers regarding vacation laws.")}</p>
        </div>
        <div className="text-field">
          <p>{t("Each employee is entitled to a certain number of annual vacation days, as outlined by agreement and law.")}</p>
        </div>
        <div className="text-field">
          <p>{t("Utilizing all annual vacation days is mandatory for every employee and manager.")}</p>
        </div>
        <div className="text-field">
          <p>{t("Accumulation of vacation days will be allowed only with the approval of the executive and upon submission of a vacation accumulation request form - Appendix A.")}</p>
        </div>
        <div className="text-field">
          <p>{t("Vacation leave will only be granted according to the following steps:")}</p>
          <ul>
            <li>{t("Completing a vacation request form at least two weeks prior to the desired date – Appendix B.")}</li>
            <li>{t("Submitting the form in an orderly manner for approval by the supervising manager through the reception manager.")}</li>
            <li>{t("Receiving a signed approval from the supervising manager.")}</li>
          </ul>
        </div>
        <div className="text-field">
          <p>{t("An employee or manager who has used all their vacation days and wishes to take additional leave may submit a written request.")}</p>
        </div>
        <div className="text-field">
          <p>{t("Any deviation from this procedure may only be made with the approval of the executive.")}</p>
        </div>
        <div className="text-field">
            <label>{t("Responsible_Person")}: {t("Reception Manager")} </label>
          </div>
      </div>

      <div className="footer-section">
        <div className="input-row">
          <div className="input-column">
            <label>{t("prepared_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_prepared_by")} disabled />
          </div>
          <div className="input-column">
            <label>{t("reviewed_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_reviewed_by")} disabled />
          </div>
          <div className="input-column">
            <label>{t("approved_by")}</label>
            <input type="text" className="input-field1" placeholder={t("placeholder_approved_by")} disabled />
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      <button className="back-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faChevronUp} />
        <span>{t("Back to Top")}</span>
      </button>
    </div>
  );
}

export default ExamplePage2;
