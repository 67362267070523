import React, { Fragment } from 'react'
import './MonthSelect.css'
// import { months } from '../../helpers/helpers';
import { useDispatch,useSelector } from 'react-redux';

function FinancePlanTable (props) {
  const {text, setIndex, resetForm, months, setInventoryTotal, setSupplierTotal } = props;
  const dispatch = useDispatch();
  const divs = document.querySelectorAll('.select_month');
  const theMonth = useSelector(state => state.finance);



  return (
    <Fragment>
      <div className='container_grid_13_f text_center '>
        {months.map((mon,i ) => (
          <Fragment key={i}>
          <div className='select_month' id={mon} onClick={(e) => {
            if(!(e.target.id === "Total" || e.target.id === "Average")){
            document.getElementById(resetForm).reset()
            setInventoryTotal(false)
            setSupplierTotal(false)
            divs.forEach(div => {
              div.classList.remove('picked_f');
            });
            dispatch({type:"month",payload:mon })
            setIndex(i)
            if(!(theMonth[mon].active)){
            dispatch({type:mon ,payload: {...theMonth.Default,month:mon}})
            dispatch({type:"inventory_detail", payload: [...theMonth.Default.inventory_detail]})
            dispatch({type:"suplier_detail", payload: [...theMonth.Default.suplier_detail]})
          }
          dispatch({type:"inventory_detail", payload: [...theMonth[mon].inventory_detail]})
          dispatch({type:"suplier_detail", payload: [...theMonth[mon].suplier_detail]})
            e.currentTarget.classList.add('picked_f')}
            }}>{text[i]}</div>
          </Fragment>
          ))}
      </div>
    </Fragment>
  )
}

export default FinancePlanTable
