import React, { useState } from "react";
import "./FunctionalInput.css";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDispatch , useSelector} from "react-redux";


function FunctionalInput({ text, state , month, page, Month_action}) {
  const { t } = useTranslation("Incom");
  const [smallButton, setSmallButton] = useState(true);
  const current_month = useSelector(state => state.income[month]);
  const dispatch = useDispatch();
  const updateAbled = (e) => {
    e.preventDefault();
    setSmallButton(!smallButton);
    return;
  };
  const handleInputChange = (e)=>{
    const {name , value} = e.target;
    dispatch({type:state,payload:e.target.value});
    dispatch({ type: month + page, payload: {...current_month, [name]: value} });
    return;

}


  return (
    <div className="functional-input-container">
      <h3 className="trunover_titel">{t(`${text}`)}</h3>
      <div  className={`functional-div `}>

      <input
      id={state}
      className={`functional_input `}
      // smallButton ? "functional-input-disabled" : "functional-input-abled"
        onChange={e =>handleInputChange(e)}
        name={state}
        type="number"
        dir="ltr"
        // disabled={smallButton}
        placeholder={0}
        defaultValue={current_month? current_month[state] : ""}
        />
        </div>
      {/* <button type="button" className="disabled-button" onClick={updateAbled}>
        <FontAwesomeIcon icon={faPencil} />
      </button> */}
    </div>
  );
}

export default FunctionalInput;
