import React, { Fragment } from 'react'
import "./Note.css"
function Note(props) {
    const {text} = props
  return (
    <Fragment>
        <div className='note_line'>
        <div className='note_icon'></div>
        <div  className="note_text">
        {text}
        </div>
        </div>
    </Fragment>
  )
}

export default Note