import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './MakingDecisions.css';
import { addNewTable, addRow, updateInput, updateTopic } from '../../Reducers/decisionsReducer';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import Warning from "../../components/Warning/Warning";

function MakingDecisions() {
  const { t } = useTranslation('Decisions');
  const dispatch = useDispatch();
  
  // Get data from Redux store
  const allTablesRedux = useSelector(state => state.decisions.allTables);
  const topicsRedux = useSelector(state => state.decisions.topics);
  
  // Local state to hold changes
  const [localTables, setLocalTables] = useState([]);
  const [localTopics, setLocalTopics] = useState([]);

  // Initialize local state with data from Redux store
  useEffect(() => {
    setLocalTables(allTablesRedux);
    setLocalTopics(topicsRedux);
  }, [allTablesRedux, topicsRedux]);

  const handleInputChange = (tableIndex, rowIndex, field, value) => {
    const updatedTables = localTables.map((table, i) =>
      i === tableIndex
        ? table.map((row, j) =>
            j === rowIndex ? { ...row, [field]: value } : row
          )
        : table
    );
    setLocalTables(updatedTables);
  };

  const handleTopicChange = (index, value) => {
    const updatedTopics = localTopics.map((topic, i) =>
      i === index ? value : topic
    );
    setLocalTopics(updatedTopics);
  };

  const handleAddRow = (tableIndex) => {
    const updatedTables = localTables.map((table, i) =>
      i === tableIndex ? [...table, { advantages: '', disadvantages: '' }] : table
    );
    setLocalTables(updatedTables);
  };

  const handleAddNewTable = () => {
    setLocalTables([...localTables, [{ advantages: '', disadvantages: '' }]]);
    setLocalTopics([...localTopics, '']);
  };

  const calculateMessage = (tableData) => {
    const advantagesCount = tableData.filter(row => row.advantages.trim() !== '').length;
    const disadvantagesCount = tableData.filter(row => row.disadvantages.trim() !== '').length;

    if (advantagesCount > disadvantagesCount) {
      return t('more_advantages');
    } else if (advantagesCount < disadvantagesCount) {
      return t('more_disadvantages');
    } else {
      return t('equal_advantages_disadvantages');
    }
  };

  // Download PDF function (same as before)
  const downloadPDF = () => {
    // ... (same as your original implementation)
  };

  const resetChanges = () => {
    setLocalTables(allTablesRedux);
    setLocalTopics(topicsRedux);
  };

  const saveChanges = () => {
    // Si on veut enregistrer une nouvelle table ajoutée
    localTables.forEach((table, tableIndex) => {
      // Si la table n'existe pas dans Redux, on l'ajoute
      if (tableIndex >= allTablesRedux.length) {
        dispatch(addNewTable());
      }
  
      // On met à jour les valeurs de chaque ligne
      table.forEach((row, rowIndex) => {
        dispatch(updateInput(tableIndex, rowIndex, 'advantages', row.advantages));
        dispatch(updateInput(tableIndex, rowIndex, 'disadvantages', row.disadvantages));
      });
    });
  
    // Mise à jour des topics
    localTopics.forEach((topic, index) => {
      dispatch(updateTopic(index, topic));
    });
  
    alert(t("save_success"));
  };
  
  
  return (
    <Fragment>
      <div className='intro_decision'>
        <h2>{t("decision_making")}</h2>
      </div>

      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>

      {/* PDF Download Section */}
      <div id="pdf-content">
        <div className="pdf-button-container">
          <button onClick={downloadPDF} className="pdf-button">
            <img src={`${process.env.PUBLIC_URL}/Pictures/pdf_icon.png`} alt="PDF Icon" className="pdf-icon" />
          </button>
        </div>
      </div>

      <div id="pdf-content">
        <div className="making-decisions-container">
          {localTables.map((tableData, tableIndex) => (
            <div key={tableIndex} className="table-section">
              <table className="making-decisions-table" border="1">
                <thead>
                  <tr>
                    <td colSpan="2">
                      <input
                        type="text"
                        value={localTopics[tableIndex]}
                        onChange={(e) => handleTopicChange(tableIndex, e.target.value)}
                        placeholder={t('topic')}
                        className="input-cell"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('advantages')}</th>
                    <th>{t('disadvantages')}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        <input
                          type="text"
                          value={row.advantages}
                          onChange={(e) => handleInputChange(tableIndex, rowIndex, 'advantages', e.target.value)}
                          placeholder={t('advantages')}
                          className="input-cell"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={row.disadvantages}
                          onChange={(e) => handleInputChange(tableIndex, rowIndex, 'disadvantages', e.target.value)}
                          placeholder={t('disadvantages')}
                          className="input-cell"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <p className="comparison-message">
                {calculateMessage(tableData)}
              </p>

              <button onClick={() => handleAddRow(tableIndex)} className="button-table">
                {t('add_row')}
              </button>
            </div>
          ))}

          <button onClick={handleAddNewTable} className="button-add-table">
            {t('add_new_table')}
          </button>
        </div>

        <Warning text={t("warning")} />

        <div className="button-container">
          <button onClick={resetChanges} className="reset">{t("reset")}</button>
          <button onClick={saveChanges} className="save_button_f">{t("save")}</button>
        </div>
      </div>
    </Fragment>
  );
}

export default MakingDecisions;
