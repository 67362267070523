import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Income.css";
import { months, formatNumber } from "../../helpers/helpers.js";
// import NineTableRow from "../../components/Tables/NineTableRow/NineTableRow.js";
// import MonthSelect from "../../components/Selectors/MonthSelect/MonthSelect.js";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal.js";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFlag, faShekelSign } from "@fortawesome/free-solid-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import FunctionalInput from "../../components/Inputs/FunctionalInput/FunctionalInput.js";
// import {handleExport} from "../../Excel/incomePageFunction.js"
import { handleExport } from '../../Excel/incomePageFunction.js'
// import { handleExport } from '../../Excel/generateBudgetData.js'
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton.js";
// import TextToSpeech from "../../components/TextToSpeech/TextToSpeech";
import IncomeTableInput from "../../components/Tables/IncomeTableInput/IncomeTableInput.js";
import MonthSelectIncome from "../../components/Selectors/MonthSelectIncome/MonthSelectIncome.js";
import AddRow from "../../components/AddRow/AddRow.js";
import Warning from "../../components/Warning/Warning.js";
import IncomeTableMonthlyFlow from "../../components/Tables/IncomeTableInput/IncomeTableMonthlyFlow.js"
import { IncomePostReq } from "./IncomeReq.js";

// import { IncomePostReq } from "./IncomeReq.js";

function Income() {
  // IncomePostReq()
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = 'Are you sure you want to leave? Please save data !';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);  


  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);  

  //   };
  // }, []);
  const dispatch = useDispatch();
  const { t } = useTranslation("Incom");
  const month = useSelector((state) => state.income.month);

  const month_list = t("month_text").split(",");

  // const payments_expenses = useSelector((state) => state.income.payments_expenses);
  const current_account_status_base = useSelector((state) => state.income.current_account_status_base);
  const account_frame = useSelector((state) => state.income.account_frame);
  // const current_account_status = useSelector((state) => state.income.current_account_status);
  // const January = useSelector((state) => state.income.January);
  // const February = useSelector((state) => state.income.February);
  // const March = useSelector((state) => state.income.March);
  // const May = useSelector((state) => state.income.May);
  // const April = useSelector((state) => state.income.April);
  // const June = useSelector((state) => state.income.June);
  // const July = useSelector((state) => state.income.July);
  // const August = useSelector((state) => state.income.August);
  // const September = useSelector((state) => state.income.September);
  // const October = useSelector((state) => state.income.October);
  // const November = useSelector((state) => state.income.November);
  // const December = useSelector((state) => state.income.December);
  const current_month = useSelector((state) => state.income[month]);
  const total_expense = useSelector((state) => state.income.total_expense);
  const total_expense_monthly_flow = useSelector((state) => state.income.total_expense_monthly_flow);
  const total_income = useSelector((state) => state.income.total_income);
  const total_income_monthly_flow = useSelector((state) => state.income.total_income_monthly_flow);
  const month_traker = useSelector((state) => state.income.month_traker);
  const monthly_flow = useSelector((state) => state.income.Monthly_flow);
  const Month_action_monthly_flow = useSelector((state) => state.income.Month_action_monthly_flow);
  const Month_action = useSelector((state) => state.income.Month_action);



  const currentYear = new Date().getFullYear();
  const [redFlags, setRedFlags] = useState([])
  function isSortedByDate(dates) {
    for (let i = 1; i < dates.length; i++) {
      if (dates[i].date < dates[i - 1].date) {
        return false; // Found a pair of dates out of order
      }
    }
    return true; // All dates are in ascending order
  }
  // function isSortedByDate(dates) {
  //   return dates.reduce((prev, current) => {
  //     if (current.date < prev) {
  //       return false; // Found a pair of dates out of order
  //     }
  //     return current;
  //   }, new Date(-Infinity));
  // }
  // const [addDate, setAddDate] = useState("0000-00-00");
  // const getDate = (array) => {
  //   let theDate = "0000-00-00"
  //   for (let i = 0; i < array.length; i++) {
  //     if (Number(array[i].payments_expenses) > 0 && array[i].date > theDate) {
  //       theDate = array[i].date
  //     }
  //   }
  //   return theDate
  // }
  const fix_month = () => {
    const new_array = Array.from(Month_action_monthly_flow);
    const fix = new_array.map((action) => {
      return { ...action, date: `${currentYear}-${current_month?.monthDate}-${action.date.slice(8)}` }
    })
    dispatch({
      type: "dispatch_monthly_flow", payload: {
        month_action: [...fix],
        total_expense: total_expense_monthly_flow,
        total_income: total_income_monthly_flow
      }
    });
    document.getElementById("incomeForm").reset();
  }

  useEffect(() => {
    // const data = getDate(Month_action)

    // setAddDate(data);
    const getflags = () => {
      const flagList = []
      Month_action.map((action, i) => {
        if (action.payments_expenses > 0) {
          const current = Month_action.slice(0, 1 + i).reduce((acc, action) => {
            return +acc + Number(action.income_revenue) - Number(action.payments_expenses)
          }, 0)
          if (+current_account_status_base + +account_frame + current < 0) flagList.push(action.date)
          }
        return setRedFlags(flagList)
      })
    }
    if (isSortedByDate(Month_action) && !(month === "select month" || month === "Monthly_flow")) getflags()
    // setActionList(Month_action)
    else {
      setRedFlags([])
    }
  }, [Month_action, month, account_frame, current_account_status_base]);

  const saveMonthFlow = async (e) => {
    e.preventDefault();

    // Prepare the data to be submitted
    const data = {
      ...current_month,
      total_expense_monthly_flow,
      total_income_monthly_flow,
      Month_action_monthly_flow,
      active: true
    };

    // POST Request
    try {
      await IncomePostReq(data);
      console.log("POST request successful for monthly flow");
    } catch (error) {
      console.error("POST request failed:", error);
    }

    dispatch({
      type: month + "_income",
      payload: data
    });
    dispatch({ type: "month_action", payload: [month_traker] });

    document.getElementById("incomeForm").reset();
  };
  const saveMonth = async (e) => {
    e.preventDefault();

    // Prepare the data to be submitted
    const data = {
      ...current_month,
      total_expense,
      total_income,
      Month_action,
      active: true
    };

    // POST Request
    try {
      await IncomePostReq(data);
      console.log("POST request successful for month");
    } catch (error) {
      console.error("POST request failed:", error);
    }

    dispatch({
      type: month + "_income",
      payload: data
    });
    dispatch({ type: "month_action", payload: [month_traker] });
    dispatch({ type: "total_income", payload: 0 });
    dispatch({ type: "total_expense", payload: 0 });
    dispatch({ type: "current_account_status_base", payload: 0 });
    dispatch({ type: "account_frame", payload: 0 });
    dispatch({ type: "month", payload: "select month" });

    const divs = document.querySelectorAll(".select_month");
    divs.forEach((div) => {
      div.classList.remove("picked_f");
    });

    document.getElementById("incomeForm2").reset();
  };

  // const changeTotalExpenseValue = (e) => {
  //   e.preventDefault()
  //   dispatch({ type: "total_expense", payload: e.target.value })
  //   dispatch({ type: month + "_income", payload: {...current_month, [e.target.name]:e.target.value}})
  //   return;
  // };

  // const changeTotalIncomeValue = (e) => {
  //   e.preventDefault()
  //   dispatch({ type: "total_income", payload: e.target.value })
  //   dispatch({ type: month + "_income", payload: {...current_month[month], [e.target.name]:e.target.value}})
  //   return;
  // };
  // const addDate = Month_action.map((action)=>{
  //   if(action.payments_expenses > 0) return action.date
  // });
  const [index, setIndex] = useState([0]);
  // const [rowCount, setRowCount] = useState(6);
  // const currency = useSelector((state) => state.income.currency);

  const addRow = (e) => {
    e.preventDefault();
    if (month !== "select month") dispatch({ type: "month_action", payload: [...Month_action, { ...month_traker, date: `${currentYear}-${current_month?.monthDate}-01` }] });
    // setRowCount((prevCount) => prevCount + 1)
    // setRowCount((prevCount) => prevCount + 1)

    return;
  };
  const addRowMonthlyFlow = (e) => {
    e.preventDefault();
    dispatch({ type: "Month_action_monthly_flow", payload: [...Month_action_monthly_flow, { ...month_traker, date: `${currentYear}-${monthly_flow.monthDate}-01` }] });
    return;
  };


  const sortMonth = (e) => {
    // e.preventDefault()
    const new_array = Array.from(Month_action);
    new_array.sort((b, a) => new Date(b.date).toUTCString() - new Date(a.date).toUTCString());
    console.log(new_array)
    dispatch({ type: "month_action", payload: [...new_array] });
    document.getElementById("incomeForm").reset();

  }
  const sortMonthly = (e) => {

    // e.preventDefault()
    const new_array = Array.from(Month_action_monthly_flow);
    new_array.sort((b, a) => new Date(b.date) - new Date(a.date));
    dispatch({ type: "Month_action_monthly_flow", payload: [...new_array] });
    document.getElementById("incomeForm").reset();

  }
  const ASelect = [t("Income"),t("Expence")]
  const RSelect = [t("General"),t("Marketing"),t("Providers"),t("Salaries"),
    t("Bank"),t("Income_tax"),t("Vat"),t("Social_security"),
    t("Book_keeping"),t("Private"),t("Maintenance"),t("Customer")]
  const FSelect =  [t("Check"),t("Credit_card"),t("Cash"),t("Bank_Transfer"),t("Transfer_via_app")]
  const excelData = [
    [t("month")+ " :", month_list[index],"", t('Current_account_status') + " :", formatNumber(+current_account_status_base), t('Account_Frame')  + " :", formatNumber(+account_frame) ],
    [],
    [
    t("Date_of_Income_Expense"),
    t("Type_of_Income_Expense"),t("What_is_the_expense_income_for"),
    t("Detail_of_expenditure_customer_name"),t("How_the_expense_income_was_made"),
    t("Payments_Expenses"),t("Income"),t("Notes_Remarks_for_control_and_analysis")
    ],
    ...Month_action.map((action)=> {
      return [action.date, ASelect[action.income_expence_select], RSelect[action.reason_select], action.detail_name,
       FSelect[action.fivePositionSelect] ,formatNumber(+action.income_revenue), formatNumber(+action.payments_expenses), action.notes
       ]

    }),
    ["","","","","", formatNumber(total_expense) , formatNumber(total_income) , "",],
    [],
    ["","","","", t('Current_account_status') + " :",formatNumber(+total_expense + +total_income + +current_account_status_base)]
  ]
  return (
    <Fragment>
      <div className="all_page">
        <div className='video-container_p'>
          <VideoNewModal videoUrl={t('video_url')} />
        </div>
        {/* <div className="container" style={{ justifyContent: "start" }}> */}
          {/* <div className="header_title3"> */}
          <div className="head_titel_income">
            <p className="title1_income">
             {t("start_1")}
            </p>
            <p className="title1_income ">
            {t("start_2")}
            </p>
            <p className="title1_income ">
            {t("start_3")}
            </p>
          </div>
          {/* </div> */}
        {/* </div> */}
        <form id="incomeForm">
          <div className="inner_section">

            {/* <ExcelButton
              name={"income"}
              data={""}
              excel={handleExport}
            /> */}

            <div className="title2_income">
              {t("table_name")}
            </div>
            <div className="main-page-content">



              <div className="table">
                <div className="container_grid_nine_income">
                  <div className="cell_date income_header">
                    {t("Date_of_Income_Expense")}
                    <div className="sort_icon" onClick={(e) => sortMonthly(e)}>{"< >"}</div>
                  </div>
                  <div className="income_header">{t("Type_of_Income_Expense")}</div>
                  <div className="income_header">{t("What_is_the_expense_income_for")}</div>
                  <div className="income_header">{t("Detail_of_expenditure_customer_name")}</div>
                  <div className="income_header">{t("How_the_expense_income_was_made")}</div>
                  <div className="income_header">{t("Payments_Expenses")}</div>
                  <div className="income_header">{t("Income")}</div>
                  <div className="income_header">{t("Current_account_status_table")}</div>
                  <div className="income_header">{t("Notes_Remarks_for_control_and_analysis")}</div>
                </div>

                {Month_action_monthly_flow.map((action, i) => (
                  <IncomeTableMonthlyFlow month={month} key={i} action={action} input_number={i} page={"_income"} />
                ))}

<div className="container_grid_nine_income">
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="total_expense_div" >
                    <input
                      name="total_expense"
                      className="total-expense"
                      placeholder="0"
                      dir="rtl"
                      disabled
                      value={formatNumber(total_expense_monthly_flow)}
                    />
                  </div>
                  <div className="total_income_div">
                    <input
                      name="total_income"
                      className="total-income"
                      placeholder="0"
                      dir="rtl"
                      disabled
                      value={formatNumber(total_income_monthly_flow)}
                    />
                  </div>
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div_a" >
                    <AddRow text={t("add")} addRow={addRowMonthlyFlow} />
                  </div>


                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                </div>
              </div>
            </div>

            <div className="bottom-details">


            </div>

          </div>
        </form>
        <div className="button_container">
              <button type="button" onClick={(e)=> saveMonthFlow(e)} className="save_button_first_page">{t("Save")}</button>
            </div>
        <div className="fit_table_income">

          <p className="text_center month_title">{month_list[12]}</p>
          <MonthSelectIncome
            text={month_list}
            setIndex={setIndex}
            resetForm={"incomeForm2"}
            months={months}
            page={"_income"}
          />
        </div>
        <form id="incomeForm2">
          <div className="inner_section">

            <ExcelButton
              name={"income"}
              data={excelData}
              excel={(month !== "select month")? handleExport: ""}
            />

            <div className="main-page-content">
              <div className="inputs-container-xl-container">
                <div className="inputs-container">
                  <FunctionalInput
                    text={"Current_account_status"}
                    state={"current_account_status_base"}
                    month={month}
                    page={"_income"}
                    Month_action={Month_action}
                  />

                  <FunctionalInput
                    text={"Account_Frame"}
                    state={"account_frame"}
                    month={month}
                    page={"_income"}
                  />
                  <button type="button" onClick={(e) => fix_month(e)} className="pull_monthly">{t("pull_monthly")}</button>
                </div>
                {/* <div className="xl-container"> */}
                {/* </div> */}
              </div>


              <div className="table">
                <div className="container_grid_nine_income">
                  <div className="cell_date income_header">
                    {t("Date_of_Income_Expense")}
                    <div className="sort_icon" onClick={(e) => sortMonth(e)}>{"< >"}</div>
                  </div>
                  <div className="income_header">{t("Type_of_Income_Expense")}</div>
                  <div className="income_header">{t("What_is_the_expense_income_for")}</div>
                  <div className="income_header">{t("Detail_of_expenditure_customer_name")}</div>
                  <div className="income_header">{t("How_the_expense_income_was_made")}</div>
                  <div className="income_header">{t("Payments_Expenses")}</div>
                  <div className="income_header">{t("Income")}</div>
                  <div className="income_header">{t("Current_account_status_table")}</div>
                  <div className="income_header">{t("Notes_Remarks_for_control_and_analysis")}</div>
                </div>

                {Month_action.map((action, i) => (
                  <IncomeTableInput month={month} key={i} action={action} input_number={i} page={"_income"} flag={redFlags} setFlags={setRedFlags} />
                ))}

                <div className="container_grid_nine_income">
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="total_expense_div" >
                    <input
                      name="total_expense"
                      className="total-expense"
                      placeholder="0"
                      dir="rtl"
                      disabled
                      // type="number"
                      // onChange={changeTotalExpenseValue}
                      value={formatNumber(total_expense)}
                    />
                  </div>
                  <div className="total_income_div">
                    <input
                      name="total_income"
                      className="total-income"
                      placeholder="0"
                      dir="rtl"
                      disabled
                      // type="number"
                      // onChange={changeTotalIncomeValue}
                      value={formatNumber(total_income)}
                    />
                  </div>
                  <div className="input_div" />
                  <div className="input_div" />
                  <div className="input_div_a" >
                    <AddRow text={t("add")} addRow={addRow} />
                  </div>
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                  <div className="input_div_a" />
                </div>

                {/* <div className="plus-button-container">
              <button
              type="button"
                className="plus-button"
                onClick={(e) => {addRow(e)}}
              >
                &#x002B;
              </button>
            </div> */}
              </div>
            </div>
            {/* <TextToSpeech text={""} /> */}
            <div className="bottom-details">
              <Warning text={t('warning')} />
              {/* {+current_account_status_base + +total_income + +account_frame + -total_expense < 0 ? (

                <Fragment>
                  <br />
                    <TextToSpeech text={`${t("On")} ${addDate} ${t("date_expense_greater_than_current_amount")}`} />
                  <p className="red_title">

                  <FontAwesomeIcon
                    icon={faFlag}
                    className="flag-icon flag-raise"
                    />
                  {`${t("On")} ${addDate} ${t("date_expense_greater_than_current_amount")}`}
                    </p>
                </Fragment>)

            :""} */}
              {redFlags.map((date) => (
                <Fragment>
                  <h4 className={`third_title  status-message red`}>
                    <FontAwesomeIcon
                      icon={faFlag}
                      className="flag-icon flag-raise"
                    />
                    {`${t("On")} ${date} ${t("date_expense_greater_than_current_amount")}`}
                  </h4>
                </Fragment>)
              )}

              {+total_expense > +total_income && !(month === "select month" || month === "Monthly_flow") ? (<Fragment>

                {/* <TextToSpeech text={t("Total_expenses_are_greater_than_total_income")} /> */}
                <h4 className={`third_title  status-message red`}>
                  <FontAwesomeIcon
                    icon={faFlag}
                    className="flag-icon flag-raise"
                  />
                  {t("Total_expenses_are_greater_than_total_income")}
                </h4>
              </Fragment>)
                : ""}
              {/* <p> {t("Recommendations_for_action")}</p>
            <p>{t("Lorem_ipsum")}</p>
            <p>{t("Lorem_ipsum")}</p>
            <p>{t("Lorem_ipsum")}</p> */}
            </div>
            {/* </div> */}
          </div>
        </form>
        <div className="button_container">
          <button type="button" onClick={(e) => saveMonth(e)} className="save_button_first_page">{t("Save")}</button>
        </div>
      </div>
    </Fragment>
  );
}

export default Income;
