import React, { Fragment, useState, useEffect,useMemo } from 'react';
import './Dashboard.css';  // Add custom CSS for table styling
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';  // Import date formatting utility
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GaugeChart from "react-gauge-chart";
import {formatNumber} from "../../helpers/helpers"

function Dashboard() {
  const totalfinanceState = useSelector(state => state.finance.Total.total) || 0;
  const turnover = useSelector(state => state.budget.transaction_turnover_before_vat) || 0;
  const totalBudgetActualExpence = useSelector(state => state.budget.total_actual_expense) || 0;

  const currency = useSelector((state) => state.income.currency);

  const { t } = useTranslation("Dashboard");
  const navigate = useNavigate(); // Hook to navigate to another route

  // State to manage selected days range
  const [daysRange, setDaysRange] = useState(7);
  const [dateRange, setDateRange] = useState('');

  // Function to calculate the date range based on the number of days and current date
  const calculateDateRange = (days) => {
    const today = new Date();
    const endDate = format(today, 'dd/MM/yy');
    const startDate = format(new Date(today.setDate(today.getDate() - days + 1)), 'dd/MM/yy');
    return `${startDate} - ${endDate}`;
  };

  // Effect to update date range when daysRange changes
  useEffect(() => {
    setDateRange(calculateDateRange(daysRange));
  }, [daysRange]);

  const handleTargetMetricsClick = () => {
    navigate('/confined-work-plan'); // Navigate to the desired page
  };

  const totalScale = Math.max(totalfinanceState, turnover, totalBudgetActualExpence) * 1.1; // Slightly higher than the max


  // Percentages for the gauges
  const profitPercentage = useMemo(() => Math.abs(totalfinanceState / (totalScale + totalBudgetActualExpence)), [totalfinanceState, totalScale, totalBudgetActualExpence]);
  const lossPercentage = useMemo(() => Math.abs(turnover / (totalScale + turnover)), [turnover,totalScale]);



  return (
    <Fragment>


      <div className="dashboard-container">
        <div className="header_dashboard">
          <h2 className='dashboard_h2'>{t('Hello, User Name')}</h2>
          <div className="date_picker_dashboard">
            <select
              className="date_select_dashboard"
              value={daysRange}
              onChange={(e) => setDaysRange(Number(e.target.value))}
            >
              <option value={7}>{t('Last_7_Days')}</option>
              <option value={14}>{t('Last_14_Days')}</option>
              <option value={21}>{t('Last_21_Days')}</option>
              <option value={30}>{t('Last_30_Days')}</option>
              <option value={91}>{t('Last quater')}</option>
              <option value={183}>{t('Last semester')}</option>
            </select>
            <button className="date_btn_dashboard">{dateRange}</button>
          </div>
        </div>

      <div className='container gauges_container' >
      <div className="text_center">
        {/* green gauge , income gauge */}
        <GaugeChart
          id="profit-gauge"
          nrOfLevels={19}
          percent={profitPercentage ? profitPercentage : 0}
          colors={totalfinanceState >=0 ? ["#e0e0e0","#2F7164" ] :["#e0e0e0","#FF4E42" ] }
          arcWidth={0.4}
          needleColor="#757575"
          // textColor="lightgray"
        />
        <h3>{t("total_profit_loss")}</h3>
        <h2><FontAwesomeIcon icon={currency}/> {formatNumber(totalfinanceState)}</h2>
      </div>

      <div className="text_center">
           {/* red gauge , balance gauge */}
        <GaugeChart
          id="loss-gauge"
          nrOfLevels={19}
          percent={lossPercentage ? lossPercentage :0}
          colors={turnover >= 0 ? ["#e0e0e0","#2F7164" ] :["#e0e0e0","#FF4E42" ]}
          arcWidth={0.4}
          needleColor="#757575"
          // textColor="lightgray"
        />
        <h3>{t("total_balance_point")}</h3>
        <h2><FontAwesomeIcon  icon={currency}/> {formatNumber(totalBudgetActualExpence)}</h2>
      </div>
    </div>

        <div className="card-container">
          <div className="card-dashboard">
            <h3>{t('Total revenue before VAT')}</h3>
            <p className={`card-value ${totalfinanceState >=0 ? "green_dashboard" : "red_dashboard"}`}>
              <FontAwesomeIcon  className="dashboard_icon" icon={currency}/> {formatNumber(totalfinanceState)}</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '10.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Total sales turnover before VAT')}</h3>
            <p className="card-value"><FontAwesomeIcon icon={currency}/> 1,000,000</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '12.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Total number of potential clients at 90% status')}</h3>
            <p className="card-value">10</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '24.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Total potential revenue at 90% status')}</h3>
            <p className="card-value"><FontAwesomeIcon icon={currency}/> 300,000</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '12.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Marketing efficiency')}</h3>
            <p className="card-value">25%</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '10.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Sales efficiency')}</h3>
            <p className="card-value">20%</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '13.00%' })}</p>
          </div>
          <div className="card-dashboard">
            <h3>{t('Customer satisfaction survey score')}</h3>
            <p className="card-value">90</p>
            <p className="card-increase">{t('↗ {{percentage}} Increase vs last week', { percentage: '12.00%' })}</p>
          </div>
          <div className="card-dashboard target-metrics" onClick={handleTargetMetricsClick} style={{cursor: 'pointer'}}>
            <h3>{t('Target metrics')}</h3>
            <p>{t('Enter and compare in the focused work plan')}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dashboard;
