import React, { Fragment, useState } from "react";
import SalesCard from "../../components/SalesCard/SalesCard";
import "./Sales.css";
import SalesModal from "../../components/Modals/SalesModal/SalesModal";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton";

function Sales() {
  const { t } = useTranslation("Sales");
  const salesData = [
    {
      icon: "/assets/i18n/Sales/icons/quality.svg",
      title: t("Choosing high product quality"),
      bodyText: (
        <ul>
          <li>{t("The longest warranty")}</li>
          <li>{t("Uncompromising materials")}</li>
          <li>{t("Design and style")}</li>
          <li>{t("Environmental standards")}</li>
          <li>{t("Comparison to other products")}</li>
          <li>{t("Reviews and recommendations")}</li>
        </ul>
      ),
    },
    {
      icon: "/assets/i18n/Sales/icons/price.svg",
      title: t("The most competitive price in the industry"),
      bodyText: (
        <ul>
          <li>{t("The highest cost-benefit ratio")}</li>
          <li>{t("Comparison to competitors")}</li>
        </ul>
      ),
    },
    {
      icon: "/assets/i18n/Sales/icons/service.svg",
      title: t("The best service experience in the industry"),
      bodyText: (
        <ul>
          <li>{t("Excellent customer service")}</li>
          <li>{t("Customized user experience")}</li>
          <li>
            {t("Attention to details that are important to the customer")}
          </li>
        </ul>
      ),
    },
    {
      icon: "/assets/i18n/Sales/icons/project.svg",
      title: t("Project management"),
      bodyText: (
        <ul>
          <li>{t("Adherence to deadlines")}</li>
          <li>{t("Use of project management software")}</li>
          <li>
            {t(
              "Advance planning and client approval according to requirements"
            )}
          </li>
          <li>
            {t("Monitoring planning versus execution and minimizing gaps")}
          </li>
        </ul>
      ),
    },
    {
      icon: "/assets/i18n/Sales/icons/leadership.svg",
      title: t("Leadership in innovation and technology"),
      bodyText: (
        <ul>
          <li>{t("Use of advanced engineering software")}</li>
          <li>{t("Use of advanced manufacturing processes")}</li>
          <li>{t("Use of advanced machinery")}</li>
          <li>{t("Implementation of advanced product quality controls")}</li>
          <li>{t("Utilization of leading talent")}</li>
        </ul>
      ),
    },
  ];

  const checkboxState = useSelector((state) => state.sales.checkbox1);
  const [selectedCard, setSelectedCard] = useState(null);
  //add this for the 2 lines in the title
  const titleLines = [t("Creating a marketing strategy"),t("Choose_one_or_more")];

  function handleCardClick(card) {
    console.log("Card clicked:", card);
    setSelectedCard(card);
  }

  // Function to download the PDF
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (!input) {
      console.error("Element not found");
      return;
    }

    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // A4 width in mm minus margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const marginRight = 15; // Right margin in mm
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", marginRight, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add new pages if content exceeds one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          marginRight,
          position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }

      pdf.save("marketing-strategy.pdf");
    });
  };

  return (
    <Fragment>
      {checkboxState && selectedCard && (
        <SalesModal
          icon={selectedCard.icon}
          title={selectedCard.title}
          bodyText={selectedCard.bodyText}
        />
      )}

      {/* Add the id="pdf-content" here */}
      <div id="pdf-content">
        <div className="text-container_p">
          {/* <h1>{titleLines[0]}</h1> */}
          {/* Render TextComponent directly */}
          <p id="second_title">{titleLines[1]}</p>
          <div className="video-container_p">
            <VideoNewModal videoUrl={t("video_url")} />
          </div>
        </div>

        <PdfButton onClick={downloadPDF}/>

        <div className="grid_container_sales">
          {salesData.map((card, index) => (
            <SalesCard
              key={index}
              icon={card.icon}
              text={card.title}
              bodyText={card.bodyText} // Pass the bodyText here
              onClick={() => handleCardClick(card)}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Sales;
