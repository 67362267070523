import React, { Fragment, useState } from "react";
import "./AdvertisingChannels.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton"


function AdvertisingChannels() {
  const { t, i18n } = useTranslation("Sales");
  const currency = useSelector((state) => state.income.currency);
  const [choice, setChoice] = useState(0);
  const [error, setError] = useState("");

  // Function to download the PDF for the current advertising text section
  const downloadPDF = () => {
    if (choice === 0) {
      // Set an error message if no choice is made
      setError(t("Please_select_an_option_before_downloading"));
      return;
    }

    const input = document.querySelector(".continu_text_body"); // Target the continu_text_body part
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // A4 width in mm minus margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("advertising-channels.pdf");
    });
  };

  return (
    <div className="content" dir={`${i18n.language === "he" ? "rtl" : "ltr"}`}>
      <div className="main_container">
        {/* <h1 className="title_text">
          {t("Stage_3_Adapting_advertising_channels")}
        </h1> */}
        <PdfButton onClick={downloadPDF}/>

        <p className="body_text">
          {t("There_are_several_different_advertising_channels")}
        </p>
        <p className="body_text">
          {t("High_efficiency_leads_to_budget_savings")}
        </p>
        <div className="select_container">
          <h3 className="select_title">
            {t("Include_the_advertising_budget")}
          </h3>
          <div className="select_options">
            <select
              className="selset"
              onChange={(e) => {
                setChoice(Number(e.target.value));
                setError(""); // Clear error message when an option is selected
              }}
            >
              <option selected disabled hidden></option>
              <option value={1}>{`${t("Up_to_10000")}`}</option>
              <option value={2}>{`${t("Up_to_20000")}`}</option>
              <option value={3}>{`${t("Over_20000")}`}</option>
            </select>
            <FontAwesomeIcon icon={currency} />
          </div>
        </div>

        <div className="continu_text_body">
          {choice === 1 && (
            <>
              <p>{`${t("text_up_to_10000_1")}`}</p>
              <p>{`${t("text_up_to_10000_2")}`}</p>
              <p>{`${t("text_up_to_10000_3")}`}</p>
              <p>{`${t("text_up_to_10000_4")}`}</p>
            </>
          )}
          {choice === 2 && (
            <Fragment>
              <p>{`${t("text_up_to_20000_1")}`}</p>
              <p>{`${t("text_up_to_20000_2")}`}</p>
              <p>{`${t("text_up_to_20000_3")}`}</p>
              <p>{`${t("text_up_to_20000_4")}`}</p>
            </Fragment>
          )}
          {choice === 3 && (
            <Fragment>
              <p>{`${t("text_over_20000_1")}`}</p>
              <p>{`${t("text_over_20000_2")}`}</p>
              <p>{`${t("text_over_20000_3")}`}</p>
              <p>{`${t("text_over_20000_4")}`}</p>
              <p>{`${t("text_over_20000_5")}`}</p>
              <p>{`${t("text_over_20000_6")}`}</p>
            </Fragment>
          )}
        </div>

        {/* Show error message if no option is selected */}
        {error && <p className="error_message">{error}</p>}



        {/* <button onClick={downloadPDF} className="download-button">
          <FontAwesomeIcon icon={faFilePdf} />
        </button> */}
      </div>
    </div>
  );
}

export default AdvertisingChannels;
