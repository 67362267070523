
const currentYear = new Date().getFullYear();
const defaultIncomeState = {
  month: "select month",
  payments_expenses: 0,
  income_revenue: 0,
  action_type: 0,
  how_was_made: 0,
  date: "00-00-00",
  notes: "",
  detail_name: "",
  current_account_status_base: 0,
  currency: "faShekelSign",
  month_traker:
  {
    payments_expenses: 0,
    income_revenue: 0,
    income_expence_select: "0",
    fivePositionSelect: "0",
    reason_select: "0",
    date: "0000-00-00",
    notes: "",
    detail_name: "",
  }
  ,
  Month_action: [
    {
      payments_expenses: 0,
      income_revenue: 0,
      income_expence_select: "0",
      fivePositionSelect: "0",
      reason_select: "0",
      date: "0000-00-00",
      notes: "",
      detail_name: "",
    }
  ],
  Month_action_monthly_flow: [
    {
      payments_expenses: 0,
      income_revenue: 0,
      income_expence_select: "0",
      fivePositionSelect: "0",
      reason_select: "0",
      date: `${currentYear}-01-01`,
      notes: "",
      detail_name: "",
    }
  ],
  ai: false,
  total_expense: 0,
  total_income: 0,
  total_expense_monthly_flow: 0,
  total_income_monthly_flow: 0,
  account_frame: 0,
  Video_open: true,
  Monthly_flow: {
    month: "Monthly_flow",
    active: true,
    monthDate: "01",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-01-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  January: {
    active: false,
    month: "January",
    monthDate: "01",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-01-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  February: {
    active: false,
    month: "February",
    monthDate: "02",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-02-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  March: {
    active: false,
    month: "March",
    monthDate: "03",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-03-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  April: {
    active: false,
    month: "April",
    monthDate: "04",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-04-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  May: {
    active: false,
    month: "May",
    monthDate: "05",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-05-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  June: {
    active: false,
    month: "June",
    monthDate: "06",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-06-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  July: {
    active: false,
    month: "July",
    monthDate: "07",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-07-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  August: {
    active: false,
    month: "August",
    monthDate: "08",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-08-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  September: {
    active: false,
    month: "September",
    monthDate: "09",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-09-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  October: {
    active: false,
    month: "October",
    monthDate: "10",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-10-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  November: {
    active: false,
    month: "November",
    monthDate: "11",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-11-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },
  December: {
    active: false,
    month: "December",
    monthDate: "12",
    current_account_status_base: 0,
    account_frame: 0,
    total_expense: 0,
    total_income: 0,
    Month_action: [
      {
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select: "0",
        fivePositionSelect: "0",
        reason_select: "0",
        date: `${currentYear}-12-01`,
        notes: "",
        detail_name: "",
      }
    ],
  },

}

// const names = Object.keys(defaultState);

export const incomeReducer = (state = defaultIncomeState, action) => {
  switch (action.type) {
    case "month":
      return { ...state, month: action.payload };
    case "month_action":
      return { ...state, Month_action: action.payload };
    case "dispatch_monthly_flow":
      return {
        ...state,
        Month_action: action.payload.month_action,
        total_expense: action.payload.total_expense,
        total_income: action.payload.total_income
      };
    case "Month_action_monthly_flow":
      return { ...state, Month_action_monthly_flow: action.payload };
    case "January_income":
      return { ...state, January: action.payload };
    case "Monthly_flow_income":
      return { ...state, Monthly_flow: action.payload };
    case "February_income":
      return { ...state, February: action.payload };
    case "March_income":
      return { ...state, March: action.payload };
    case "April_income":
      return { ...state, April: action.payload };
    case "May_income":
      return { ...state, May: action.payload };
    case "June_income":
      return { ...state, June: action.payload };
    case "July_income":
      return { ...state, July: action.payload };
    case "August_income":
      return { ...state, August: action.payload };
    case "September_income":
      return { ...state, September: action.payload };
    case "October_income":
      return { ...state, October: action.payload };
    case "November_income":
      return { ...state, November: action.payload };
    case "December_income":
      return { ...state, December: action.payload };
    case "payments_expenses":
      return { ...state, payments_expenses: action.payload };
    case "income_revenue":
      return { ...state, income_revenue: action.payload };
    case "current_account_status_base":
      return { ...state, current_account_status_base: action.payload, current_account_status: action.payload };
    case "current_account_status":
      return { ...state, current_account_status: action.payload };
    case "date":
      return { ...state, date: action.payload };
    case "notes":
      return { ...state, notes: action.payload };
    case "detail_name":
      return { ...state, detail_name: action.payload };
    case "total_expense":
      return { ...state, total_expense: action.payload };
    case "total_income":
      return { ...state, total_income: action.payload };
    case "total_expense_monthly_flow":
      return { ...state, total_expense_monthly_flow: action.payload };
    case "total_income_monthly_flow":
      return { ...state, total_income_monthly_flow: action.payload };
    case "account_frame":
      return { ...state, account_frame: action.payload };
    case "Video_open":
      return { ...state, Video_open: action.payload };
    case "currency":
      return { ...state, currency: action.payload };
    case "ai":
      return { ...state, ai: action.payload };

    default:
      return state;
  }
};