import * as XLSX from 'xlsx'
import i18next from "i18next";

export const handleExportSales=(data, name)=>{
    const currentLanguageDirection = i18next.dir();

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Income");
    worksheet['!cols'] =[{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18},{ width:18}];
    workbook.Workbook = {  Views: [
        {RTL: currentLanguageDirection === "rtl"}
      ],

    }
    // יצירת הקובץ
    XLSX.writeFile(workbook, `${name}.xlsx`);

}