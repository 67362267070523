import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./CompetitorAnalisys.css";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PdfButton from "../../components/Buttons/PdfButton/PdfButton";
import Loader from "../../components/Loader/Loader";
import { CompetitorPostReq, CompetitorGetReq, CompetitorPutReq, CompetitorDeleteReq } from "./CompetitorAnalisysReq";

function CompetitorAnalisys() {
  const { t } = useTranslation("Competitor");

  const [fieldValue, setFieldValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [questions, setQuestions] = useState([]);
  const [aiResponse, setAiResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

  // Fetch saved data when component mounts
  useEffect(() => {
    const fetchSavedData = async () => {
      try {
        const savedData = await CompetitorGetReq();
        if (savedData) {
          setFieldValue(savedData.fieldValue || "");
          setCountryValue(savedData.countryValue || "");
          setEmployeeCount(savedData.employeeCount || "");
          setQuestions(savedData.questions || []);
        }
      } catch (error) {
        console.error("Error fetching saved data:", error.message);
      }
    };

    fetchSavedData();
  }, []);

  const handleInputChange = () => {
    return `
      ${t("I am a CEO / owner in the field / industry of")} ${fieldValue}
      ${t("from the country of")} ${countryValue}
      ${t("with")} ${employeeCount} ${t("employees")}

      ${t("Please generate a report analyzing my top 5 competitors")}:

      ${t("What are the main messages each of them is promoting?")}
      ${t("What advertising channels is each of them using?")}
      ${t("What are the notable achievements of each one?")}

      ${questions.map((q, i) => `Question ${i + 1}: ${q}`).join("\n")}
    `;
  };

  // Send all data (input and questions) to backend
  const sendToAI = async () => {
    const fullText = handleInputChange();
    if (!fullText.trim()) {
      alert("Please enter all required information before sending.");
      return;
    }

    const requestData = {
      fieldValue,
      countryValue,
      employeeCount,
      questions,
    };

    setLoader(true);
    try {
      // Send data to backend to save the state
      await CompetitorPostReq(requestData);

      setAiResponse("Report generated successfully");
    } catch (error) {
      console.error("Error sending to AI:", error.message);
      setAiResponse(
        "Error! An error occurred while fetching the AI response. Please try again."
      );
    } finally {
      setLoader(false);
    }
  };

  const addQuestion = () => {
    setQuestions([...questions, ""]);
  };

  const updateQuestion = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const downloadPDF = () => {
    const input = document.getElementById("ai-response-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
      });
      const imgWidth = 595.28;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("AI_Report.pdf");
    });
  };

  return (
    <div>
      {loader && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <div className="additional-content">
        <div className="video-container_p">
          <VideoNewModal videoUrl={t("video_url")} />
        </div>
        <PdfButton onClick={downloadPDF} />
        <div className="form-row">
          {t("I am a CEO / owner in the field / industry of")}
          <input
            type="text"
            placeholder={t("complete field")}
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
          />
          {t("from the country of")}
          <input
            type="text"
            placeholder={t("country")}
            value={countryValue}
            onChange={(e) => setCountryValue(e.target.value)}
          />
          {t("with")}
          <input
            type="text"
            placeholder={t("number of employees")}
            value={employeeCount}
            onChange={(e) => setEmployeeCount(e.target.value)}
          />
          {t("employees")}
          <br />
        </div>
        <p>{t("Please generate a report analyzing my top 5 competitors")}:</p>
        <ol>
          <li>{t("What are the main messages each of them is promoting?")}</li>
          <li>{t("What advertising channels is each of them using?")}</li>
          <li>{t("What are the notable achievements of each one?")}</li>
        </ol>
        {questions.map((question, index) => (
          <input
            key={index}
            type="text"
            placeholder={t("Enter your additional question")}
            value={question}
            onChange={(e) => updateQuestion(index, e.target.value)}
          />
        ))}

        <button className="add_button_competitor" onClick={addQuestion}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <button className="generate-report-button" onClick={sendToAI}>
        {t("Generate Report")}
      </button>
      {aiResponse && (
        <div className="ai-response" id="ai-response-content">
          <p dangerouslySetInnerHTML={{ __html: aiResponse }} />
        </div>
      )}
    </div>
  );
}

export default CompetitorAnalisys;
