import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt,faClone } from "@fortawesome/free-solid-svg-icons";
import "./CreatingWorkflow.css";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";

function CreatingWorkflow() {
  const { t } = useTranslation("Workflow");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forms } = useSelector((state) => state.workflow);

// Main page handleCreate function
const handleCreate = (e) => {
  e.preventDefault();
  const newFormId = forms.length + 1;
  dispatch({
    type: "ADD_WORKFLOW_FORM", // Ensure this action is handled
    payload: {
      id: newFormId,
      nameOfProcedure: "",
      startDate: new Date().toISOString().split("T")[0], // Automatically set creation date
      status: "Pending",
      lastUpdate: new Date().toISOString().split("T")[0], // Automatically set last update
    }
  });
  navigate(`/create-workflow/${newFormId}`);
};

  const handleDelete = (id) => {
    dispatch({ type: "REMOVE_WORKFLOW_FORM", payload: id });
  };

  console.log("Forms in CreatingWorkflow:", forms);

  return (
    <>
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>
      <div>

        <table className="experience-table_creating">
          <thead>
            <tr>
              <th>{t("name_of_survey")}</th>
              <th>{t("date_created")}</th>
              <th>{t("status")}</th>
              <th>{t("last_update")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {forms.map((form) => (
              <tr key={form.id}>
                <td className="clickable-cell" onClick={() => navigate(`/create-workflow/${form.id}`)}>
                  {form.nameOfProcedure || t("Value")}
                </td>
                <td>{form.startDate || t("No_date")}</td>
                <td>{form.status || t("Pending")}</td>
                <td>{form.lastUpdate || t("No update")}</td>
                <td>
                  <button className="action-btn" onClick={() => navigate(`/create-workflow/${form.id}`)}>
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button className="action-btn"onClick={() => handleDelete(form.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                  {/* New Duplicate button */}
                  <button className="action-btn" onClick={() => {
  const newFormId = forms.length + 1; // Generate a new form ID
  dispatch({
    type: "DUPLICATE_WORKFLOW_FORM",
    payload: { id: form.id, newId: newFormId }, // Pass both original ID and new ID
  });
}}>
  <FontAwesomeIcon icon={faClone} />
</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="button-container1">
        <button className="top_button_create" onClick={(e)=>handleCreate(e)}>
          {t("Create")}
        </button>
      </div>
      </div>
    </>
  );
}

export default CreatingWorkflow;