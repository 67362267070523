// import axios from "axios";

//  export const IncomePostReq = async () => {
//   try {
//     const response = await axios({
//       method: "post",
//       url: "http://localhost:5000",
//       data: {
//       },
//     });
//     console.log(response)
//   } catch (error) {
//     console.log(error)
//   }
// };




import axios from "axios";

// POST request to submit income data
export const IncomePostReq = async (data) => {
  try {
    const response = await axios.post("http://localhost:5000/api/income", data);
    console.log("POST Response:", response);
  } catch (error) {
    console.error("POST Error:", error);
  }
};

// GET request to retrieve income data
export const IncomeGetReq = async () => {
  try {
    const response = await axios.get("http://localhost:5000/api/income");
    console.log("GET Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Error:", error);
  }
};

// PUT request to update income data
export const IncomePutReq = async (id, data) => {
  try {
    const response = await axios.put(`http://localhost:5000/api/income/${id}`, data);
    console.log("PUT Response:", response);
  } catch (error) {
    console.error("PUT Error:", error);
  }
};

// DELETE request to remove income data
export const IncomeDeleteReq = async (id) => {
  try {
    const response = await axios.delete(`http://localhost:5000/api/income/${id}`);
    console.log("DELETE Response:", response);
  } catch (error) {
    console.error("DELETE Error:", error);
  }
};
