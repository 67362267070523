// marketingReducer.js

// Initial state with all months and fields
const initialState = {
  January: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0},
  February: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  March: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  April: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  May: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  June: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  July: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  August: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  September: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  October: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  November: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  December: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 },
  Total: { totalBudget: 0, retainerSuppliers: 0, marketingStaff: 0, TM: 0, TL: 0, TR: 0, TA: 0, TI: 0, effectiveness_for_leads:0, effectiveness_for_sales:0,sales_efficiency:0 }
};

// Reducer function to handle actions
export const marketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'saveData': 
    return {...state, [action.payload.month]: action.payload.data};
      case 'UPDATE_FIELD': {
          const { month, field, value } = action.payload;

          // Update TM (Total Marketing) if necessary
          const updatedMonth = {
              ...state[month],
              [field]: value,
              TM: (field === 'totalBudget' || field === 'retainerSuppliers' || field === 'marketingStaff') 
                  ? (field === 'totalBudget' 
                      ? value + state[month].retainerSuppliers + state[month].marketingStaff
                      : field === 'retainerSuppliers'
                      ? state[month].totalBudget + value + state[month].marketingStaff
                      : state[month].totalBudget + state[month].retainerSuppliers + value)
                  : state[month].TM
          };


          return {
              ...state,
              [month]: updatedMonth
          };
      }

      default:
          return state;
  }
};

// Action creator to update a field
export const updateField = (month, field, value) => ({
  type: 'UPDATE_FIELD',
  payload: { month, field, value }
});
