import React, { Fragment,useState } from "react";
import "./SupplierTable.css";
import AddButton from "../../AddButton/AddButton";
import { formatNumber } from "../../../helpers/helpers";
import { useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
function SupplierTable({ onSave, supplierHeader, amountHeader ,saveButtonText ,modal, state,type}) {
  const dispatch = useDispatch()
  const { t } = useTranslation("Finance");
  const [rows, setRows] = useState([...state]);
  const total = [...rows].reduce((total, row) => total + parseFloat(row.amount || 0), 0);
  const addRow = (e) => {
    e.preventDefault()
    setRows([...rows, { name: "", amount: "" }]);
  };
  const handelCancel = (e)=> {
    e.preventDefault()
    modal(false)
    // dispatch({type: type, payload: []})
    onSave(0)
  }
  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index] = {...newRows[index], [field]: value}
    setRows(newRows);
  };

  const handleSave = (e) => {
    e.preventDefault()
    // const total = rows.reduce(
    //   (acc, row) => acc + parseFloat(row.amount || 0),
    //   0
    // );
    dispatch({type: type, payload: rows})
    onSave(total);
    modal(false)
  };

  return (
    <div className="expandable-table">
      <div className="table-grid">
        <div className="table-header">{supplierHeader}</div>
        <div className="table-header">{amountHeader}</div>
        {rows.map((row, index) => (
          <Fragment key={index}>
            <input
              type="text"
              value={row.name}
              onChange={(e) => handleInputChange(index, "name", e.target.value)}
              className="table-input"
            />
            <input
              type="number"
              value={row.amount}
              dir="ltr"
              onChange={(e) =>
                handleInputChange(index, "amount", e.target.value)
              }
              className="table-input text_center"
            />
          </Fragment>
        ))}
                <div className="table_add_section"><AddButton text={t("add")} onClick={addRow}/></div>
                <div className="table_add_section"></div>
                <div className="table-header">{t("total")} :</div>
                <div className="table-header">{formatNumber(total)}</div>
      </div>
      {/* <button type="button" onClick={addRow} className="add-button">
        +
      </button> */}
      <div className="buttons-supplier">
      <button type="button" onClick={handelCancel} className="cancel-button-supplier">
      {t("cancel")}
      </button>
      <button type="button" onClick={handleSave} className="save-button-supplier">
      {saveButtonText}
      </button>

      </div>
    </div>
  );
}

export default SupplierTable;
