const defaultConcentationDebts = {
  debts:{
loans :[
    { date:"", source: "", purpose: "", amountTaken: 0, scheduledPayments: 0, amountRemaining: 0, paymentsRemaining: 0, monthlyRepayment: 0 }
],
update_date:"",
totalAmountTaken:0,
totalScheduledPayments:0,
totalAmountRemaining:0,
totalPaymentsRemaining:0,
totalMonthlyRepayment:0
}
};

export const concentationDebtsReducer = (state = defaultConcentationDebts, action) => {
  switch (action.type) {
    case "debts":
      return { ...state, debts:{
          loans: action.payload.loans,
         update_date:action.payload.update_date,
         totalScheduledPayments: action.payload.totalScheduledPayments,
         totalAmountRemaining: action.payload.totalAmountRemaining,
         totalPaymentsRemaining:action.payload.totalPaymentsRemaining,
         totalMonthlyRepayment:action.payload.totalMonthlyRepayment
        }};
    default:
      return state;
  }
};
