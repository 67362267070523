import React,{ useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import "./CreateProcedure.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PdfButton from "../../components/Buttons/PdfButton/PdfButton";

function CreateProcedure() {
  const { t } = useTranslation("DefineRules");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pdfRef = useRef();

  // Accéder aux données du formulaire actuel et à la liste des formulaires
  const {
    startDate,
    lastUpdate,
    questions,
    image,
    nameOfProcedure,
    numberOfProcedure,
    numberOfPages,
    preparedBy,
    authorizedToMakeChanges,
    approval,
    isSubmitted,
  } = useSelector((state) => state.define.currentForm);

  const forms = useSelector((state) => state.define.currentForm); // Accéder aux formulaires dans Redux

  const addQuestion = () => {
    dispatch({ type: "ADD_DEFINE_QUESTION" });
  };

  const removeQuestion = (id) => {
    dispatch({ type: "REMOVE_DEFINE_QUESTION", payload: id });
  };

  const handleQuestionChange = (id, newText) => {
    dispatch({
      type: "SET_DEFINE_QUESTION_TEXT",
      payload: { id, text: newText },
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Vérifiez si l'image est bien lue par le FileReader
        console.log("Image base64: ", reader.result);
        
        // Dispatch de l'image vers Redux
        dispatch({ type: "SETDEFINE__IMAGE", payload: reader.result });
      };
      reader.readAsDataURL(file); // Lire le fichier comme URL base64
    } else {
      console.log("No file selected");
    }
  };
  

  const handleFinish = () => {
    dispatch({ type: "ADD_DEFINE_FORM" }); // Ajouter le formulaire dans Redux

    // Naviguer vers la page create2 avec l'ID du dernier formulaire ajouté
    const lastFormId = forms.length + 1; // Supposons que le nouvel ID est length + 1
    navigate(`/define-rules`);
  };

  const downloadPDF = () => {
    const pdfContent = pdfRef.current;  // Use the ref assigned to the container
    const saveButton = document.getElementById("save-button");

    if (saveButton) {
        saveButton.style.display = "none"; // Hide the save button during PDF generation
    }

    html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;  // A4 page width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("Work-Plan.pdf");
    }).finally(() => {
        if (saveButton) {
            saveButton.style.display = "block";  // Show the save button again
        }
    });
};


  return (
    <div className="all11">
      <div className="page111">
        {/* Header */}
        <div className="header11">
          <button className="back-button2" onClick={() => navigate("/define-rules")}>
            ← {t("Back_to")}
          </button>
        </div>


      </div>
      <div className="pdf_to_right">
      <PdfButton onClick={downloadPDF} />
      </div>

<div className="work-plan-table-container" id="pdf-content">
    <div className='cash_flow_table' ref={pdfRef}>
      {/* Form Container */}
      <div className="form-container21">
        {/* Image Upload Section */}
        <div className="upload-section">
          <div
            className="logo-placeholder"
            onClick={() => document.getElementById("file-upload").click()}
          >
            {!image && <span>{t("Upload Photo")}</span>}
            <FontAwesomeIcon icon={faPen} className="edit-icon" />
            <input
              id="file-upload"
              type="file"
              onChange={handleImageUpload}
              className="file-input"
              accept="image/*"
            />
            {image && <img src={image} alt="Uploaded" className="uploaded-image" />}
          </div>
        </div>

        {/* Form Section */}
        <div className="form-section">
          <div className="input-row">
            <div className="input-column">
              <label>{t("Name of Procedure")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={nameOfProcedure}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_NAME_OF_PROCEDURE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Number of Procedure")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={numberOfProcedure}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_NUMBER_OF_PROCEDURE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Number of pages")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={numberOfPages}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_NUMBER_OF_PAGES", payload: e.target.value })
                }
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-column">
              <label>{t("Date created")}</label>
              <input
                type="date"
                className="input-field"
                value={startDate}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_START_DATE", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Last update")}</label>
              <input
                type="date"
                className="input-field"
                value={lastUpdate}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_LAST_UPDATE", payload: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        {/* Question Section */}
        <div className="form-section">
          {questions.map((question) => (
            <div key={question.id} className="question-row">
              <textarea
                type="text"
                value={question.text}
                onChange={(e) => handleQuestionChange(question.id, e.target.value)}
                className="input-field"
                placeholder={t("Text")}
              />
              <button
                onClick={() => removeQuestion(question.id)}
                className="icon-button"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ))}
          <button onClick={addQuestion} className="add-question-button">
            <FontAwesomeIcon icon={faPlus} /> {t("Add Text")}
          </button>
        </div>

        {/* Form Section 2 */}
        <div className="form-section">
          <div className="input-row">
            <div className="input-column">
              <label>{t("Prepared by")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={preparedBy}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_PREPARED_BY", payload: e.target.value })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Authorized to make changes")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={authorizedToMakeChanges}
                onChange={(e) =>
                  dispatch({
                    type: "SET_DEFINE_AUTHORIZED_TO_MAKE_CHANGES",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className="input-column">
              <label>{t("Approval")}</label>
              <input
                type="text"
                className="input-field"
                placeholder={t("Value")}
                value={approval}
                onChange={(e) =>
                  dispatch({ type: "SET_DEFINE_APPROVAL", payload: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>

      {!isSubmitted && (
        <button className="top_button_create" onClick={handleFinish}>
          {t("Finish")}
        </button>
      )}

      <div className="page">

      </div>
    </div>
  </div>
</div>
  );
}

export default CreateProcedure;
