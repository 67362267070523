import React from 'react'
import {useSelector} from 'react-redux';
import { formatNumber } from '../../../helpers/helpers';
import "./TextButtonCell.css"
function TextButtonCell(props) {
    const {text,buttonText, month, state, textRed} = props;
    const input_value = useSelector(state => state.finance[month]);
  return (
    <div className='container_grid_f'>
    <div className='text_cell_total'>{buttonText}</div>
    <div className='currency_value' dir='ltr'>
    <div className='fill_up'></div>
    <div dir='ltr' className={`input_finance ${textRed? "text_red": ""}`}>{input_value? ((input_value[state] > 0) ? formatNumber(text): formatNumber(text)) : ""}</div>
    </div>
</div>
  )
}

export default TextButtonCell
