import React, { Fragment, useEffect, useState } from 'react'
import "./ConcentrationDebts.css";
import { useSelector, useDispatch } from "react-redux";
import { handleExport } from '../../Excel/debts.js'
import AddRow from '../../components/AddRow/AddRow';
import { useTranslation } from "react-i18next";
import TotalDiv from '../../components/TotalDiv/TotalDiv';
import ExcelButton from '../../components/Buttons/ExcelButton/ExcelButton';
import { formatNumber } from "../../helpers/helpers";
import { DebtsPostReq } from './ConcentrationDebtsReq';


function ConcentrationDebts() {
  const { t } = useTranslation("Debts");
  const dispatch = useDispatch()
  const Debts = useSelector((state) => state.debts.debts);
  const currency = useSelector((state) => state.income.currency);
  const [loans, setLoans] = useState([
    ...Debts.loans
  ]);
  const addRow = () => {
    setLoans([...loans, {date:"", source: "", purpose: "", amountTaken: 0, scheduledPayments: 0, amountRemaining: 0, paymentsRemaining: 0, monthlyRepayment: 0 , loanEnd:""}]);
  };

  const handleInputChange = (index, event) => {
    const newLoans = loans.map((loan, i) => {
      if (i === index) {
        return { ...loan, [event.target.name]: event.target.value };
      }
      return loan;
    });
    setLoans(newLoans);
  };
  const handleInputChangeNumber = (index, event) => {
    if(event.target.value >= 0) {
    const newLoans = loans.map((loan, i) => {
      if (i === index) {
        return { ...loan, [event.target.name]: event.target.value };
      }
      return loan;
    });
    setLoans(newLoans);
  };
  }
  const handleInputTotalPayments = (index, event) => {
    if(event.target.value >= 0) {
    const newLoans = loans.map((loan, i) => {
      if (i === index) {
        const endDate = addMonths(new Date(loan.date), event.target.value)
        const paymentLeft = getTheDiffrent(endDate)
        return { ...loan, [event.target.name]: event.target.value , amountRemaining: (+loan.monthlyRepayment * +paymentLeft), loanEnd:endDate, paymentsRemaining:paymentLeft};
      }
      return loan;
    });
    setLoans(newLoans);
  };
  }
  const handleInputLoanDate = (index, event) => {
      const newLoans = loans.map((loan, i) => {
        if (i === index) {
          const endDate = addMonths(new Date( event.target.value), loan.scheduledPayments)
          const paymentLeft = getTheDiffrent(endDate)
          return { ...loan, [event.target.name]: event.target.value, amountRemaining: (+loan.monthlyRepayment * +paymentLeft) ,loanEnd:endDate, paymentsRemaining:paymentLeft};
        }
        return loan;
      });
      setLoans(newLoans);
    };
  const handleInputMonthlyPayment = (index, event) => {
    if(event.target.value >= 0) {
      const newLoans = loans.map((loan, i) => {
        const endDate = addMonths(new Date(loan.date), loan.scheduledPayments)
        const paymentLeft = getTheDiffrent(endDate)
        if (i === index) {
          return { ...loan, [event.target.name]: event.target.value,amountRemaining: (+loan.monthlyRepayment * +paymentLeft) ,loanEnd:endDate, paymentsRemaining:paymentLeft };
        }
        return loan;
      });
      setLoans(newLoans);
    };
  }

  useEffect(()=>{
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString( {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });

    if(Debts.update_date !== currentDate){
      const newLoans = loans.map((loan, i)=>{
        const millisecondsPerMonth = 30.4375 * 24 * 60 * 60 * 1000
        const differenceInMilliseconds =  new Date(loan.loanEnd).toUTCString()  - new Date(formattedDate).toUTCString()
        const monthsDifference = differenceInMilliseconds / millisecondsPerMonth;
        const theDif = (Math.round(monthsDifference))
        console.log(theDif)
        if(theDif <= 0){
          return {...loan, paymentsRemaining: 0  ,amountRemaining: 0  }
        }
        else{

          return {...loan, paymentsRemaining: theDif  ,amountRemaining: loan.monthlyRepayment * theDif }
        }
      })
      setLoans(newLoans)
    }
  },[])
  const totalAmountTaken = loans.reduce((total, loan) => total + parseFloat(loan.amountTaken || 0), 0);
  const totalScheduledPayments = loans.reduce((total, loan) => total + parseFloat(loan.scheduledPayments || 0), 0);
  const totalAmountRemaining = loans.reduce((total, loan) => total + parseFloat(loan.amountRemaining || 0), 0);
  const totalPaymentsRemaining = loans.reduce((total, loan) => total + parseFloat(loan.paymentsRemaining || 0), 0);
  const totalMonthlyRepayment = loans.reduce((total, loan) => total + parseFloat(loan.monthlyRepayment || 0), 0);

  const saveDebts = async (e) => {
    e.preventDefault();

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString({
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    const debtsData = {
      loans: loans,
      update_date: formattedDate,
      totalAmountTaken: totalAmountTaken,
      totalScheduledPayments: totalScheduledPayments,
      totalAmountRemaining: totalAmountRemaining,
      totalPaymentsRemaining: totalPaymentsRemaining,
      totalMonthlyRepayment: totalMonthlyRepayment,
    };

    // POST Request
    try {
      await DebtsPostReq(debtsData);
      console.log("POST request successful for debts");
    } catch (error) {
      console.error("POST request failed:", error);
    }

    // Dispatch to Redux store
    dispatch({
      type: "debts",
      payload: debtsData
    });
  };

    function addMonths(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + +months );
  if(newDate.getDate() !== new Date(date).getDate()){
    newDate.setDate(0)
  }
  return newDate.toLocaleDateString();
}
// function getTheDiffrent(date){
//   const currentDate = new Date();
//   // const formattedDate = currentDate.toLocaleDateString( {
//   //   year: 'numeric',
//   //   month: 'numeric',
//   //   day: 'numeric'
//   // });
//   const millisecondsPerMonth = 30.4375 * 24 * 60 * 60 * 1000
//   const differenceInMilliseconds =  new Date(date)  - new Date(currentDate)
//   const monthsDifference = differenceInMilliseconds / millisecondsPerMonth;
//   const theDif = (Math.round(monthsDifference))
//   console.log(theDif)
//   if(theDif <= 0){
//     return  0
//   }
//   else{

//     return theDif
//   }
// }

function getTheDiffrent(date) {
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = new Date(date) - currentDate;

  // Average milliseconds per month (accounting for leap years)
  const millisecondsPerMonth = 30.4375 * 24 * 60 * 60 * 1000;

  // Calculate the difference in months
  let monthsDifference = differenceInMilliseconds / millisecondsPerMonth;

  // Round up to the nearest whole month if there's any fraction
  monthsDifference = Math.ceil(monthsDifference);

  // Ensure non-negative difference
  const theDifference = Math.max(monthsDifference, 0);


  return theDifference;
}

// console.log(getDifference('2025-12-25')); // Example usage




const excelData = [
  [t('date'),t("source"),t("funding_for"),t("total_amount_taken"),t("total_monthly_repayment"),t("total_payments_scheduled")
   ,t("end_of_loan") ,t("total_remaining_payments"),t("total_Amount_Remaining")
  ],
  ...loans.map((loan)=> {
    return [loan.date, loan.source, loan.purpose, formatNumber(+loan.amountTaken), formatNumber(+loan.monthlyRepayment),
     formatNumber(+loan.scheduledPayments) ,loan.loanEnd,formatNumber(+loan.paymentsRemaining), formatNumber(+loan.amountRemaining)
     ]}),
  [t('total',), "","", formatNumber(totalAmountTaken),formatNumber(totalMonthlyRepayment) ,String(totalScheduledPayments), "", String(totalPaymentsRemaining), formatNumber(totalAmountRemaining)]
]
const [maxDate, setMaxDate] = useState('');

useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setMaxDate(today);
}, []);

  return (
    <Fragment>
      {/* <div className='page_title text_center '>Open Debts</div> */}
      <div className='page_postion'>
      <div>

      <div className="header_title1">
        { Debts.update_date}
        </div>
        <ExcelButton
        name={"Debts"}
        data={excelData}
        excel={handleExport}
        />
      </div>
          <div className='scroll_table'>
        <div className='debts_table '>

        <div className='grid_eight_debts'>
              <div className='laon_table_h'><div className='debts_h_text first_h'>{t('date')}</div></div>
              <div className='laon_table_h'><div className='debts_h_text first_h'>{t("source")} </div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("funding_for")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("total_amount_taken")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("total_monthly_repayment")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("total_payments_scheduled")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("end_of_loan")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("total_remaining_payments")}</div></div>
              <div className='laon_table_h'><div className='debts_h_text'>{t("total_Amount_Remaining")}</div></div>
        </div>
        {loans.map((loan, index) => (
          <div className='grid_eight_debts'>
                  <div className='laon_table_r'>
             <input
              className='grid_eight_input'
              name='date'
              type="date"
              max={maxDate}
              value={loan.date}
              onChange={(e) => handleInputLoanDate(index, e)} />
             </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input"
                    type="text" name="source"
                    value={loan.source}
                    onChange={(e) => handleInputChange(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input"
                    type="text" name="purpose" value={loan.purpose}
                    onChange={(e) => handleInputChange(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"
                    type="number" name="amountTaken"
                    placeholder='0'
                    value={loan.amountTaken > 0 ? loan.amountTaken: ""}
                    onChange={(e) => handleInputChangeNumber(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"

                    placeholder='0'
                    type="Text" name="monthlyRepayment" defaultValue={loan.paymentsRemaining > 0 ? formatNumber(loan.monthlyRepayment) : ""}
                    onChange={(e) => handleInputMonthlyPayment(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"
                    type="number" name="scheduledPayments"
                    placeholder='0'
                    value={loan.scheduledPayments > 0 ? loan.scheduledPayments: ""} onChange={(e) => handleInputTotalPayments(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"
                    readOnly
                    type="text" name="loanEnd" value={loan.loanEnd}
                    onChange={(e) => handleInputChange(index, e)} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"
                  readOnly
                    type="number" name="paymentsRemaining"
                    placeholder='0'
                    value={loan.paymentsRemaining > 0 ? loan.paymentsRemaining : ""} />
                  </div>
                  <div className='laon_table_r'>
                  <input className="concentration_input_number"
                  readOnly
                    type="text" name="amountRemaining"
                    placeholder='0'
                    value={loan.amountRemaining > 0 ? formatNumber(loan.amountRemaining): ""}
                    />
                  </div>
            </div>
        )
      )}
                <div className='grid_eight_debts'>
                <div className='laon_table_h'></div>
              <div className='laon_table_h'></div>
              <div className='laon_table_h'></div>
              <div className='laon_table_h'><div className='debts_h_text'>
                <TotalDiv  currency={currency} totalAmountTaken={totalAmountTaken} text={t('total')}/>
                </div></div>
                <div className='laon_table_h'>
                <div className='debts_h_text'>
                <TotalDiv currency={currency} totalAmountTaken={totalMonthlyRepayment} text={t('total')}/>
                  </div>
                  </div>
              <div className='laon_table_h'><div className='debts_h_text'>
              <TotalDiv totalAmountTaken={totalScheduledPayments} text={t('total')}/>
                </div></div>
                  <div className='laon_table_h'></div>
              <div className='laon_table_h'><div className='debts_h_text'>
              <TotalDiv totalAmountTaken={totalPaymentsRemaining} text={t('total')}/>
                </div></div>
                <div className='laon_table_h'><div className='debts_h_text'>
              <TotalDiv currency={currency} totalAmountTaken={totalAmountRemaining} text={t('total')}/>
                </div></div>
                  <div className='laon_table_r_a'><AddRow text={t("add")} addRow={addRow}/></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>
                <div className='laon_table_r_a'></div>

        </div>
        </div>
        </div>

        <button className='save_button_first_page' onClick={saveDebts}>
            {t("save")}
        </button>
      {/* </div> */}
      </div>
    </Fragment>

  )
}

export default ConcentrationDebts
