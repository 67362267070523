import React, { useState, useEffect} from 'react';
import AvatarModal from '../Modals/AvatarModal/AvatarModal';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const TextToSpeech = () => {
  const text = useSelector((state) => state.app.ai_responce)
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { i18n } = useTranslation("App");
  // Load available voices
  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setVoices(voices);
      // Auto-select Hebrew voice if available
      if(i18n.language ==="he"){
      const hebrewVoice = voices.find((voice) => voice.lang.startsWith('he'));
      setSelectedVoice(hebrewVoice ? hebrewVoice.name : voices[0]?.name);
      }
      else{
        const englishVoice = voices.find((voice) => voice.lang.startsWith('en'));
        setSelectedVoice(englishVoice ? englishVoice.name : voices[0]?.name);
      }
    };

    // Load voices and listen for changes
    window.speechSynthesis.onvoiceschanged = loadVoices;
    loadVoices(); // Initial load
  }, [i18n.language]);

  // Function to handle the Text to Speech conversion
  const handleSpeak = () => {
    if (text.trim()) {
      const speech = new SpeechSynthesisUtterance(text);
      const selectedVoiceObj = voices.find((voice) => voice.name === selectedVoice);
      speech.voice = selectedVoiceObj;
      setIsSpeaking(true);
      speech.onend = () => setIsSpeaking(false); // Reset speaking state after speech finishes
      window.speechSynthesis.speak(speech);
    }
  };

  // Automatically speak when text is updated
  useEffect(() => {
    if (text) {
      // Cancel any ongoing speech before speaking new text
      window.speechSynthesis.cancel();
      handleSpeak();
    }
  }, [text]); // Listen to text changes

  // Function to handle muting/stop speech
  const handleMute = () => {
    window.speechSynthesis.cancel();
    setIsSpeaking(!isSpeaking);
  };

  return (
  <AvatarModal handleMute={handleMute} isSpeaking={isSpeaking}/>

  );
};

export default TextToSpeech;

    {/* <div style={{ marginTop: '10px' }}>
        <label>Select Voice: </label>
        <select
          value={selectedVoice}
          onChange={(e) => setSelectedVoice(e.target.value)}
        >
          {voices.map((voice) => (
            <option key={voice.name} value={voice.name}>
              {voice.name} ({voice.lang})
            </option>
          ))}
        </select>
      </div> */}



