import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import "./CreatePage3.css";

function CreatePage3() {
  const { t } = useTranslation("Experience");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Récupérer l'ID depuis l'URL
  const { id } = useParams(); // Utilisation de useParams pour récupérer l'ID

  // Access form data and formLink (to be added to the reducer)
  const {
    forms,
    googleFormLink, // Anticipating this to be part of the reducer in the future
  } = useSelector((state) => state.customer.currentForm);

  // Default text with a link included
  const defaultText = `Link for Google Forms: ${googleFormLink || "https://default-link.com"}`;

  return (
    <div className="all3">
      <div className="page3">
        {/* Header */}
        <div className="header_create_page">
          <button className="back-button2" onClick={() => navigate("/customer-experience")}>
            ← {t("Back_to")}
          </button>
        </div>

        {/* Progress Bar */}
        <div className="create-progress-bar2">
          <div className="create-step completed" onClick={() => navigate(`/create1/${id}`)}>1</div>
          <div className="create-step completed" onClick={() => navigate(`/create2/${id}`)}>2</div>
          <div className="create-step completed" onClick={() => navigate(`/create3/${id}`)}>3</div>
        </div>
      </div>

      <div className="create-form-section">
        <div className="create-input-row">
          <h3>{t("result_title")}</h3>
          <p>{t("result_description")}</p>
<p>
  <a href={googleFormLink || "https://default-link.com"} target="_blank" rel="noopener noreferrer">
    {t("Click here to open the Google Form")}
  </a>
</p>


{/* Add the image below the link */}
<img
            src={t("image_path")} // Retrieve the image path from the translation
            alt="Related to the result" 
            className="form-image"
          />

          
          {/* <textarea
            value={defaultText} // Set the default text
            className="create-input-field"
            readOnly // Prevent changes to the text
          /> */}
        </div>
      </div>

      <div className="page">
        {/* Back Button */}
        <button className="top_button_back" onClick={() => navigate(`/create2/${id}`)}>
          {t("Back")}
        </button>
      </div>
    </div>
  );
}

export default CreatePage3;
