import React, { Fragment, useState } from "react";
import "./AvatarModal.css";
import { useTranslation } from "react-i18next";
// import ReactPlayer from "react-player";
// import Video from "./talking_video.mp4";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader"
import image from "../AvatarModal/Animation.gif"
import image_quiet from "../AvatarModal/Animation_quiet.gif"

function AvatarModal({ handleMute, isSpeaking }) {
    const { t, i18n } = useTranslation("App");
    const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [userInput, setUserInput] = useState("");
    const [aiResponse, setAiResponse] = useState(null);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const ai = useSelector((state) => state.income.ai);

    const closeFunc = (e) => {
        e.preventDefault();
        handleMute();
        dispatch({ type: "ai", payload: !ai });
    };

    const sendPrompt = async (e) => {

        if (!userInput.trim()) {
            setAiResponse(t("Error! Please enter a message before sending"));
            return;
        }
        setLoader(true);
        try {
            e.preventDefault();
            const response = await fetch(`${BACKEND_BASE_URL}/openai`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: userInput, userToken: '' }), // todo: pass userToken here
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            const lastResponse = data.lastResponse;
            setAiResponse(lastResponse);
            setLoader(false)
            dispatch({ type: "ai_responce", payload: lastResponse });
        } catch (error) {
            setAiResponse(t("Error! An error occurred while fetching the AI response. Please try again"));
        }
        finally {
            setLoader(false);
        }
    };

    const inputAiFunc = (e) => {
        e.preventDefault();
        setUserInput(e.target.value);
        return;
    };

    return (
        <Fragment >
            <div className="background_ai">
                {ai && (
                    <div className={`player_wrapper_ai ${i18n.language === "he" ? "rtl" : "ltr"}`}>


                        <div className="video-container-ai">
                            <div className="container_align_end">
                                <button className="mute-avatar-button" onClick={handleMute} disabled={!isSpeaking}>Mute</button>
                                {/* <button className="mute-avatar-button" onClick={handleMute} disabled={!isSpeaking}>&#x1F507;</button> */}
                                <button className="exsit-button" onClick={(e) => closeFunc(e)}>X</button>
                            </div>
                            <div className="img_container">
                                {isSpeaking ?

                                    <img src={image} alt="avatar" className="img_avatar"></img>
                                    :
                                    <img src={image_quiet} alt="avatar" className="img_avatar"></img>

                                }
                            </div>
                            {/* <ReactPlayer
                                className="react_player_ai"
                                url={Video}
                                width={"800px"}
                                height={"350px"}
                                playing={true}
                                loop={true}
                            /> */}
                            <div className="container_column responce_height">
                                {userInput &&
                                    <div className="user_input_ai" >{userInput}</div>
                                }
                                <div className="ai_response_container">
                                    {aiResponse &&
                                        // <div className="ai_response" dir={i18n.language === "he" ? "ltr" : "rtl"}>
                                        <div className="ai_response">
                                            {aiResponse}</div>
                                    }
                                </div>
                                {loader && <Loader />}
                                {/* <div className="container_row" dir={`${i18n.language === "he" ? "rtl" : "ltr"} `}> */}
                                <div className="container_row">
                                    <input
                                        className="input_ai"
                                        placeholder={t("Talk_to_Ai")}
                                        value={userInput}
                                        disabled={loader}
                                        onChange={(e) => inputAiFunc(e)}
                                    />
                                    <button
                                        onClick={(e) => sendPrompt(e)}
                                        className={`button_ai ${i18n.language === "he" ? "mr_button_ai" : "ml_button_ai"} `}>
                                        &#x16902;
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default AvatarModal;
