const initialState = {
  selectedMonth: 'January', // Default month
  projects: {
    January: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)], // Initial values for checkboxes are 0 (unchecked)
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    February: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    March: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    April: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    May: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    June: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    July: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    August: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    September: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    October: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    November: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
    December: [
      {
        name: '',
        resources: [''],
        cost: '',
        tasks: [{ text: '' }],
        days: [Array(31).fill(0)],
        columnNames: [],
        additionalColumnValues: [],
      },
    ],
  },
};

export const vsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PROJECTS':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: action.payload // Save the projects for the selected month
        }
      };

    case 'ADD_PROJECT':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: [
            ...state.projects[state.selectedMonth],
            {
              name: '',
              resources: [''],
              cost: '',
              tasks: [{ text: '' }],
              days: [Array(31).fill(false)],
              columnNames: [],
              additionalColumnValues: [],
            },
          ],
        },
      };
    case 'ADD_TASK_LINE':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.index
              ? {
                  ...project,
                  tasks: [...project.tasks, { text: '' }],
                  days: [...project.days, Array(31).fill(false)],
                }
              : project
          ),
        },
      };
    case 'ADD_RESOURCE_LINE':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.index
              ? { ...project, resources: [...project.resources, ''] }
              : project
          ),
        },
      };
      case 'ADD_COLUMN':
  return {
    ...state,
    projects: {
      ...state.projects,
      [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
        projectIndex === action.projectIndex
          ? {
              ...project,
              additionalColumnValues: [
                ...project.additionalColumnValues,
                [] // Ajoute une nouvelle colonne vide
              ],
            }
          : project
      ),
    },
  };

    
      case 'ADD_ROW_TO_COLUMN':
        return {
          ...state,
          projects: {
            ...state.projects,
            [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
              projectIndex === action.projectIndex
                ? {
                    ...project,
                    // Ajout des nouvelles valeurs dans la colonne spécifique
                    additionalColumnValues: project.additionalColumnValues.map((colValues, colIndex) =>
                      colIndex === action.colIndex
                        ? [...colValues, ''] // Ajout d'une nouvelle ligne vide à la colonne
                        : colValues
                    ),
                  }
                : project // Les autres projets restent inchangés
            ),
          },
        };
      
    case 'UPDATE_RESOURCE':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? {
                  ...project,
                  resources: project.resources.map((resource, resourceIndex) =>
                    resourceIndex === action.resourceIndex ? action.text : resource
                  ),
                }
              : project
            ),
          },
        };
    case 'UPDATE_TASK_TEXT':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? {
                  ...project,
                  tasks: project.tasks.map((task, taskIndex) =>
                    taskIndex === action.taskIndex
                      ? { ...task, text: action.text }
                      : task
                  ),
                }
              : project
          ),
        },
      };
    case 'TOGGLE_TASK_DAY':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? {
                  ...project,
                  days: project.days.map((taskDays, taskIndex) =>
                    taskIndex === action.taskIndex
                      ? taskDays.map((day, dayIndex) =>
                          dayIndex === action.dayIndex
                            ? (day + 1) % 4 // Toggle between 0, 1, 2, 3 (0 = inactive, 1 = blue, 2 = green, 3 = red)
                            : day
                        )
                      : taskDays
                  ),
                }
              : project
          ),
        },
      };
    case 'UPDATE_PROJECT_NAME':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? { ...project, name: action.name }
              : project
          ),
        },
      };
    case 'UPDATE_PROJECT_COST':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? { ...project, cost: action.cost }
              : project
          ),
        },
      };
    case 'UPDATE_COLUMN_NAME':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? {
                  ...project,
                  columnNames: project.columnNames.map((name, colIndex) =>
                    colIndex === action.colIndex ? action.text : name
                  ),
                }
              : project
          ),
        },
      };
    case 'UPDATE_COLUMN_VALUE':
      return {
        ...state,
        projects: {
          ...state.projects,
          [state.selectedMonth]: state.projects[state.selectedMonth].map((project, projectIndex) =>
            projectIndex === action.projectIndex
              ? {
                  ...project,
                  additionalColumnValues: project.additionalColumnValues.map(
                    (columnValues, colIndex) =>
                      colIndex === action.colIndex
                        ? columnValues.map((value, rowIndex) =>
                            rowIndex === action.rowIndex ? action.text : value
                          )
                        : columnValues
                  ),
                }
              : project
          ),
        },
      };
    case 'month':
      return {
        ...state,
        selectedMonth: action.payload,
      };

      case 'UPDATE_PROJECTS':
    return {
        ...state,
        projects: {
            ...state.projects,
            [state.selectedMonth]: action.payload, // Mise à jour des projets pour le mois sélectionné
        },
    };

    default:
      return state;
  }
};
