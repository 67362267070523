import React from 'react'
import './FinanceMonthHeader.css'

function FinanceMonthHeader(props) {

  const {mon,  text,} = props
  

  return (
    <div  key={mon} id={mon} className="div_finance_t_head">{text}</div>
  )
}

export default FinanceMonthHeader