import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:5000/api";

// POST request to create new sales data
export const createSalesData = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/sales`, data);
    console.log("POST Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("POST Error:", error.message);
    throw error;
  }
};


// GET request to fetch sales data
export const getSalesData = async (month) => {
    try {
      const response = await axios.get(`${BASE_URL}/sales?month=${month}`);
      console.log("GET Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("GET Error:", error.message);
      throw error;
    }
  };

  // PUT request to update sales data
export const updateSalesData = async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/sales/${id}`, data);
      console.log("PUT Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("PUT Error:", error.message);
      throw error;
    }
  };

  
  // DELETE request to delete sales data
export const deleteSalesData = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/sales/${id}`);
      console.log("DELETE Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("DELETE Error:", error.message);
      throw error;
    }
  };
  