import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ExamplePage.css"; // Ensure this file contains the button styles

function ExamplePage3() {
  const { t } = useTranslation("Workflow");
  const navigate = useNavigate();

  return (
    <div className="page">
      {/* Header */}
      <div className="header">
        <button className="back-button2" onClick={() => navigate(-1)}>
          ← {t("Back_to")}
        </button>
      </div>

      <button className="top_button_create33">
  {t("Create")}
</button>


      {/* Cards Container */}
      <div className="cards-container">
        {Array.from({ length: 12 }).map((_, index) => (
          <div className="card" key={index}>
            <div className="card-step">{t(`Step ${index + 1}`)}</div>
            <div className="card-content">
              <h3>{t("Card Title")}</h3>
              <p>{t("Responsibility")}: Manager</p>
              <p>{t("Time")}: TBD</p>
              <p>{t("Procedure")}: TBD</p>
              <p>{t("Notes")}: TBD</p>
            </div>
            {index < 11 && (
              <div className="arrow-container">
                <div className="arrow"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExamplePage3;
