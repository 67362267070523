import React from "react";
import "./Artical.css";
import { useTranslation } from 'react-i18next';
import VideoNewModal from '../Modals/VideoNewModal/VideoNewModal';
// import ReactPlayer from "react-player";
function SalesObjections() {
  const { t } = useTranslation('SalesTraining');
  return (
    <div className="paragraph">
      <div className="video_div">
    <VideoNewModal videoUrl={t("video_url2")} />
      </div>
{/* <div className="container">
        <div className="steps ">
          <ReactPlayer
            className="react-player"
            url={t("video_url2")}
            width={`70%`}
            height={"70%"}
            controls={true}
            //   onEnded={() => {
            //     dispatch({ type: "Video_open", payload: false });
            //   }}
          />
        </div>
      </div> */}
      <div className='textArea'>
        <div className='keys_container'>
          <p className='text_salse'>{t('selse_objections_text_1')}</p>
          <bold className='modal_h_sales'>
          {t('selse_objections_first_option_h_1')}
            </bold>
            {/* {t('first_modal_h')} */}
             <p className='text_salse' >{t('selse_objections_first_option_text_1')}</p>
             <p className='text_salse' >{t('selse_objections_first_option_text_2')}</p>

          <bold className='modal_h_sales'>{t('selse_objections_second_option_h_1')}</bold>
             <p className='text_salse' >{t('selse_objections_second_option_text_1')}</p>
             <p className='text_salse' >{t('selse_objections_second_option_text_2')}</p>
          
          <bold className='modal_h_sales'>{t('selse_objections_third_option_h_1')}</bold>
             <p className='text_salse' >{t('selse_objections_third_option_text_1')}</p>
             <p className='text_salse' >{t('selse_objections_third_option_text_2')}</p>
        </div>
      </div>
    </div>
  );
}

export default SalesObjections;
