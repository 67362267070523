const initialState = {
  forms: [],
  currentForm: {
    id: null,
    formName: "", // New field for form name
    creationDate: "", // New field for creation date
    caseName: "",
    caseDescription: "",
    lessonsLearned: [""],
    correctiveActions: [""],
    questions: [{ id: 1, text: "" }],
    lastUpdate: "", // New field for the last update
    googleFormLink: "",
  },
};

const learningLessonsReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REMOVE_CORRECTIVE_ACTION":
  return {
    ...state,
    currentForm: {
      ...state.currentForm,
      correctiveActions: state.currentForm.correctiveActions.filter((_, index) => index !== action.payload),
      lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
    },
  };

  
    case "CREATE_LESSONS_NEW_FORM":
      return {
        ...state,
        forms: [...state.forms, { ...initialState.currentForm, id: action.payload }],
        currentForm: { ...initialState.currentForm, id: action.payload },
      };

    case "SAVE_LESSONS_FORM":
      const existingFormIndex = state.forms.findIndex((form) => form.id === state.currentForm.id);
      const updatedForms =
        existingFormIndex !== -1
          ? state.forms.map((form, index) =>
              index === existingFormIndex ? { ...form, ...state.currentForm } : form
            )
          : [...state.forms, state.currentForm];

      return {
        ...state,
        forms: updatedForms,
      };

    case "LOAD_LESSONS_FORM":
      const selectedForm = state.forms.find((form) => form.id === action.payload);
      return {
        ...state,
        currentForm: selectedForm ? { ...selectedForm } : { ...initialState.currentForm, id: action.payload },
      };

    case "SET_LESSONS_FORM_FIELD":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          [action.payload.field]: action.payload.value,
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "SET_QUESTION_TEXT":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: state.currentForm.questions.map((question, index) =>
            index === action.payload.index ? { ...question, text: action.payload.text } : question
          ),
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "ADD_QUESTION":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: [...state.currentForm.questions, { id: state.currentForm.questions.length + 1, text: "" }],
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "REMOVE_QUESTION":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          questions: state.currentForm.questions.filter((_, index) => index !== action.payload),
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "ADD_LESSON_LEARNED":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          lessonsLearned: [...state.currentForm.lessonsLearned, ""],
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "SET_LESSON_LEARNED":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          lessonsLearned: state.currentForm.lessonsLearned.map((lesson, index) =>
            index === action.payload.index ? action.payload.text : lesson
          ),
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "ADD_CORRECTIVE_ACTION":
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          correctiveActions: [...state.currentForm.correctiveActions, ""],
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "SET_CORRECTIVE_ACTION":
      const updatedCorrectiveActions = [...state.currentForm.correctiveActions];
      updatedCorrectiveActions[action.payload.index] = action.payload.text || "";
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          correctiveActions: updatedCorrectiveActions,
          lastUpdate: new Date().toISOString(), // Automatically update the 'lastUpdate' field
        },
      };

    case "REMOVE_LESSONS_FORM":
      return {
        ...state,
        forms: state.forms.filter((form) => form.id !== action.payload),
      };

      case "DUPLICATE_LESSONS_FORM":
      const formToDuplicate = state.forms.find((form) => form.id === action.payload.id);
      if (!formToDuplicate) return state;

      const duplicatedForm = {
        ...formToDuplicate,
        id: action.payload.newId, // Assign a new ID
        lastUpdate: new Date().toISOString(), // Update the last modified date
        creationDate: new Date().toISOString().split("T")[0], // Set a new creation date
      };

      return {
        ...state,
        forms: [...state.forms, duplicatedForm], // Add the duplicated form to the list
      };

    default:
      return state;
  }
};

// Actions
export const loadLessonsForm = (formId) => ({
  type: "LOAD_LESSONS_FORM",
  payload: formId,
});

export const updateLessonsForm = () => ({
  type: "SAVE_LESSONS_FORM",
});

export const setLessonsFormField = (field, value) => ({
  type: "SET_LESSONS_FORM_FIELD",
  payload: { field, value },
});

export const addLessonLearned = () => ({
  type: "ADD_LESSON_LEARNED",
});

export const setLessonLearned = (index, text) => ({
  type: "SET_LESSON_LEARNED",
  payload: { index, text },
});

export const addCorrectiveAction = () => ({
  type: "ADD_CORRECTIVE_ACTION",
});

export const setCorrectiveAction = (index, text) => ({
  type: "SET_CORRECTIVE_ACTION",
  payload: { index, text },
});

export const addQuestion = () => ({
  type: "ADD_QUESTION",
});

export const setQuestionText = (index, text) => ({
  type: "SET_QUESTION_TEXT",
  payload: { index, text },
});

export const removeQuestion = (index) => ({
  type: "REMOVE_QUESTION",
  payload: index,
});

export default learningLessonsReducer;
