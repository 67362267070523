import React from 'react'
import './PdfButton.css'
function PdfButton(props) {
  const {onClick}= props
  return (
    <div  className="pdf_div">
    <button type='button' className="pdf-button_1" onClick={(e)=> {onClick(e)}}/>
      <div className="fillup"></div>
   </div>
  )
}

export default PdfButton