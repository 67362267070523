import React, { Fragment } from 'react'
import './AddButton.css'
function AddButton(props) {
    const {text , state, onClick} = props
  return (
    <Fragment>
        <div className='plus_line'>
        <div className='plus_icon' onClick={onClick}></div>
        <div  className="plus_text">
        {text}
        </div>
        </div>
    </Fragment>
  )
}

export default AddButton