import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { financeReducer } from "./financeReducer";
import { budgetReducer } from "./budgetReducer";
import { incomeReducer } from "./incomeReducer";
import { salesReducer } from "./salesReducer";
import { appReducer } from "./appReducer";
import { workplanReducer } from "./workplanReducer";
import customerReducer from "./customerReducer";
import { workflowReducer } from "./workflowReducer";
import { scenariosReducer } from "./scenariosReducer";
import{ vsReducer } from './vsReducer';
import {decisionsReducer} from './decisionsReducer';
import { marketingReducer } from './marketingReducer'; 
import  learningLessonsReducer  from "./learningLessonsReducer";
import {concentationDebtsReducer} from "./concentationDebtsReducer"
import defineReducer from "./defineReducer";

const rootReducer = combineReducers({
  finance: financeReducer,
  budget: budgetReducer,
  income: incomeReducer,
  workplan: workplanReducer,
  sales: salesReducer,
  app: appReducer,
  customer: customerReducer,
  define: defineReducer,
  scenarios:scenariosReducer,
  workflow: workflowReducer,
  vs: vsReducer,
  decisions: decisionsReducer,
  marketing: marketingReducer,
  learningLessons: learningLessonsReducer,
  debts: concentationDebtsReducer,

});

export const store = configureStore({ reducer: rootReducer });
