import React, { Fragment } from "react";
import "./MonthSelectBudget.css";
// import { months } from '../../helpers/helpers';
import { useDispatch, useSelector } from "react-redux";

function BudgetTable(props) {
  const { text, setIndex, resetForm, months, page, theMonth } = props;
  const thisState = useSelector((state) => state.budget);
  const dispatch = useDispatch();
  const divs = document.querySelectorAll(".select_month");

  return (
    <Fragment>
      <div className="container_grid_12_budget text_center ">
        {months.map((mon, i) => (
          <Fragment key={i}>
            <div
              className="select_month"
              id={mon}
              onClick={(e) => {
                if (!(e.target.id === "Total" || e.target.id === "Average")) {
                  document.getElementById(resetForm).reset();
                  divs.forEach((div) => {
                    div.classList.remove("picked_f");
                  });
                  dispatch({ type: "month", payload: mon });
                  dispatch({
                    type: "transaction_turnover_before_vat",
                    payload: theMonth[mon].total_Income,
                  });
                  dispatch({
                    type: mon + page,
                    payload: {
                      ...thisState[mon],
                      // "baseValue": theMonth[mon].total_Income,
                      transaction_turnover_before_vat:
                        theMonth[mon].total_Income,
                      inventory_and_suppliers_actual_expense:
                        theMonth[mon].inventory_and_supplier_expenses,
                      employee_salaries_actual_expense:
                        theMonth[mon].salary_and_related_expenses,
                      operation_costs_actual_expense:
                        theMonth[mon].operational_and_managment,
                      repayments_of_loans_actual_expense:
                        theMonth[mon].financial_expenses,
                    },
                  });
                  setIndex(i);
                  e.currentTarget.classList.add("picked_f");
                }
              }}
            >
              {text[i]}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default BudgetTable;
