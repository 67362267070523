import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "./Budget.css";
import FourTableRow from "../../components/Tables/FourTableRow/FourTableRow";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { handleExport } from "../../Excel/generateBudgetData";
import { months, formatNumber } from "../../helpers/helpers";
import MonthSelectBudget from "../../components/Selectors/MonthSelectBudget/MonthSelectBudget";
import TurnoverInput from "../../components/Inputs/TurnoverInput/TurnoverInput";
import { budgetRows } from "../../data/budgetRows";
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton";
import Warning from "../../components/Warning/Warning"
import { BudgetPostReq, BudgetGetReq, BudgetPutReq, BudgetDeleteReq } from "./BudgetReq";
import FourTableRowTotal from "../../components/Tables/FourTableRow/FourTableRowTtoal";
// import TextToSpeech from "../../components/TextToSpeech/TextToSpeech";

function Budget() {
  // BudgetPostReq()
  // useEffect(() => {
    //   const handleBeforeUnload = (event) => {
      //     event.preventDefault();
      //     event.returnValue = "Are you sure you want to leave?";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
    //     window.removeEventListener("beforeunload", handleBeforeUnload);
    //   };
    // }, []);
    const dispatch = useDispatch();
    useEffect(()=>{
      document.getElementById("budgetForm").reset()
      dispatch({ type: "reset_b" })
    },[dispatch])
  const { t } = useTranslation("Budget");

  const turnover = useSelector(
    (state) => state.budget.transaction_turnover_before_vat
  );
  const [index, setIndex] = useState(12);
  const currency = useSelector((state) => state.income.currency);
  const month = useSelector((state) => state.budget.month);
  // const transaction_turnover_before_vat = useSelector((state) => state.budget.transaction_turnover_before_vat);
  // console.log(transaction_turnover_before_vat)
  const inventory_and_suppliers_actual_expense = useSelector(
    (state) => state.budget.inventory_and_suppliers_actual_expense
  );
  const the_budget = useSelector((state) => state.budget);

  const employee_salaries_actual_expense = useSelector(
    (state) => state.budget.employee_salaries_actual_expense
  );
  const operation_costs_actual_expense = useSelector(
    (state) => state.budget.operation_costs_actual_expense
  );
  const repayments_of_loans_actual_expense = useSelector(
    (state) => state.budget.repayments_of_loans_actual_expense
  );
  const employee_salaries_budget_amount = useSelector(
    (state) => state.budget.employee_salaries_budget_amount
  );
  const employee_salaries_initialPercentage = useSelector(
    (state) => state.budget.employee_salaries_initialPercentage
  );
  const operation_costs_budget_amount = useSelector(
    (state) => state.budget.operation_costs_budget_amount
  );
  const operation_costs_initialPercentage = useSelector(
    (state) => state.budget.operation_costs_initialPercentage
  );
  const repayments_of_loans_budget_amount = useSelector(
    (state) => state.budget.repayments_of_loans_budget_amount
  );
  const repayments_of_loans_initialPercentage = useSelector(
    (state) => state.budget.repayments_of_loans_initialPercentage
  );
  const inventory_and_suppliers_budget_amount = useSelector(
    (state) => state.budget.inventory_and_suppliers_budget_amount
  );
  const inventory_and_suppliers_initialPercentage = useSelector(
    (state) => state.budget.inventory_and_suppliers_initialPercentage
  );
  const employee_salaries_budget_status = useSelector(
    (state) => state.budget.employee_salaries_budget_status
  );
  const operation_costs_budget_status = useSelector(
    (state) => state.budget.operation_costs_budget_status
  );
  const inventory_and_suppliers_budget_status = useSelector(
    (state) => state.budget.inventory_and_suppliers_budget_status
  );
  const repayments_of_loans_budget_status = useSelector(
    (state) => state.budget.repayments_of_loans_budget_status
  );
  const total_budget_amount = useSelector(
    (state) => state.budget.total_budget_amount
  );
  const total_actual_expense = useSelector(
    (state) => state.budget.total_actual_expense
  );
  const total_budget_status = useSelector(
    (state) => state.budget.total_budget_status
  );

  // [January, February, March, April, May, June, July, August, September, October, November, December]
  const month_list = t("month_text").split(",");

  const rows = budgetRows.map((row) => ({
    ...row,
    text: t(row.text),
  }));

  const actualExpenses = useSelector((state) => ({
    inventory_and_suppliers:
      state.budget.inventory_and_suppliers_actual_expense || 0,
    employee_salaries: state.budget.employee_salaries_actual_expense || 0,
    operation_costs: state.budget.operation_costs_actual_expense || 0,
    repayments_of_loans: state.budget.repayments_of_loans_actual_expense || 0,
  }));
  const budgetAmounts = useSelector((state) => ({
    inventory_and_suppliers:
      state.budget.inventory_and_suppliers_budget_amount || 0,
    employee_salaries: state.budget.employee_salaries_budget_amount || 0,
    operation_costs: state.budget.operation_costs_budget_amount || 0,
    repayments_of_loans: state.budget.repayments_of_loans_budget_amount || 0,
  }));

  // const budgetStatus = useSelector((state) => ({
  //   inventory_and_suppliers:
  //     state.budget.inventory_and_suppliers_budget_status || 0,
  //   employee_salaries: state.budget.employee_salaries_budget_status || 0,
  //   operation_costs: state.budget.operation_costs_budget_status || 0,
  //   repayments_of_loans: state.budget.repayments_of_loans_budget_status || 0,
  // }));
  // const inventory_and_suppliers1 = useSelector((state) => ({
  //   name: "inventory and suppliers",
  //   budget: state.budget.inventory_and_suppliers_budget_amount,
  //   actual: state.budget.inventory_and_suppliers_actual_expense,
  //   status: state.budget.inventory_and_suppliers_budget_status,
  // }));
  const inventory_and_suppliers = useSelector((state) => ([
    formatNumber(state.budget.inventory_and_suppliers_budget_amount),
    formatNumber(state.budget.inventory_and_suppliers_actual_expense),
    // state.budget.inventory_and_suppliers_budget_status,
    formatNumber(state.budget.inventory_and_suppliers_budget_amount -  state.budget.inventory_and_suppliers_actual_expense),
  ]));
  // const employee_salaries1 = useSelector((state) => ({
  //   name: "employee salaries",
  //   budget: state.budget.employee_salaries_budget_amount,
  //   actual: state.budget.employee_salaries_actual_expense,
  //   status: state.budget.employee_salaries_budget_status,
  // }));
  const employee_salaries = useSelector((state) => ([    
  formatNumber(state.budget.employee_salaries_budget_amount),
formatNumber(state.budget.employee_salaries_actual_expense),
formatNumber(state.budget.employee_salaries_budget_amount - state.budget.employee_salaries_actual_expense)
  ]));
  // const operation_costs1 = useSelector((state) => ({
  //   name: " operation costs",
  //   budget: state.budget.operation_costs_budget_amount,
  //   actual: state.budget.operation_costs_actual_expense,
  //   status: state.budget.operation_costs_budget_status,
  // }));
  const operation_costs = useSelector((state) => ([
    formatNumber(state.budget.operation_costs_budget_amount),
    formatNumber(state.budget.operation_costs_actual_expense),
    formatNumber(state.budget.operation_costs_budget_amount -state.budget.operation_costs_actual_expense )
  ]));
  // const repayments_of_loans1 = useSelector((state) => ({
  //   name: "repayments of loans",
  //   budget: state.budget.repayments_of_loans_budget_amount,
  //   actual: state.budget.repayments_of_loans_actual_expense,
  //   status: state.budget.repayments_of_loans_budget_status,
  // }));
  const repayments_of_loans = useSelector((state) => ([
    formatNumber(state.budget.repayments_of_loans_budget_amount),
    formatNumber(state.budget.repayments_of_loans_actual_expense),
    formatNumber(state.budget.repayments_of_loans_budget_amount - state.budget.repayments_of_loans_actual_expense),
  ]));
  
  const totalBudget = Object.values(budgetAmounts).reduce(
    (acc, val) => acc + val,
    0
  );
  const totalActual = Object.values(actualExpenses).reduce(
    (acc, val) => acc + val,
    0
  );

  
  const totalDifference = totalBudget - totalActual;
  // const total1 = {
  //   name: "total",
  //   budget: totalBudget,
  //   actual: totalActual,
  //   status: totalDifference,
  // };
  const total = [
 formatNumber(totalBudget),
formatNumber(totalActual),
 formatNumber(totalDifference),
  ];
  const excelData = [
    [t("issue_of_expenses_costs"),t("budget_amount"),t("actual_expense"),t("budget_status")],
    [t("inventory_and_suppliers"),...inventory_and_suppliers],
    [t("employee_salaries"),...employee_salaries],
    [t("operation_costs",),...operation_costs],
    [t("repayments_of_loans"),...repayments_of_loans],
    [t("total"), ...total],
    [t('general_profit'),"",formatNumber(+turnover + -totalActual),""]
  ]
  const redFlagMessages = [];
  const getMessages = () => {
    return rows.map(({ id, initialPercentage }) => {
      const budgetAmount = turnover * the_budget[initialPercentage];
      const actualExpense = actualExpenses[id];
      const difference = actualExpense - budgetAmount;

      if (difference > 1000) {
        redFlagMessages.push(id);
        return (
          <h4 key={id} className="status-message red_title">
            <FontAwesomeIcon icon={faFlag} className="flag-icon flag-raise" />
            {t("expense_bigger", { id: t(id) })}
          </h4>
        );
      } else if (difference > 0 && difference <= 1000) {
        return (
          <h4 key={id} className="status-message red_title">
            {t("warning_approaching_limit", { id: t(id) })}
          </h4>
        );
      } else if (difference < 0) {
        return (
          <h4 key={id} className="status-message green_title">
            {t("budget_bigger", { id: t(id) })}
          </h4>
        );
      }
      return null;
    });
  };
  getMessages()
  const getRecommendationMessage = (id) => {
    if (id === "employee_salaries") {
      return t(
        "Employee_salaries_including_bonuses:_Budget_exceeded!_You_need_to_increase_turnover,_reduce_salary_costs,_or_reduce_the_budget_of_another_subject"
      );
    }
    return t(
      "There_is_an_exception_to_the_budget!_The_turnover_must_be_increased_or_reduce_the_budget_of_another_subject"
    );
  };

  const thisMonth = useSelector((state) => state.budget[month]);
  const obj = {
    ...thisMonth,
    inventory_and_suppliers_actual_expense,
    employee_salaries_actual_expense,
    operation_costs_actual_expense,
    repayments_of_loans_actual_expense,
    inventory_and_suppliers_budget_amount,
    employee_salaries_budget_amount,
    operation_costs_budget_amount,
    repayments_of_loans_budget_amount,
    inventory_and_suppliers_initialPercentage,
    employee_salaries_initialPercentage,
    operation_costs_initialPercentage,
    repayments_of_loans_initialPercentage,
    inventory_and_suppliers_budget_status,
    employee_salaries_budget_status,
    operation_costs_budget_status,
    repayments_of_loans_budget_status,
    total_budget_amount,
    total_actual_expense,
    total_budget_status,
  };
  const theMonth = useSelector((state) => state.finance);

  const btnSaveFunc = async (e) => {
    e.preventDefault();
    
    // Collect the data to be sent in the request
    const divs = document.querySelectorAll(".select_month");
    const obj = {
      ...thisMonth,
      inventory_and_suppliers_actual_expense,
      employee_salaries_actual_expense,
      operation_costs_actual_expense,
      repayments_of_loans_actual_expense,
      inventory_and_suppliers_budget_amount,
      employee_salaries_budget_amount,
      operation_costs_budget_amount,
      repayments_of_loans_budget_amount,
      inventory_and_suppliers_initialPercentage,
      employee_salaries_initialPercentage,
      operation_costs_initialPercentage,
      repayments_of_loans_initialPercentage,
      inventory_and_suppliers_budget_status,
      employee_salaries_budget_status,
      operation_costs_budget_status,
      repayments_of_loans_budget_status,
      total_budget_amount,
      total_actual_expense,
      total_budget_status,
    };
  
    // POST Request
    try {
      await BudgetPostReq(thisMonth); // You can pass data specific to thisMonth here
      console.log("POST request successful");
    } catch (error) {
      console.error("POST request failed:", error);
    }
  
    // Reset the form and dispatch the actions
    dispatch({ type: month + "_budget", payload: obj });
    dispatch({ type: "reset_b" });
    document.getElementById("budgetForm").reset();
  
    // Remove the active class from month selectors
    divs.forEach((div) => {
      div.classList.remove("picked_f");
    });
  
    // Reset the index and month
    setIndex(12);
    dispatch({ type: "month", payload: "select month" });
  };
  
  return (
    <Fragment>
      <div className="container_align_start">
      </div>
      <div className="all_page">

      <div className='video-container_p'>
      <VideoNewModal videoUrl={t('video_url')} />
      </div>
        {/* <div className="header_title2">{t("budget_control")}</div> */}
        {/* <div className="center_month"> */}
      <div className="inner_section_budget">

        <p className="text_center month_title">{month_list[12]}</p>
        <MonthSelectBudget
          text={month_list}
          setIndex={setIndex}
          resetForm={"budgetForm"}
          months={months}
          page={"_budget"}
          theMonth={theMonth}
          />
          </div>
          {/* </div> */}

      <form id="budgetForm">
        <div className="top-container">
          <TurnoverInput
            text="transaction_turnover_amount"
            state="SET_TURNOVER"
            month={month}
            page={"_budget"}
            currency={currency}
            theMonth={theMonth}
            />
        </div>
        <div className="fit_table">

          <ExcelButton
            name={"budget"}
            data={excelData}
            excel={handleExport}
            />
        <div className="inner_section">
        <Warning text={t("cahnge")}/>
        {/* <h4 className="msg">{t("cahnge")}</h4> */}
        <div className="budget-table">
          <div className="container_grid_four text_center row_height">
            <div className="budget_head"><h3 className="the_info_budget">{t("issue_of_expenses_costs")}</h3></div>
            <div className="budget_head">%</div>
            <div className="budget_head">{t("budget_amount")}</div>
            <div className="budget_head">{t("actual_expense")}</div>
            <div className="budget_head">{t("budget_status")}</div>
          </div>
          {rows.map(({ id, text, initialPercentage }) => (
            <FourTableRow
            key={id}
            id={id}
            text={text}
            turnover={turnover}
            initialPercentage={the_budget[initialPercentage]}
            currency={currency}
            month={month}
            page={"_budget"}
            />
          ))}
          <FourTableRow
            text={t("total")}
            isSummary={true}
            key={"total"}
            id={"total"}
            turnover={turnover}
            totalBudget={totalBudget}
            totalActual={totalActual}
            totalDifference={totalDifference}
            currency={currency}
            page={"_budget"}
            month={month}
            />
          <FourTableRowTotal
            text={t('general_profit')}
            isSummary={true}
            key={"total"}
            id={"total"}
            turnover={turnover}
            totalBudget={""}
            totalActual={+turnover + -totalActual}
            totalDifference={ ""}
            currency={currency}
            page={"_budget"}
            month={month}
            />

            </div>
            </div>
        </div>
        <div className="inner_section">
        <h3>{t("recommendations_for_action")}</h3>
        {/* <h4 className="msg">{t("output_depending_on_result")}</h4> */}
        {/* {getMessages()} */}

        {redFlagMessages.length > 0 &&
          redFlagMessages.map((id) => (
            <Fragment>

            {/* <TextToSpeech key={id} text={t("expense_bigger", { id: t(id) })} /> */}
            </Fragment>
          ))}


        {redFlagMessages.map((id) => (
          <h4 key={id} className="msg third_title  status-message red">
            <FontAwesomeIcon icon={faFlag} className="flag-icon flag-raise" />
            {t(id)}: {getRecommendationMessage(id)}
          </h4>
        ))}

<button
  type="button"
  className="save_button_first_page"
  onClick={btnSaveFunc} // Directly call the btnSaveFunc without e.preventDefault() here
>
  {t('save')}
</button>

            </div>
      </form>
          </div>
    </Fragment>
  );
}

export default Budget;
