import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateField } from "../../Reducers/marketingReducer"; // Import the action
// import html2canvas from "html2canvas";
import { formatNumber } from "../../helpers/helpers";
// import jsPDF from "jspdf";
import "./MarketingControl.css";
// import PdfButton from "../../components/Buttons/PdfButton/PdfButton";
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton";
import { handleExportMarketing } from "../../Excel/MarketingControl";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import {createMarketingData, updateMarketingData} from "./MarketingControlReq";

function MarketingControl() {
  const { t } = useTranslation("MarketingControl");
  const dispatch = useDispatch();

  // Access the data from Redux store
  const data = useSelector((state) => state.marketing); // Assuming it's combined as 'marketing'

  const handleInputChange = async (month, field, value) => {
    dispatch(updateField(month, field, parseFloat(value) || 0));
  
    try {
      const existingData = data[month];
      const updatedData = {
        ...existingData,
        [field]: parseFloat(value) || 0,
      };
  
      // Check if this is a new entry or existing one (e.g., no `id` present for new data)
      if (!updatedData.id) {
        // If it's new data, create it via POST request
        const responseData = await createMarketingData(updatedData);
        console.log(`Successfully created new data for ${month}.`);
      } else {
        // Otherwise, update the existing data via PUT request
        const id = updatedData.id || month;
        await updateMarketingData(id, updatedData);
        console.log(`Successfully updated ${month} data.`);
      }
    } catch (error) {
      console.error(`Error handling input change for ${month}:`, error.message);
    }
  };
  
  const calculateMarketingEffectivenessLeads = (TR, TL) => {
    return TL ? (TR / TL) * 100 : 0;
  };

  const calculateMarketingEffectivenessSales = (TI, TM) => {
    if (TM === 0) {
      return 0.0;
    }
    if (TI < TM && TI !== 0) {
      return (1 - TI / TM) * 100;
    } else {
      return (TI / TM) * 100;
    }
  };

  const calculateSalesEfficiency = (TR, TA) => {
    return TR ? (TA / TR) * 100 : 0;
  };

  const pdfRef = useRef();
  // const downloadPdf = () => {
  //   // const input = pdfRef.current;
  //   const input = document.getElementById("marketingTable");
  //   input.style.overflowX = "scroll";
  //   html2canvas(input, { scale: 0.7 }).then((canvas) => {
  //     // ... שאר הקוד ליצירת ה-PDF
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4", true);
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const imageWidth = canvas.width;
  //     const imageHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
  //     const imgX = (pdfWidth - imageWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(
  //       imgData,
  //       "PNG",
  //       imgX,
  //       imgY,
  //       imageWidth * ratio,
  //       imageHeight * ratio
  //     );
  //     pdf.save("MarketingControl.pdf");
  //   });
  // };
    // Function to calculate the total for each field
    const calculateTotal = (field) => {
      return monthsInEnglish.reduce(
        (sum, month) => sum + (data[month]?.[field] || 0),
        0
      );
    };
  const monthsInEnglish = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsInHebrew = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const titles = [
    t("total_budget"),
    t("tetainer_for_suppliers"),
    t("cost_of_marketing_staff"),
    t("total_Marketing"),
    t("amount_of_leads"),
    t("relevant_leads"),
    t("total_of_close_deals"),
    t("income_from_leads"),
    t("effectiveness_for_leads"),
    t("effectiveness_for_salse"),
    t("sales_efficiency"),
    // t('Actions')
  ];

  const titlesForExcel = [
    "totalBudget", "retainerSuppliers", "marketingStaff", "TM", "TL", "TR", "TA", "TI",]
  const titlesForSave = [
    "totalBudget", "retainerSuppliers", "marketingStaff", "TM", "TL", "TR", "TA", "TI",
  ]
  const calculateActiveMonth = monthsInEnglish.reduce((sum, month) => {
    if (data[month].TM > 0) return sum + 1;
    else {
      return sum;
    }
  }, 0);
  const ExcelData = [
    [t("month"),...titles],
    ...monthsInEnglish.map((month, i) =>
    [monthsInHebrew[i], ...titlesForExcel.map(header => formatNumber(data[month][header])),
    `${calculateMarketingEffectivenessLeads(data[month]?.TR, data[month]?.TL).toFixed(2)}%`,
    `${calculateMarketingEffectivenessSales(data[month]?.TI, data[month]?.TM).toFixed(2)}%`,
    `${calculateSalesEfficiency(data[month]?.TR, data[month]?.TA).toFixed(2)}%`,
  ]),
  [t("Total"),...titlesForExcel.map(header => formatNumber(calculateTotal([header]))),
  `${((calculateMarketingEffectivenessLeads( calculateTotal("TR"),  calculateTotal("TL"))/calculateActiveMonth) || 0).toFixed(2)}%`,
  `${((calculateMarketingEffectivenessSales( calculateTotal("TI"),  calculateTotal("TM"))/calculateActiveMonth) || 0).toFixed(2)}%`,
  `${((calculateSalesEfficiency( calculateTotal("TR"),  calculateTotal("TA"))/calculateActiveMonth) || 0).toFixed(2)}%`
]
  // [titlesForExcel.map(header => calculateTotal([header]))
  // `${(calculateMarketingEffectivenessLeads( calculateTotal("TR"),  calculateTotal("TL"))/calculateActiveMonth).toFixed(2)}%`,
  // `${(calculateMarketingEffectivenessSales( calculateTotal("TI"),  calculateTotal("TM"))/calculateActiveMonth).toFixed(2)}%`,
  // `${(calculateSalesEfficiency( calculateTotal("TR"),  calculateTotal("TA"))/calculateActiveMonth).toFixed(2)}%`

]
  const saveData = ()=> {
     monthsInEnglish.map((month) => {
      let obj = {};
      titlesForSave.forEach(header => obj[header] = data[month][header]);
      obj["effectiveness_for_leads"] = `${calculateMarketingEffectivenessLeads(data[month]?.TR, data[month]?.TL).toFixed(2)}%`;
      obj["effectiveness_for_sales"] = `${calculateMarketingEffectivenessSales(data[month]?.TI, data[month]?.TM).toFixed(2)}%`;
      obj["sales_efficiency"] = `${calculateSalesEfficiency(data[month]?.TR, data[month]?.TA).toFixed(2)}%`;
      dispatch({type:"saveData", payload:{month, data:obj}})
      return obj
  });
  let obj = {};
  titlesForExcel.forEach(header => obj[header] = calculateTotal([header]))
  obj["effectiveness_for_leads"] = `${calculateMarketingEffectivenessLeads( calculateTotal("TR"),  calculateTotal("TL"))/calculateActiveMonth.toFixed(2)}%`;
  obj["effectiveness_for_sales"] = `${calculateMarketingEffectivenessSales( calculateTotal("TI"),  calculateTotal("TM"))/calculateActiveMonth.toFixed(2)}%`;
  obj["sales_efficiency"] = `${calculateSalesEfficiency( calculateTotal("TR"),  calculateTotal("TA"))/calculateActiveMonth.toFixed(2)}%` 
  dispatch({type:"saveData", payload:{month:"Total", data:obj}})
}
  console.log(ExcelData)


  return (
    <div className="margin_top_sales">
      <VideoNewModal videoUrl={t("videoUrl")} />

      {/* <PdfButton onClick={downloadPdf} /> */}
      <ExcelButton
              data={ExcelData}
              name={"Marketing-control"}
              excel={handleExportMarketing}
      />
      <div
        className=" container_grid_12_sales margin_sales_effectivness"
        ref={pdfRef}
        id="marketingTable"
      >
        <div className="table_head_m">
          <input readOnly className="input-table-month-head" value={t("month")} />
        </div>
        {titles.map((title, i) => (
          <div className="table_head_m" key={i}>
            {title}
          </div>
        ))}
        {monthsInEnglish.map((month, i) => (
          <Fragment key={i}>
            <div className="div-table-m">
              <input
                className="input-table-months"
                value={monthsInHebrew[i]}
                disabled
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={
                  data[month]?.totalBudget === 0
                    ? ""
                    : data[month]?.totalBudget || ""
                }
                placeholder="0"
                type="number"
                onChange={(e) =>
                  handleInputChange(month, "totalBudget", e.target.value)
                }
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={
                  data[month]?.retainerSuppliers === 0
                    ? ""
                    : data[month]?.retainerSuppliers || ""
                }
                placeholder="0"
                type="number"
                onChange={(e) =>
                  handleInputChange(month, "retainerSuppliers", e.target.value)
                }
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={
                  data[month]?.marketingStaff === 0
                    ? ""
                    : data[month]?.marketingStaff || ""
                }
                placeholder="0"
                type="number"
                onChange={(e) =>
                  handleInputChange(month, "marketingStaff", e.target.value)
                }
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={formatNumber(data[month]?.TM) || ""}
                disabled
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={data[month]?.TL === 0 ? "" : data[month]?.TL || ""}
                placeholder="0"
                type="number"
                onChange={(e) => handleInputChange(month, "TL", e.target.value)}
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={data[month]?.TR === 0 ? "" : data[month]?.TR || ""}
                placeholder="0"
                type="number"
                onChange={(e) => handleInputChange(month, "TR", e.target.value)}
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={data[month]?.TA === 0 ? "" : data[month]?.TA || ""}
                placeholder="0"
                type="number"
                onChange={(e) => handleInputChange(month, "TA", e.target.value)}
              />
            </div>
            <div className="div-table-m">
              <input
                dir="ltr"
                className="input-table-m"
                value={data[month]?.TI === 0 ? "" : data[month]?.TI || ""}
                placeholder="0"
                type="number"
                onChange={(e) => handleInputChange(month, "TI", e.target.value)}
              />
            </div>
            <div className={`div-table-m ${
                  calculateMarketingEffectivenessLeads(
                    data[month]?.TR,
                    data[month]?.TL
                  ) > 30
                    ? "green"
                    : data[month].TM > 0
                    ? "red"
                    : ""
                }`}>
              <input
                dir="ltr"
                className={`input-table-m `}
                value={`${calculateMarketingEffectivenessLeads(
                  data[month]?.TR,
                  data[month]?.TL
                ).toFixed(2)}%`}
                disabled
              />
            </div>
            <div className={`div-table-m ${
                  calculateMarketingEffectivenessSales(
                    data[month]?.TI,
                    data[month]?.TM
                  ) > 110
                    ? "green"
                    : data[month].TM > 0
                    ? "red"
                    : ""
                }`}>
              <input
                dir="ltr"
                className={`input-table-m `}
                value={`${calculateMarketingEffectivenessSales(
                  data[month]?.TI,
                  data[month]?.TM
                ).toFixed(2)}%`}
                disabled
              />
            </div>
            <div className={`div-table-m ${
                  calculateSalesEfficiency(data[month]?.TR, data[month]?.TA) >
                  30
                    ? "green"
                    : data[month].TM > 0
                    ? "red"
                    : ""
                }`}>
              <input
                dir="ltr"
                className={`input-table-m `}
                value={`${calculateSalesEfficiency(
                  data[month]?.TR,
                  data[month]?.TA
                ).toFixed(2)}%`}
                disabled
              />
            </div>
          </Fragment>
        ))}

        {/* Add the totals row at the end */}

        <div className="div-table-m">
          <input
            className="input-table-months total-row"
            value={t("Total")}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("totalBudget"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("retainerSuppliers"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("marketingStaff"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("TM"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("TL"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("TR"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("TA"))}
            disabled
          />
        </div>
        <div className="div-table-m">
          <input
            dir="ltr"
            className="input-table-m total-row"
            value={formatNumber(calculateTotal("TI"))}
            disabled
          />
        </div>
        {/* <div className="div-table-m">

                <input dir='ltr'  className={`input-table-m total-row ${(calculateMarketingEffectivenessSales(calculateTotal('TR'), calculateTotal('TL'))/ calculateActiveMonth ) > 30 ? "green": calculateTotal('TM') > 0 ? "red": ""}`}
                     value={`${calculateActiveMonth > 0 ? (calculateMarketingEffectivenessLeads(calculateTotal('TR'), calculateTotal('TL'))/ calculateActiveMonth ).toFixed(2): "0.00"}%`} disabled />
                </div>
                <div className="div-table-m">

                    <input dir='ltr'  className={`input-table-m total-row ${(calculateMarketingEffectivenessSales(calculateTotal('TI'), calculateTotal('TM'))/ calculateActiveMonth ) > 100 ? "green": calculateTotal('TM') > 0 ? "red": ""}`}
                    value={`${calculateActiveMonth > 0 ? (calculateMarketingEffectivenessSales(calculateTotal('TI'), calculateTotal('TM'))/ calculateActiveMonth ).toFixed(2):"0.00" }%`} disabled />
                </div>
                <div className="div-table-m ">

                    <input dir='ltr'  className={`input-table-m total-row ${(calculateMarketingEffectivenessSales(calculateTotal('TR'), calculateTotal('TA'))/ calculateActiveMonth ) > 30 ? "green": calculateTotal('TM') > 0 ? "red": ""}`}
                     value={`${calculateActiveMonth > 0 ?(calculateSalesEfficiency(calculateTotal('TR'), calculateTotal('TA'))/ calculateActiveMonth ).toFixed(2) : "0.00" }%`} disabled />
                </div> */}
        <div
          className={`div-table-m total-row ${
            calculateMarketingEffectivenessSales(
              calculateTotal("TR"),
              calculateTotal("TL")
            ) /
              calculateActiveMonth >
            30
              ? "green"
              : calculateTotal("TM") > 0
              ? "red"
              : ""
          }`}
        >
          <input
            dir="ltr"
            className="input-table-m"
            value={`${
              calculateActiveMonth > 0
                ? (
                    calculateMarketingEffectivenessLeads(
                      calculateTotal("TR"),
                      calculateTotal("TL")
                    ) / calculateActiveMonth
                  ).toFixed(2)
                : "0.00"
            }%`}
            disabled
          />
        </div>

        <div
          className={`div-table-m total-row ${
            calculateMarketingEffectivenessSales(
              calculateTotal("TI"),
              calculateTotal("TM")
            ) /
              calculateActiveMonth >
            100
              ? "green"
              : calculateTotal("TM") > 0
              ? "red"
              : ""
          }`}
        >
          <input
            dir="ltr"
            className="input-table-m"
            value={`${
              calculateActiveMonth > 0
                ? (
                    calculateMarketingEffectivenessSales(
                      calculateTotal("TI"),
                      calculateTotal("TM")
                    ) / calculateActiveMonth
                  ).toFixed(2)
                : "0.00"
            }%`}
            disabled
          />
        </div>

        <div
          className={`div-table-m total-row ${
            calculateSalesEfficiency(
              calculateTotal("TR"),
              calculateTotal("TA")
            ) /
              calculateActiveMonth >
            30
              ? "green"
              : calculateTotal("TM") > 0
              ? "red"
              : ""
          }`}
        >
          <input
            dir="ltr"
            className="input-table-m"
            value={`${
              calculateActiveMonth > 0
                ? (
                    calculateSalesEfficiency(
                      calculateTotal("TR"),
                      calculateTotal("TA")
                    ) / calculateActiveMonth
                  ).toFixed(2)
                : "0.00"
            }%`}
            disabled
          />
        </div>
      </div>
      <div>
        <div className="save_button_sales" onClick={saveData}>{t("save")}</div>
        <h2 className="small_header">{t("definitions")}:</h2>
        <ol className="table-info-m">
          {/* <li className="marketing_list">{t("Marketing_effectiveness_for_leads_explanation")}</li> */}
          <li className="marketing_list">{t("marketing_efficiency")}</li>
          {/* <li className="marketing_list">{t("Marketing_effectiveness_for_sales_explanation")}</li> */}
          <li className="marketing_list">{t("marketing_effectiveness")}</li>
          {/* <li className="marketing_list">{t("Sales_efficiency_explanation")}</li> */}
          <li className="marketing_list">{t("sales_efficiency_explain")}</li>
        </ol>
        {/* <button type='button' onClick={downloadPdf}>Download PDF</button> */}
      </div>
    </div>
  );
}

export default MarketingControl;
