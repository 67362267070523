// import logo from '../../Pictures/logo_transparent.png';
import "./Navbar.css";
import LanguageSelect from "../Selectors/LanguageSelect/LanguageSelect";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo,faUser,faGear } from "@fortawesome/free-solid-svg-icons";
import CurrencySelctor from "../Selectors/CurrencySelctor/CurrencySelctor";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const dispatch = useDispatch();
  const ai = useSelector((state) => state.income.ai);
  const { t, i18n } = useTranslation("App");

  return (
    <nav className="top_navbar">
      <div className="user_selector">
      <FontAwesomeIcon icon={faUser} />
      <select>
      {/* <option >User Profile</option>
      <option >Settings</option>
      <option >Help</option>
      <option >Disconnected</option> */}
      </select>
      </div>

      <LanguageSelect />
      <CurrencySelctor />
      <div
        className="video-nav-container"
        onClick={() => {
          dispatch({ type: "Video_open", payload: true });
        }}
      >
        <FontAwesomeIcon icon={faVideo} />
      </div>

      <div className={`navbar_settings ${ i18n.language === "he" ? "border_left" : " border_right"}`}>
      <FontAwesomeIcon icon={faGear} />
      </div>
      <button
        className="Ai"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          dispatch({ type: "ai", payload: !ai });
        }}
      >

       {t("Talk_to_digital_consultant")}
      </button>
      <button
        className="ai_training"
        type="button"
      >

       {t("Ai_tranning")}
      </button>
    </nav>
  );
};

export default Navbar;
