// import React from "react";
// import "./SalesTable.css";
// import { useTranslation } from "react-i18next";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faDollarSign,
//   faEuroSign,
//   faShekelSign,
// } from "@fortawesome/free-solid-svg-icons";

// export default function SalesTable({ rows, handleInputChange, currency }) {
//   const { t } = useTranslation("SalesManagment");

//   const getCurrencySymbol = (currency) => {
//     if (currency === faDollarSign)
//       return (
//         <h5>
//           <FontAwesomeIcon icon={faDollarSign} />
//         </h5>
//       );
//     if (currency === faShekelSign)
//       return (
//         <h5>
//           <FontAwesomeIcon icon={faShekelSign} />
//         </h5>
//       );
//     if (currency === faEuroSign)
//       return (
//         <h5>
//           <FontAwesomeIcon icon={faEuroSign} />
//         </h5>
//       );
//     return "";
//   };

//   const currencySymbol = getCurrencySymbol(currency);

//   return (
//     <div className="sales-management-grid">
//       <div className="grid-header">{t("date")}</div>
//       <div className="grid-header">{t("customerName")}</div>
//       <div className="grid-header">{t("contactName")}</div>
//       <div className="grid-header">{t("phoneNumber")}</div>
//       <div className="grid-header">{t("status1")}</div>
//       <div className="grid-header">{t("status2")}</div>
//       <div className="grid-header">{t("status3")}</div>
//       <div className="grid-header">{t("totalAmount")}</div>
//       <div className="grid-header">{t("preTaxAmount")}</div>
//       <div className="grid-header">{t("dealStatus")}</div>

//       {rows.map((row, index) => (
//         <React.Fragment key={index}>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="date"
//               value={row.date}
//               onChange={(e) => handleInputChange(index, "date", e.target.value)}
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.customerName}
//               onChange={(e) =>
//                 handleInputChange(index, "customerName", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.contactName}
//               onChange={(e) =>
//                 handleInputChange(index, "contactName", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.phoneNumber}
//               onChange={(e) =>
//                 handleInputChange(index, "phoneNumber", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.status1}
//               onChange={(e) =>
//                 handleInputChange(index, "status1", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.status2}
//               onChange={(e) =>
//                 handleInputChange(index, "status2", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <input
//               className="input-grid"
//               type="text"
//               value={row.status3}
//               onChange={(e) =>
//                 handleInputChange(index, "status3", e.target.value)
//               }
//             />
//           </div>
//           <div className="grid-cell">
//             <div className="input-with-currency">
//               <span className="currency-symbol">{currencySymbol}</span>
//               <input
//                 className="input-grid"
//                 type="number"
//                 value={row.totalAmount}
//                 onChange={(e) =>
//                   handleInputChange(index, "totalAmount", e.target.value)
//                 }
//               />
//             </div>
//           </div>
//           <div className="grid-cell">
//             <div className="input-with-currency">
//               <span className="currency-symbol">{currencySymbol}</span>
//               <input
//                 className="input-grid"
//                 type="number"
//                 value={row.preTaxAmount}
//                 onChange={(e) =>
//                   handleInputChange(index, "preTaxAmount", e.target.value)
//                 }
//               />
//             </div>
//           </div>
//           <div className="grid-cell">
//             <select
//               value={row.status}
//               onChange={(e) =>
//                 handleInputChange(index, "status", e.target.value)
//               }
//             >
//               <option value="0%">0%</option>
//               <option value="50%">50%</option>
//               <option value="90%">90%</option>
//               <option value="100%">100%</option>
//             </select>
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }

import React,{Fragment} from "react";
import "./SalesTable.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNumber } from "../../../helpers/helpers";
import { useSelector } from "react-redux";
import AddRow from "../../AddRow/AddRow";

export default function SalesTable({
  rows,
  headers,
  handleInputChange,
  currency,
  addRow
}) {
  const { t } = useTranslation("SalesManagment");
  const currencyState = useSelector((state)=>state.income.currency)
  const combainTotalAmount = rows.reduce((acc , row)=> +acc + +row.totalAmount, 0)
  const combainTotalPreTaxAmount = rows.reduce((acc , row)=> +acc + +row.preTaxAmount, 0)
  const renderInputByHeader = (header, row, index) => {
    switch (header) {
      case "date":
        return (
          <input
            className="input-grid"
            type="date"
            value={row[header] || ""}
            onChange={(e) => handleInputChange(index, header, e.target.value)}
          />
        );
      case "totalAmount":
      case "preTaxAmount":
        return (
          <div className="input-with-currency">
            <FontAwesomeIcon icon={currencyState}/>
            {/* <span className="currency-symbol">{currencySymbol}</span> */}
            <input
              className="input-grid"
              type="number"
              value={row[header] || ""}
              onChange={(e) => handleInputChange(index, header, e.target.value)}
            />
          </div>
        );
      case "dealStatus":
        return (
          <select
            className="input-grid"
            value={row[header] || "0%"}
            onChange={(e) => handleInputChange(index, header, e.target.value)}
          >
            <option value="0%">0%</option>
            <option value="50%">50%</option>
            <option value="90%">90%</option>
            <option value="100%">100%</option>
          </select>
        );
      default:
        return (
          <input
            className="input-grid"
            type="text"
            value={row[header] || ""}
            onChange={(e) => handleInputChange(index, header, e.target.value)}
          />
        );
    }
  };

  return (
    <div
      className="sales-management-grid"
      style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
    >
      {headers.map((header, i) => (
        <div key={i} className="grid-header">
          {t(header)}
        </div>
      ))
      }
      {rows.map((row, index) => (
        <Fragment key={index}>
          {headers.map((header, i) => (
            <div key={i} className="grid-cell">
              {renderInputByHeader(header, row, index)}
            </div>
          ))}
        </Fragment>
      ))}
      <div className="grid-cell-add">{t('total')}</div>
            {headers.slice(1,7).map((head, i)=>(
        <div className="grid-cell-add"></div>
      ))}
      <div dir="ltr" className="grid-cell-add">{formatNumber(combainTotalAmount || 0)}</div>
      <div dir="ltr" className="grid-cell-add">{formatNumber(combainTotalPreTaxAmount|| 0) }</div>
            {headers.slice(9).map((head, i)=>(
        <div className="grid-cell-add"></div>
      ))}
      <div  className="grid-cell-add"  onClick={addRow}>
      <AddRow addRow={addRow} text={t("add")}/>

      </div>
      {headers.slice(0,-1).map((head, i)=>(
        <div className="grid-cell-add"></div>
      ))}
    </div>
  );
}
