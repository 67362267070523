import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "../../../helpers/helpers";
import "./FourTableRow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FourTableRowTotal({
  id,
  text,
  turnover,
  initialPercentage,
  isSummary,
  totalBudget,
  totalActual,
  totalDifference,
  page,
  month,
  currency,
}) {
  const current_month = useSelector((state) => state.budget[month]);
  // const current = useSelector((state) => state.budget);
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState(initialPercentage);
  const [baseValue, setBaseValue] = useState(0);
  const [actualExpense, setActualExpense] = useState(0);
  // const [budgetAmount, setBudgetAmount] = useState(baseValue * percentage);
  const  budgetAmount = baseValue * percentage;

  useEffect(() => {
    if (current_month) {
      setPercentage(current_month[id + "_initialPercentage"]);
      setBaseValue(current_month.transaction_turnover_before_vat);
      setActualExpense(current_month[id + "_actual_expense"]);
    }
    else{
      setPercentage(0);
      setBaseValue(0);
      setActualExpense(0);
    }
  }, [current_month, id,]);
  useEffect(() => {
    if (!isSummary) {
      dispatch({ type: `${id}_budget_amount`, payload: budgetAmount });
      dispatch({ type: `${id}_actual_expense`, payload: actualExpense });
      dispatch({
        type: `${id}_budget_status`,
        payload: budgetAmount - actualExpense,
      });
      dispatch({ type: `${id}_initialPercentage`, payload: percentage });
      dispatch({ type: "UPDATE_TOTAL_BUDGET" });
      dispatch({ type: "UPDATE_TOTAL_EXPENSE" });
      dispatch({ type: "UPDATE_TOTAL_STATUS" });
    }
  }, [budgetAmount, actualExpense, dispatch, isSummary, id, percentage, month]);


  const differenceClass = totalActual < 0 ? "negative" : "positive";

    return (
      <div className="container_grid_four_budget_total row_height">
        <div className="budget_col_total"><p className="the_info">{text}</p></div>
        {/* <div></div> */}
        {/* <div className="input-container" dir="ltr"> */}
        {/* <FontAwesomeIcon icon={currency} />
          <p
            className="the_info_b"
            type="text"
            dir="ltr"
          >{formatNumber(totalBudget)}</p> */}
        {/* </div> */}
        <div className={`input-container ${differenceClass}`} dir="ltr">
          <FontAwesomeIcon icon={currency} />
          <p
            type="text"
            className="the_info_b"
          >{formatNumber(totalActual)}</p>
        </div>
        {/* <div className={`input-containe`} dir="ltr"> */}
          {/* <FontAwesomeIcon icon={currency} />
          <p
            type="text"
            className="the_info_b"
          >{formatNumber(totalDifference)}</p> */}
        {/* </div> */}

      </div>
    );

  
}

export default FourTableRowTotal;

