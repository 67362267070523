import React, { useState, useEffect } from 'react';
import './Pointer.css'; 

function Pointer({ info, state, list, point1, point2, updateState }) {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
      setIsActive(!isActive);
      
      updateState((prevState) => {
          const updatedState = [...prevState];
          // Safely access the targetItem
          const targetItem = updatedState[point1] && updatedState[point1][list] && updatedState[point1][list][point2];
          
          if (targetItem) {
              // Perform the necessary update
              // You can add your logic here to modify targetItem as needed
          }
          
          return updatedState;
      });
  };
  
  useEffect(() => {
    console.log("Pointer Debugging: ", {
        state,
        point1,
        point2,
        list,
        targetItem: state[point1] && state[point1][list] && state[point1][list][point2]
    });
}, [state, point1, point2, list]);


    return (
        <div className="pointer_component" onClick={handleClick}>
            <div className={isActive ? 'dot_blue' : 'dot'}></div>
        </div>
    );
}

export default Pointer;
