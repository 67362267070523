import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./GoalsSelect.css";

function GoalsSelect(props) {
    const { state, setState, id, value } = props;
    const { t } = useTranslation(["WorkPlan"]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value || t('increasing_sales_turnover_regularly'));
    const [inputValue, setInputValue] = useState(value || t('increasing_sales_turnover_regularly'));
    const [isAdding, setIsAdding] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const updateValue = (newValue) => {
        setSelectedValue(newValue);
        setInputValue(newValue);
        setState([...state.slice(0, id), { ...state[id], name: newValue }, ...state.slice(1 + id)]);
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
        updateValue(inputValue);
        setIsAdding(false);
    };

    const removeItem = () => {
        setState([...state.slice(0, id), ...state.slice(1 + id)]);
    };

    return (
        <Fragment>
            <div className='selector_container1' ref={dropdownRef}>
                <div className="selector_box scrollable2">
                    <button className="select_button2 scrollable2" onClick={() => setIsOpen(!isOpen)}>
                        <span className="button_text">{selectedValue}</span>
                    </button>
                    {isOpen && (
                        <div className="options_window scrollable2">
                            <ul>
                                <li onClick={() => setIsAdding(true)}>
                                    {t('add_text')}
                                </li>
                                {['increasing_sales_turnover_regularly', 'stability_in_the_business_structure', 
                            'improving_quality_of_life', 'positioning_and_branding_as_a_leader_in_selected_niches', 
                            'importing_complementary_products', 'increasing_the_profit_line', 
                            'passive_income_generation', 'strengthening_reputation', 'manufacturing_the_best', 
                            'improving_project_management', 'lead_in_customer_satisfaction', 'to_gain_an_image', 
                            'streamlining_to_increase_production_yields', 'streamlining_the_procurement_of_purchases_with_suppliers', 
                            'reduction_of_open_debts', 'establishment_of_a_franchise_network', 'stabilizing_the_cash_flow', 
                            'development_of_new_products', 'stabilizing_and_improving', 'obtaining_the_ISO_standard', 
                            'reducing_the_amount_of_employee_abandonment', 'increase_in_private_customers', 
                            'growth_in_institutional_clients', 'penetration_into_a_new_market/new_markets', 
                            'reducing_breakdowns_and_repetitive_work'].map((goal, index) => (
                                    <li key={index} onClick={() => updateValue(t(goal))}>
                                        {t(goal)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {isAdding && (
                        <div className="input_window scrollable2">
                            <input
                                type="text"
                                className="selector_input2"
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                placeholder={t('custom_input')}
                            />
                        </div>
                    )}
                </div>
                <button type='button' className="remove_button" onClick={removeItem}>X</button>
            </div>
        </Fragment>
    );
}

export default GoalsSelect;
